const getSchema = () => (

  /*** JSON */
  
{
  "defaultActiveTabId": "Titolo",
  "id": "root",
  "type": "tabs",
  "tabs": [
    {
      "id": "Titolo",
      "title": "Titolo",
      "rows": [
        {
          "id": "columns.bwn2bmopd",
          "type": "columns",
          "defaultSizes": [
            6,
            6
          ],
          "columns": [
            {
              "id": "column.mwpgfxbge",
              "name": "data",
              "type": "field",
              "fieldType": "date",
              "label": "Data",
              "props": {}
            },
            {
              "id": "column.khycywxvr"
            }
          ]
        },
        {
          "id": "columns.422kmpri0",
          "type": "columns",
          "defaultSizes": [
            6,
            6
          ],
          "columns": [
            {
              "id": "column.hs5z38f6f",
              "name": "oraInizio",
              "type": "field",
              "fieldType": "time",
              "label": "Ora inizio",
              "props": {}
            },
            {
              "id": "column.d1ok72vee",
              "name": "oraFine",
              "type": "field",
              "fieldType": "time",
              "label": "Ora fine",
              "props": {}
            }
          ]
        },
        {
          "id": "columns.bdf3zbn2t",
          "type": "columns",
          "defaultSizes": [
            12,
            6
          ],
          "columns": [
            {
              "id": "operatoreErogazioneUniqueId",
              "name": "operatoreErogazioneUniqueId",
              "type": "field",
              "fieldType": "select",
              "label": "Operatore"
            }
          ]
        },
        {
          "id": "columns.bdf3zbn2t",
          "type": "columns",
          "defaultSizes": [
            12,
            6
          ],
          "columns": [
            {
              "id": "column.rf4o7d7qk",
              "name": "prestazioniErogate",
              "type": "field",
              "fieldType": "select",
              "label": "Prestazioni",
              "props": {
                "multiple": "on"
              }
            }
          ]
        },
        {
          "id": "note",
          "type": "component",
          "name": "note"
        }
      ]
    }
  ]
}

/*** JSON */

)

export default getSchema;