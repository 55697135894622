import React, { Component } from "react";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import { FormViewer } from "@ai4/form-viewer";
import $schema from './schema-titolo.json';
import AppCalendar from './calendar/AppCalendar';

class DettaglioTitolo extends Component {

  render() {

    let components = {
      pianificazione: () => <AppCalendar />
    }

    return (
      <div>
        
        <Breadcrumb
          routeSegments={[
            { name: "Titolo 2CBFD338-7/1/110 di ROSSI GIUSEPPE" }
          ]}
        ></Breadcrumb>
        
        <FormViewer schema={$schema} components = {components} />
      </div>
    );
  }
}

export default DettaglioTitolo;
