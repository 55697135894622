import _get from 'lodash/get';
import React, { useCallback, useContext, useMemo } from "react";
import { DataContext, gql, useDataRequest, useFormViewer } from "@ai4/data-request";
import { useDataDecorator, useHideNodesSchema, useSelectItemsPopulate } from 'src/app/helpers/data';
import { GET_SOSPENSIONE } from '../gql/sospensione';
import { Formatters } from '@ai4/records-management';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { _assoc } from '../../anagrafiche/common';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import getSchema from '../schema/edit/sospensione';

const RELATED = gql`
	query getRelatedSospensione($cartellaUniqueId: String) {
		cartellaSocioSanitariaQuery {
            progettiPai {
                list(
                    cartellaUniqueId: $cartellaUniqueId
                ) {
                    codice
                    interventi {
                        affidamenti {
                            uniqueId
                        }
                    }
                }
            }
			elenchiCartellaSocioSanitaria {
				motivoSospensione {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

export const useModule = (options: any) => {
    const { parent, parent_type } = options || {};
    const list = 'cartellaSocioSanitariaQuery.sospensioni';
    const mutation = "cartelle.sospensioni";
	const { domain, getClient } = useContext(DataContext);
    const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED, { variables: { cartellaUniqueId: parent_type === 'affidamento' ? undefined : parent.uniqueId }});
    const query = useLazyQuery(GET_SOSPENSIONE);
	const [execDetails, { data: dataDetails, loading }] = query;
    const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	const schema = useHideNodesSchema(useSelectItemsPopulate(
		getSchema() as Schema,
        related, {
            affidamentoUniqueId: _get(related.data, 'cartellaSocioSanitariaQuery.progettiPai.list', []).map((pai: any) => { return { text: _get(pai, 'codice'), value: _get(pai, 'interventi[0].affidamenti[0].uniqueId') }}),
            motivoSospensioneUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.motivoSospensione.list', []).map(_assoc),
                { listManagement: { name: 'MotivoSospensione', query_name: 'getRelatedSospensione' } },
            ],
        }
    ), options.parent_type === 'cartella' ? [] : ['affidamentoUniqueId']);

	const save = useCallback(async (values) => {
		const { uniqueId, affidamentoUniqueId = _get(options.parent, 'uniqueId') } = values;
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/sospensioni`;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: values,
                affidamentoUniqueId,
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, []);

	const remove = useCallback(async (values) => {
		const { uniqueId, affidamentoUniqueId } = values;
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/sospensioni`;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, []);

    return {
        graphql: {
            list,
            mutation,
            loading,
			details: execDetails,
            listFilters: () => {
                const listArgs = () => {
                    if (options.parent_type === 'affidamento') {
                        return `affidamentoUniqueId: "${options.parent.uniqueId}"`;
                    }
                    if (options.parent_type === 'cartella') {
                        return `cartellaUniqueId: "${options.parent.uniqueId}"`;
                    }
                    return ``;
                }
                return `pagedList (
                    ${listArgs()}
                    order: {
                        dataInizio: DESC
                    }
                    pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                )`;
            },
        },
        actions: {
            save,
			remove,
        },
        results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		dataDetails,
        table: {
            columns: [
                {
                    dataField: "uniqueId",
                    text: "ID",
                    hidden: true,
                    dataNode: `
                        uniqueId
                        affidamento {
                            uniqueId
                        }
                    `
                },
                {
                    dataField: "motivoSospensione.descrizione",
                    text: "Motivo",
                },
                {
                    dataField: "dataInizio",
                    text: "Data inizio",
                    formatter: Formatters.dateFormatter
                },
                {
                    dataField: "dataFine",
                    text: "Data fine",
                    formatter: Formatters.dateFormatter
                },
            ],
        },
        extra: {
            options: {
                onCreateInitialValues: { [`${parent_type}UniqueId`]: parent.uniqueId },
                onEditValues: { [`${parent_type}UniqueId`]: parent.uniqueId },
                modalSize: "sm",
				closeWhenSaved: true,
            },
        },
    };
};
