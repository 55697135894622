import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { useDataRequest } from '@ai4/data-request';
import { setErrors } from 'src/@bootstrap/features/app';

interface Props {
    onSuccess: () => void;
}

// in seconds
const POLLING_FREQUENCY = 5;
const POLLING_TIMEOUT = 30;

const AuthFlow = (props: Props) => {
    const { onSuccess } = props;
    const [busy, setBusy] = useState(false);
    const dispatch = useDispatch();
    const $polling = useRef<any>();
    const $timeout = useRef<any>();
    const $popup = useRef<Window>();

	const { useRestRequest } = useDataRequest();
    const [execInit] = useRestRequest({
        method: 'POST',
        path: 'api/{ver}/sgdt/auth/session',
		jwt: true,
	});
    const [execCheck] = useRestRequest({
        method: 'GET',
        path: '@later',
		jwt: true,
	});

    useEffect(() => {
        return () => {
            stopPolling();
        }
    }, []);

    const stopPolling = useCallback(() => {
        if ($polling.current) clearInterval($polling.current);
        if ($timeout.current) clearTimeout($timeout.current);
        if ($popup.current) $popup.current.close();
    }, []);

    const checkSession = useCallback(async (sid: string) => {
        try {
            const res: any = await execCheck({
                path: `api/{ver}/sgdt/auth/session/${sid}`
            });
            if (res && res.result === true) {
                stopPolling();
                onSuccess();
            }
        } catch(e) {
            // silence error, we'll retry
        }
    }, []);

    const startAuth = useCallback(async () => {
        setBusy(true);
        stopPolling();
        try {
            const res: any = await execInit();
            if (res && res.result === true) {
                const { sessionId, url } = res;

                const handle = window.open(url, "SGDTAuth", "left=100,top=100,width=600,height=600");
                if (handle) {
                    $popup.current = handle;
                } else {
                    dispatch(setErrors(`Non è stato possibile aprire la finestra di autenticazione.`));
                }

                $polling.current = setInterval(() => {
                    checkSession(sessionId);
                }, POLLING_FREQUENCY * 1000);
                $timeout.current = setTimeout(() => {
                    dispatch(setErrors(`L'autenticazione non è stata completata sul sito esterno.`));
                    stopPolling();
                    setBusy(false);
                }, POLLING_TIMEOUT * 1000);
            }
        } catch(e) {

        }
    }, []);
    
    return <div
        className="modal show"
        style={{ display: 'block', position: 'initial' }}
    >
        <Modal.Dialog>
            <Modal.Header>
                <Modal.Title>
                    {!busy && <>Autenticazione</>}
                    {busy && <>Autenticazione in corso...</>}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {!busy && <p>Non sei autenticato. Accedi al servizio SISS della Regione Lombardia.</p>}
                {busy && <p>Completa l'accesso al SISS nella piattaforma della Regione Lombardia.</p>}
            </Modal.Body>

            <Modal.Footer>
                {!busy && <Button variant="primary" onClick={startAuth}>Accedi</Button>}
                {busy && <Button variant="primary" onClick={startAuth}>Avvia un'altra sessione di autenticazione</Button>}
            </Modal.Footer>
        </Modal.Dialog>
    </div>
}


export default AuthFlow;