import { gql } from "@ai4/data-request";

export const GET_CALENDARIO_ACCESSO = gql`
    query getCalendarioAccesso($id: Guid!) {
        cartellaSocioSanitariaQuery {
            accessiDiretti {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    stato
                    dataOreInizioErogazione
                    dataOreFineErogazione
                    ricorrenzaId
                    assistito {
                      datiAnagrafici {
                        nome
                        cognome
                      }
                      datiNascita {
                        data
                      }
                    }
                    affidamento {
                        uniqueId
                    }
                    operatoreErogazione {
                        uniqueId
                        datiAnagrafici {
                          nome
                          cognome
                        }
                        qualificaOperatore {
                          uniqueId
                          colore
                          descrizione
                        }
                    }
                    prestazioniErogate {
                        prestazione {
                            uniqueId
                        }
                    }
                    operatorePianificato {
                      uniqueId
                    }
                    dataOreInizioPianificata
                    dataOreFinePianificata
                    prestazioniPianificate {
                      prestazione {
                        uniqueId
                      }
                    }
                    motivoNonEsecuzione {
                      uniqueId
                      descrizione
                    }
                    motivoNonEsecuzioneAltro
                    note
                }
            }
        }
    }
`;