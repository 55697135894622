import { gql } from "@ai4/data-request";

export const GET_EROGATORE = gql`
    query getErogatore($id: Guid!) {
        struttureServiziQuery {
            erogatori {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    nome
                    descrizione
                    codiceFiscale
                    partitaIVA
                    note
                    tipologiaErogatore {
                        uniqueId
                        descrizione
                    }
                    recapiti {
                        tipologiaRecapito {
                            uniqueId
                            descrizione
                        }
                        valore
                    }
                    sedi {
                        nazione {
                            uniqueId
                            descrizione
                        }
                        provincia {
                            uniqueId
                            descrizione
                        }
                        comune {
                            uniqueId
                            descrizione
                        }
                        tipologiaSede {
                            uniqueId
                            descrizione
                        }
                        comuneEstero
                        localita
                        cap
                        note
                    }
                    codice {
                        cudesADI
                        cudesUCP
                    }
                }
            }
        }
    }
`;