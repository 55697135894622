import { gql } from "@ai4/data-request";

export const GET_COMUNI = gql`
    query getComuni($provincia: String) {
        elenchiQuery {
            elenchiGeneraliQuery {
                comuni {
                    list(where: { siglaProvincia: { _eq: $provincia} }) {
                        uniqueId
                        descrizione
                        siglaProvincia
                    }
                }
            }
        }
    }
`;

export const GET_ALLEGATO = gql`
	query getAllegato($id: Guid!) {
		anagraficaQuery {
			allegati {
				list (where: { uniqueId: { _eq: $id } }) {
					uniqueId
					metadata {
						insertDate
						updateDate
					}
					note
					tipologiaDocumentoAllegato {
						uniqueId
					}
					documento {
						uniqueId
						fileName
						fileLength
						fileExt
						hash
					}
				}
			}
		}
	}
`;

export const ANAGRAFICA_FIELDS = `
    uniqueId
    metadata {
        insertDate
        updateDate
    }
    datiAnagrafici {
        codiceFiscale
        nome
        cognome
        sesso
        statoCivile {
            uniqueId
            codice
            descrizione
        }
    }
    datiNascita {
        data
        note
        comuneEstero
        provincia {
            uniqueId
        }
        comune {
            uniqueId
        }
        nazione {
            uniqueId
        }
    }
    datiRecapito {
        recapiti {
            valore
            tipologiaRecapito {
                uniqueId
                descrizione
            }
        }
        recapitoPreferenziale
    }
    datiResidenza {
        denominazione
        note
        luogo {
            ...luogoInfo
        }
        tipologiaResidenza {
            uniqueId
        }
    }
    datiDomicilio {
        coincideConResidenza
        denominazione
        note
        luogo {
            ...luogoInfo
        }
    }
    datiCorrispondenza {
        coincideConResidenza
        denominazione
        note
        luogo {
            ...luogoInfo
        }
    }
    documentiIdentita {
        dataRilascio
        dataScadenza
        nomeEnteEmittente
        numeroDocumento
        tipologiaEnteEmittenteAltro
        tipologiaDocumentoIdentita {
            uniqueId
        }
        tipologiaEnteEmittente {
            uniqueId
        }
    }
    allegati {
        uniqueId
        metadata {
            insertDate
            updateDate
        }
        note
        tipologiaDocumentoAllegato {
            uniqueId
            descrizione
        }
        documento {
            uniqueId
            fileName
            fileLength
            fileExt
            hash
        }
    }
`;

export const LUOGO_FRAGMENT = `
fragment luogoInfo on Luogo {
    localita
    cap
    note
    comuneEstero
    comune {
        uniqueId
        descrizione
    }
    provincia {
        uniqueId
        descrizione
    }
    nazione {
        uniqueId
    }
}
`;