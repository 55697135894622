import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { Spinner, Button, Modal, ListGroup } from 'react-bootstrap';
import { DataContext, gql, useDataRequest, useFormViewer } from "@ai4/data-request";
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { _assoc } from '../anagrafiche/common';
import { selectCurrentUser, selectPermissions, hasPermission } from 'src/@bootstrap/features/auth';
import { currentDateTime } from 'src/app/helpers/helpers';

const RELATED = gql`
	query getRelatedAccessoDiretto {
        struttureServiziQuery {
            prestazioni {
                list {
                    uniqueId
                    nome
                }
            }
            operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
				}
			}

        }
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
                tipologiaAccessoDiretto {
					list {
						uniqueId
						descrizione
					}
				}
				motivoNonEsecuzione {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

interface AnnullamentoProps {
    record: {
        uniqueId: string;
        affidamentoUniqueId: string;
        stato: string;
        ricorrenzaId?: string;
        prestazioniPianificate?: any[];
        note?: string;
    };
    onSuccess: () => void;
    onCancel: () => void;
}

export const useAccessoAnnullamento = (props: AnnullamentoProps) => {
    const { record, onSuccess, onCancel } = props;
    const { uniqueId, affidamentoUniqueId } = record || {};
    
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);
    const [exec, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	const annulla = useCallback(async (values) => {
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi/diretto`;
		await exec({
			path: `${BASE}/${uniqueId}/annulla`,
			method: 'PATCH',
            data: {
                dati: values,
            }
		});
        onSuccess();
	}, [uniqueId, affidamentoUniqueId]);

	var schemaAnnullamento = require('./schema/edit/accessoDiretto.annullamento.json');
	schemaAnnullamento = useSelectItemsPopulate(
		schemaAnnullamento as Schema,
        related, {
            motivoNonEsecuzioneUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.motivoNonEsecuzione.list', []).map(_assoc),
                { listManagement: { name: 'MotivoNonEsecuzione', query_name: 'getRelatedAccessoDiretto' } },
            ],
        }
	);

    const annullamentoModale = () => {
        if (!uniqueId) return null;
        return <Modal show={true} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Annullamento accesso</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!schemaAnnullamento && <Spinner />}
                {schemaAnnullamento && <>
                    <FormViewer
                        initialValues={{ uniqueId }}
                        schema={schemaAnnullamento}
                        onSubmit={({ values }) => annulla(values)}
                        slots={{
                            ButtonBar: (args) => <>
                                <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                                    <Button variant="secondary" onClick={onCancel}>
                                        Annulla
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Salva
                                    </Button>
                                </div>
                            </>
                        }}
                    />
                </>}
            </Modal.Body>
        </Modal>;
    }
        
    return {
        annullamentoModale,
    }
}

const CACHE_KEY = `accessoDiretto::inizioErogazione`;

export const useAccessoErogazione = (props: AnnullamentoProps) => {
    const { record, onSuccess, onCancel } = props;
    const { uniqueId, affidamentoUniqueId, prestazioniPianificate, note } = record || {};
    const $dateInizioSalvate = useRef<any>();
    const [dataOreInizioErogazione, setDataOreInizioErogazione] = useState<string>();

    useEffect(() => {
        $dateInizioSalvate.current = JSON.parse(localStorage.getItem(CACHE_KEY) || `{}`);
        if (uniqueId) {
            let dataOreInizioErogazione = _get($dateInizioSalvate.current, uniqueId);
            if (!dataOreInizioErogazione) {
                dataOreInizioErogazione = currentDateTime();
                $dateInizioSalvate.current[uniqueId] = dataOreInizioErogazione;
                localStorage.setItem(CACHE_KEY, JSON.stringify($dateInizioSalvate.current));
            }
            setDataOreInizioErogazione(dataOreInizioErogazione);
        }
    }, [uniqueId]);
    
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);
    const [exec, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

    const clearDataErogazione = useCallback(() => {
        try {
            delete $dateInizioSalvate.current[uniqueId];
            localStorage.setItem(CACHE_KEY, JSON.stringify($dateInizioSalvate.current));
        } catch(e) {}
    }, [uniqueId, $dateInizioSalvate]);

	const erogazione = useCallback(async (values) => {
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi/diretto`;
		await exec({
			path: `${BASE}/${uniqueId}/eroga`,
			method: 'PATCH',
            data: {
                dati: {
                    ...values,
                    dataOreFineErogazione: currentDateTime(),
                },
            }
		});
        clearDataErogazione();
        onSuccess();
	}, [uniqueId, affidamentoUniqueId, $dateInizioSalvate, clearDataErogazione]);

	const annullaErogazione = useCallback(() => {
        clearDataErogazione();
        onCancel();
	}, [clearDataErogazione]);

    const operatori = useOperatoriDisponibili(related);
    var schemaErogazione = require('./schema/edit/accessoDiretto.erogazione.json');
	schemaErogazione = useSelectItemsPopulate(
		schemaErogazione as Schema,
        related, {
            prestazioniErogate: [
                ..._get(related.data, 'struttureServiziQuery.prestazioni.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
            ],
        }
	);

    const erogazioneModale = () => {
        if (!uniqueId) return null;
        return <Modal show={true} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Erogazione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!schemaErogazione && <Spinner />}
                {schemaErogazione && dataOreInizioErogazione && <>
                    <FormViewer
                        initialValues={{
                            uniqueId,
                            dataOreInizioErogazione,
                            prestazioniErogate: (prestazioniPianificate || []).map(p => p.prestazione.uniqueId),
                            note
                        }}
                        schema={schemaErogazione}
                        onSubmit={({ values }) => erogazione(values)}
                        slots={{
                            ButtonBar: (args) => <>
                                <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                                    <Button variant="secondary" onClick={annullaErogazione}>
                                        Annulla
                                    </Button>
                                    <Button variant="primary" type='submit'>
                                        Fine erogazione
                                    </Button>
                                </div>
                            </>
                        }}
                    />
                </>}
            </Modal.Body>
        </Modal>;
    };
        
    return {
        erogazioneModale,
    }
}


export const useAccessoElimina = (props: AnnullamentoProps) => {
    const { record, onSuccess, onCancel } = props;
    const { uniqueId, affidamentoUniqueId, ricorrenzaId } = record || {};
    const [mode, setMode] = useState<string>();
    const isRicorrenza = !!ricorrenzaId;

    const { useRestRequest } = useDataRequest();
    const [exec, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	const elimina = useCallback(async (mode) => {
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi`;
		await exec({
			path: mode === 'diretto' ? `${BASE}/diretto/${uniqueId}` : `${BASE}/${uniqueId}/pianificazione`,
			method: 'DELETE',
            data: mode === 'diretto' ? undefined : {
                dati: {
                    tipoModifica: mode
                },
            }
		});
        onSuccess();
	}, [uniqueId, affidamentoUniqueId]);

    const eliminazioneModale = () => {
        if (!uniqueId) return null;
        if (record.stato === 'eseguito') {
            return eliminazioneConferma();
        }
        return <>
            <Modal show={true} onHide={onCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminazione accesso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {isRicorrenza && <>
                        <p>Quali accessi vuoi eliminare?</p>
                        <ListGroup>
                            <ListGroup.Item action active={mode === 'Selezionato'} onClick={() => setMode('Selezionato')}>
                                Questo accesso
                            </ListGroup.Item>
                            <ListGroup.Item action active={mode === 'SelezionatoSuccessivi'} onClick={() => setMode('SelezionatoSuccessivi')}>
                                Tutti i successivi
                            </ListGroup.Item>
                            <ListGroup.Item action active={mode === 'Tutti'} onClick={() => setMode('Tutti')}>
                                Tutti quelli della ricorrenza
                            </ListGroup.Item>
                        </ListGroup>
                    </>}
                    {!isRicorrenza && <>
                        <p>Sei sicuro di voler continuare?</p>
                    </>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel}>Annulla</Button>
                    <Button variant="primary" disabled={!mode && isRicorrenza} onClick={() => elimina(isRicorrenza ? mode : 'diretto')}>Procedi all'eliminazione</Button>
                </Modal.Footer>
            </Modal>
        </>;
    };

    const eliminazioneConferma = () => {
        if (!uniqueId) return null;
        return <>
            <Modal show={true} onHide={onCancel}>
                <Modal.Body>
                    <p>Sei sicuro di voler eliminare questo accesso?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onCancel}>Annulla</Button>
                    <Button variant="primary" onClick={() => elimina('diretto')}>Procedi</Button>
                </Modal.Footer>
            </Modal>
        </>;
    };
        
    return {
        eliminazioneModale,
        eliminazioneConferma,
    }
}

export const useOperatoriDisponibili = (related) => {
    const user = useSelector(selectCurrentUser);
    const permissions = useSelector(selectPermissions);
    const $operatori = _get(related.data, 'struttureServiziQuery.operatori.list', []);
    if (hasPermission('GestioneAccessoGlobale', permissions)) return $operatori;
    if (hasPermission('GestioneAccessoBase', permissions)) return $operatori.filter(o => _get(o, 'utenteCollegato.email') === user.email);
    return [];
}