import _get from 'lodash/get';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Modal, Button, Spinner, ListGroup, Tabs, Tab } from 'react-bootstrap';
import moment from "moment";
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { useDataDecorator } from 'src/app/helpers/data';
import { selectCurrentUser, selectPermissions, hasPermission } from 'src/@bootstrap/features/auth';
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import $schemaNuovo from '../schema/edit/nuovoAccesso.json';
import $schemaErogato from '../schema/edit/accessoErogato';
import $schemaPianificato from '../schema/edit/accessoPianificato';
import { formatDate } from 'src/app/helpers/helpers';
import { _assoc } from '../../anagrafiche/common';
import { useAccessoAnnullamento, useAccessoElimina, useAccessoErogazione, useOperatoriDisponibili } from '../common';
import { GET_CALENDARIO_ACCESSO } from '../gql/calendario.accesso';

import {
	LazyQueryHookOptions,
	MutationHookOptions,
	QueryHookOptions,
	useLazyQuery as useLazyQueryApollo,
	useMutation as useMutationApollo,
	useQuery as useQueryApollo,
} from '@apollo/client';

const RELATED_CALENDARIO_ACCESSO = gql`
    query getRelatedAccessoCalendario {
        anagraficaQuery {
            assistiti {
                list {
                    uniqueId
                    datiAnagrafici {
                        nome
                        cognome
                    }
                }
            }
        }
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaProgetto {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
                    utenteCollegato {
                        email
                        userName
                    }
				}
			}
            prestazioni {
                list {
                    uniqueId
                    nome
                }
            }
		}
	}
`;

const GET_AFFIDAMENTI_BY_ASSISTITO = gql`
    query getAffidamentiByAssistito($assistitoUniqueId: String) {
        cartellaSocioSanitariaQuery {
            affidamenti {
                list(
                  uniqueIdAssistito: $assistitoUniqueId
                ) {
                  uniqueId
                  codice
                }
            }
        }
    }
`;

interface Props {
    record: any;
    onReload: () => void;
    onClose: () => void;
}

export function CreateAccesso(props: Props) {
    return null;
}

export function EditAccesso(props: Props) {
    const { record: srcRecord, onReload, onClose } = props;
    const related = useQueryApollo(RELATED_CALENDARIO_ACCESSO);
    const query = useQueryApollo(GET_CALENDARIO_ACCESSO, {
        variables: { id: srcRecord.uniqueId },
        skip: !srcRecord.uniqueId, 
    });
    return <Modal show={true} onHide={onClose}>
        <Modal.Body>
            TEST
        </Modal.Body>
    </Modal>;
}

export default EditAccesso;
