import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

interface Props {
    path: string[];
    onBack?: () => void;
    frontendLink?: JSX.Element;
}

export function TitleBar(props: Props) {
	const { path, onBack, frontendLink } = props;
    
    const _path = path.filter(p => !!p);
    return (
        <header className="path">
            <div className="back" onClick={() => { if (onBack) onBack(); }}>
                <Button variant="outline-secondary"><i className="bi bi-chevron-left" /></Button>
            </div>
            {_path.map((pt, k) => {
                return <Fragment key={k}>
                    <span>
                        {pt}
                    </span>
                    {(k === 0 && _path.length > 1) && <span className="path-div"> / </span>}
                </Fragment>;
            })}
            {frontendLink}
        </header>
    );
}

export default TitleBar;
