import React, { Component, useState } from "react";
import { isMobile } from 'react-device-detect'
import { useNavigate } from "react-router-dom";
import { Helpers } from '@ai4/records-management';
import TableWidget from "./TableWidget";
import { Spinner } from "react-bootstrap";
import { formatDate } from "src/app/helpers/helpers";

interface Props {
  data?: {
    uniqueId: string;
    cartellaUniqueId: string;
    assistito: string;
    dataPianificata: string;
    operatore: string;
    stato: number;
    tipologia: number;
  }[];
}

export function AccessiPianificati(props: Props) {
  const { data = [] } = props;

  const state = {
    dataTablePage: 0,
    dataTablePerPage: 6,
  };

let { dataTablePage, dataTablePerPage } = state;

let dotStyle = { marginLeft: '4px', backgroundColor: 'black !important'};

return (  
  <div className="card h-100">
    <div className="card-body">
      <div className="ul-widget__head border-0 mb-2">
        <div className="ul-widget__head-label">
          <h3 className="ul-widget__head-title">Prossimi accessi pianificati</h3>
        </div>
      </div>
      {!data && <Spinner />}
      {data && data.length === 0 && <div className="empty">Nessun accesso pianificato</div>}
      {data && data.length > 0 && <>
      <TableWidget
        data={data}
        ipp={dataTablePerPage}
        head={() => <thead>
          <tr className="ul-widget6__tr--sticky-th">
            <th scope="col">Operatore</th>
            <th scope="col">Data</th>
            {!isMobile && <>
            <th scope="col">Assistito</th>
            </>}
          </tr>
        </thead>}
        row={(item, i) => <tr key={i} >
          <td>{item.operatore}</td>
          <td>{formatDate(item.dataPianificata, 'date')}</td>
          {!isMobile && <>
          <td>{item.assistito}</td>
          </>}
          {/*
          <td>
            <a href={`/cartelle/dettaglio/${item.cartellaUniqueId}#${Helpers.buildHash([{ tab: 'accessiDiretti' }, { entity: 'accessiDiretti', action: 'edit', uniqueId: item.uniqueId }])}`}>
                <i className="bi bi-arrow-right-circle" />
            </a>
          </td>
          */}
        </tr>}
      />
      </>}
    </div>
  </div>);
}

export default AccessiPianificati;
