import _get from 'lodash/get';
import _set from 'lodash/set';
import _isEqual from 'lodash/isEqual';
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { useCallback, useContext, useMemo, useRef } from "react";
import { assoc } from 'src/@bootstrap/components/Crud/helpers';
import { getRoot, useFormBuilderSchemaLoader, useSelectItemsPopulate, useUpdateSchemaNode, } from "../../helpers/data";
import { GET_COMUNI } from "./gql/anagrafica";
import $filters from './schema/filters/anagrafica.json';

interface Args {
    module: string;
    entity: string;
    graphql: {
        list: any;
        details: any;
    };
    lists?: string[];
    lists_servizi?: string[];
}

export const useAnagraficaModule = (args: Args) => {
    const { module, entity, graphql, lists = [], lists_servizi = [] } = args;
	const { domain, getClient } = useContext(DataContext);
	const { useRestRequest, useQuery } = useDataRequest();
    const getListsQuery = useCallback(() => {
        return gql`
            query getLists_${entity} {
                anagraficaQuery {
                    elenchiAnagrafica {
                        ${['statoCivile', 'tipologiaResidenza', ...lists].map((list) => {
                            return `${list} {
                                list {
                                    uniqueId
                                    descrizione
                                }
                            }`;
                        }).join('\n')}
                    }
                }
                ${lists_servizi.length > 0 ? `
                struttureServiziQuery {
                    elenchiStruttureServizi {
                        ${[lists_servizi].map((list) => {
                            return `${list} {
                                list {
                                    uniqueId
                                    descrizione
                                }
                            }`;
                        }).join('\n')}
                    }
                }
                ` : ''}
                elenchiQuery {
                    elenchiGeneraliQuery {
                        provincie {
                            list {
                                uniqueId
                                siglaProvincia
                                descrizione
                            }
                        }
                        nazioni {
                            list {
                                uniqueId
                                denominazioneIT
                            }
                        }
                    }
                }
            }
        `;
    }, []);
    const related = useQuery(getListsQuery());
    const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resRemove] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const save = useCallback(
        async (values) => {
            const { uniqueId } = values;
            // extraData
            const { recapiti, tessereSanitarie, documentiIdentita } = values;
            let newValues = { ...values };
            newValues = _set(newValues, 'datiRecapito.recapiti', recapiti);
            newValues = _set(newValues, 'documentiIdentita', documentiIdentita);
            newValues = _set(newValues, 'datiSanitari.tessereSanitarie', tessereSanitarie);
			const insertedId = await execSave({
				path: `api/{ver}/${module}/${entity}${uniqueId ? `/${uniqueId}` : ''}`,
				method: uniqueId ? 'PUT' : 'POST',
				data: {
					...newValues,
				},
			});
            if (getClient) {
                await getClient(domain).refetchQueries({
                    include: [graphql.list],
                });
            }
			return {
				uniqueId: uniqueId || insertedId,
				...values,
			}
        },
        []
    );

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `api/{ver}/${module}/${entity}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [graphql.list],
            });
        }
	}, []);

    const filterSchema = useSelectItemsPopulate(
		$filters as any,
		related, {
			'statoCivile': _get(related.data, "anagraficaQuery.elenchiAnagrafica.statoCivile.list", []).map(_assoc),
		}
	);
    return {
        related,
        crud: {
            actions: {
                save,
                remove,
            },
            results: {
                save: resSave,
                remove: resRemove,
            },
        },
        filters: {
            schema: filterSchema,
            queryWithVars: () => {
				return `${graphql.list} (
					$nome: String
					$cognome: String
					$codiceFiscale: String
					$sesso: String
                    $statoCivile: String
					$dataNascitaFromDate: String
					$dataNascitaToDate: String
                    $pageNumber: Int! = 0, $pageSize: Int! = 10
				)`
			},
			listFilters: () => {
                return `pagedList(
                    nome: $nome
                    cognome: $cognome
                    codiceFiscale: $codiceFiscale
                    sesso: $sesso
                    statoCivile: $statoCivile
                    dataNascitaFromDate: $dataNascitaFromDate
                    dataNascitaToDate: $dataNascitaToDate
					pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                )`;
            },
        }
    }
}

const PROVINCE = gql`
    query getProvince {
        elenchiQuery {
            elenchiGeneraliQuery {
                nazioni {
                    list {
                        uniqueId
                        codiceISO3166alpha3
                        descrizione
                    }
                }
                provincie {
                    list {
                        uniqueId
                        siglaProvincia
                        descrizione
                    }
                }
            }
        }
    }
`;

interface ChangeComuniByProvinciaArgs {
    ns?: string;
    values: any;
    setValues: any;
    getSchemaNode: any;
    setSchemaNode: any;
}

export const useChangeComuniByProvincia = () => {
    const { domain, getClient } = useContext(DataContext);
	const { useQuery } = useDataRequest();
    const province = useQuery(PROVINCE);
    const client = getClient ? getClient(domain) : undefined;
    const execComuni = useCallback(({ variables }: any) => {
        if (client) {
            return client.query({
                query: GET_COMUNI,
                variables
            });
        }
        return Promise.resolve();
    }, []);
    
    const prevValues = useRef<Record<string, any>>();
    const onChangeProvincia = useCallback((args: ChangeComuniByProvinciaArgs) => {
        const { ns, values, setValues, getSchemaNode, setSchemaNode } = args;
        const nazione = _get(province.data, "elenchiQuery.elenchiGeneraliQuery.nazioni.list", []).find(p => p.uniqueId === _get(values, `${ns ? `${ns}.` : ''}nazioneUniqueId`));
        const provincia = _get(province.data, "elenchiQuery.elenchiGeneraliQuery.provincie.list", []).find(p => p.uniqueId === _get(values, `${ns ? `${ns}.` : ''}provinciaUniqueId`));
        const names = {
            comune: `${ns ? `${ns}.` : ''}comuneUniqueId`,
            provincia: `${ns ? `${ns}.` : ''}provinciaUniqueId`,
            nazione: `${ns ? `${ns}.` : ''}nazioneUniqueId`,
            localita: `${ns ? `${ns}.` : ''}localita`,
            cap: `${ns ? `${ns}.` : ''}cap`,
            estero: `${ns ? `${ns}.` : ''}comuneEstero`,
        };
        const valuesA = Object.keys(names).reduce((acc, k) => ({ ...acc, [k]: _get(values, names[k]) }), {});
        const valuesB = Object.keys(names).reduce((acc, k) => ({ ...acc, [k]: _get(prevValues.current, names[k]) }), {});
        if (_isEqual(valuesA, valuesB)) {
            return;
        }
        const isEstero = nazione && nazione.codiceISO3166alpha3 !== 'ITA';
        const estero = getSchemaNode(names.estero);
        if (estero) {
            setSchemaNode(names.estero, {
                ...estero,
                props: { ...(estero.props || {}), disabled: !isEstero }
            });
        }
        ['provincia', 'comune', 'localita', 'cap'].forEach((k) => {
            const node = getSchemaNode(names[k]);
            if (node) {
                setSchemaNode(names[k], {
                    ...node,
                    props: { ...(node.props || {}), disabled: isEstero }
                });
            }
        });
        const node = getSchemaNode(names.comune);
        if (node) {
            if (provincia && !isEstero) {
                execComuni({ variables: { provincia: provincia.siglaProvincia }}).then(res => {
                    const items = _get(res.data, 'elenchiQuery.elenchiGeneraliQuery.comuni.list', [])
                    setSchemaNode(names.comune, {
                        ...node,
                        props: {
                            ...(node.props || {}),
                            items: items.map(_assoc),
                            disabled: false,
                        },
                    });
                })
            } else {
                setSchemaNode(names.comune, {
                    ...node,
                    props: { ...(node.props || {}), disabled: true }
                });
            }
        }
        prevValues.current = { ...values };
    }, [province]);

    return {
        onChangeProvincia
    }
}

export const _assoc = assoc;
export const _revid = (entity: string) => `uniqueId${entity.charAt(0).toUpperCase() + entity.slice(1)}`;

interface UseAnagraficaSelectItemsPopulateArgs {
    schema: any;
    related: any;
}

export const useAnagraficaSelectItemsPopulate = (args: UseAnagraficaSelectItemsPopulateArgs) => {
    const { schema, related } = args;

    const LISTS = {
		nazioni: _get(related.data, "elenchiQuery.elenchiGeneraliQuery.nazioni.list", []).map((cat: any) => ({ text: cat.denominazioneIT, value: cat.uniqueId })),
		provincie: _get(related.data, "elenchiQuery.elenchiGeneraliQuery.provincie.list", []).map(_assoc),
	}

    return useSelectItemsPopulate(
		schema as any,
		related,
		{
			'datiAnagrafici.statoCivileUniqueId': _get(related.data, "anagraficaQuery.elenchiAnagrafica.statoCivile.list", []).map(_assoc),
			'datiNascita.nazioneUniqueId': LISTS.nazioni,
			'datiNascita.provinciaUniqueId': LISTS.provincie,
			'datiResidenza.tipologiaResidenzaUniqueId': _get(related.data, "anagraficaQuery.elenchiAnagrafica.tipologiaResidenza.list", []).map(_assoc),
			'datiResidenza.luogo.nazioneUniqueId': LISTS.nazioni,
			'datiResidenza.luogo.provinciaUniqueId': LISTS.provincie,
			'datiDomicilio.luogo.nazioneUniqueId': LISTS.nazioni,
			'datiDomicilio.luogo.provinciaUniqueId': LISTS.provincie,
			'datiCorrispondenza.luogo.nazioneUniqueId': LISTS.nazioni,
			'datiCorrispondenza.luogo.provinciaUniqueId': LISTS.provincie,
		}
	);
}

/*
export const usePopulateTabs = (schema, keys) => {
    return useMemo(() => {
        var result: any;
        keys.forEach(key => {
            const [id] = key.split('-');
            // eslint-disable-next-line react-hooks/rules-of-hooks
            result = useUpdateSchemaNode(
                schema,
                id, (node: any) => {
                    return {
                        ...node,
                        rows: [
                            ...getRoot(require(`./schema/${key}.json`)),
                        ],
                    };
                },
            );
        });
        return result;
    }, [schema, keys]);
}
*/  

export const useFillTabs = (schema, map) => {
    const toLoad = Object.keys(map).reduce<string[]>((acc, key) => { return [...acc, ...(map[key] || [])] }, []).map(v => `presets.${v}`);
    const schemas = useFormBuilderSchemaLoader(toLoad);
    
    return useMemo(() => {
        var result: any;
        Object.keys(map).forEach(key => {
            if (!schemas) return schema;
            const sections = map[key];
            // eslint-disable-next-line react-hooks/rules-of-hooks
            result = useUpdateSchemaNode(
                { ...schema },
                key, (node: any) => {
                    if (sections === false) return undefined;
                    return {
                        ...node,
                        rows: sections.reduce((acc, sect) => [...acc, ...getRoot(schemas[`presets.${sect}`])], []),
                    };
                },
            );
        });
        return result;
    }, [schema, schemas, map]);
}

export const getEditFormViewer = (props: any) => {
    const {
        record,
        onAddExtraData,
        handleClose,
        onSelectFiles,
        onDownloadFile,
        onRemoveFile,

        formSchema,
        formOptions,
        onSetHelpers,
        onInit,
        onChange,
        onDirty,
        onValidate,
        onSubmit,
        onCancel,
        onSuccess,
        onError,
        submitResponse,
        ...rest
    } = props;

    return {
        ...rest,
        schema: formSchema,
        initialValues: record || null,
        options: formOptions,
        onCancelWhenModified: () => {
            return `Continuando perderai tutte le tue modifiche. Vuoi continuare?`;
        },
        onInit: (form: any) => {
            if (onSetHelpers) onSetHelpers(form);
            if (onInit) onInit(form);
        },
        onChange: (form: any) => {
            if (onChange)
                onChange({
                    ...form,
                    setExtraData: onAddExtraData,
                });
            if (onDirty) onDirty(form.dirty);
        },
        onValidate: (args: any) => {
            const { values, extraData } = args;
            if (onValidate) {
                return onValidate({
                    values: {
                        ...values,
                        ...extraData,
                    },
                });
            }
            return null;
        },
        onSubmit: (args: any) => {
            const { values, form, extraData } = args;
            const newArgs = { ...args, values: { ...values, ...extraData } };
            onSubmit(newArgs)
                .then((res) => {
                    const values = res.data;
                    // form.resetForm({ values });
                    if (onSuccess) onSuccess(values);
                })
                .catch((err) => {
                    if (onError) onError(err);
                })
                .finally(() => {
                    form.setSubmitting(false);
                });
        },
    }
}