import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import { GraphQLError } from '@ai4/form-viewer/dist/types/general';
import { ErrorElement, FormViewer } from '@ai4/form-viewer';
import { ConfigContext } from 'src/@bootstrap/Config';
import { scriptsLoader } from 'src/@bootstrap/services/scripts';

interface AlertWrapperProps {
    show: boolean;
    onClick?: () => void;
    children: any;
}

function AlertWrapper(props: AlertWrapperProps) {
	return (
		<div className={`Alert-wrapper ErrorCatcher-wrapper ${props.show ? '' : 'd-none'}`} onClick={props.onClick || undefined}>
			{props.children}
		</div>
	);
}

interface Props {
	title?: string;
	errors: ErrorElement;
	hideMessages?: boolean;
	onClose?: () => void;
}

export function ErrorCatcher(props: Props) {
	const { title, errors, hideMessages, onClose } = props;
    const { getEnv } = useContext(ConfigContext);
    const showCollectorDialog = useRef<() => void>();
    const loaded = useRef<boolean>(false);
    const [show, setShow] = useState(true);
	const ISSUES_URL = getEnv("REACT_APP_JIRA_ISSUE");

	const errs = useMemo(() => {
		let errs = [] as GraphQLError[];
		if (typeof errors === 'string') {
			errs.push({ message: errors });
		} else {
			errs = (errors as any[]).map((error) => {
				if (typeof error === 'string') {
					return { message: error };
				}
				return error;
			});
		}
		return errs;
	}, [errors]);

    const sendIssue = useCallback(() => {
        if (ISSUES_URL) {
            const scripts: string[] = [];
            if (!loaded.current) scripts.push('https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js');
            scripts.push(ISSUES_URL);
            /** Load Jira script if present */
            scriptsLoader
            .reset()
            .addScript(scripts)
            .onComplete(() => {
                loaded.current = true;
                // @ts-ignore
                window.ATL_JQ_PAGE_PROPS =  {
                    triggerFunction: function(fn) {
                        showCollectorDialog.current = fn;
                        fn();
                        setShow(false);
                        if (onClose) onClose();
                    },
                    fieldValues: () => {
                        const hr = `\r\n------------------\r\n`;
                        return {
                            description : [
                                `DETTAGLI TECNICI:\r\n\r\n${errs.map(err => (
                                    `Codice: ${err.code || '-'}\r\nRequest ID: ${err.requestId || '-'}\r\nMessage: ${err.message || '-'}`
                                )).join(`\r\n`)}${hr}`,
                            ].join(''),
                            priority : '2'
                        }
                    },
                };
            })
            .load();
        }
    }, [errs]);

	return createPortal(
		<AlertWrapper show={show}>
			<ToastContainer className='p-3 position-fixed' style={{ zIndex: 2000 }} position='bottom-end'>
				<Toast onClose={onClose}>
					<Toast.Header className='ErrorCatcher-header error'>
						<strong className='me-auto'>{title || 'Errore'}</strong>
					</Toast.Header>
					<Toast.Body className='ErrorCatcher-body'>
						<table className='table table-sm table-borderless'>
							<tbody>
								{errs &&
									errs.map((err, k) => {
										const hasMessage = !hideMessages && err.message;
										return <Fragment key={k}>
											{!hasMessage && err.code && (
												<tr>
													<td>
														<strong>Codice:</strong>
													</td>
													<td>{err.code}</td>
												</tr>
											)}
											{hasMessage && (
												<tr>
													<td colSpan={2}>
														<div className='message'>{err.message}</div>
													</td>
												</tr>
											)}
											{err.requestId && (
												<tr>
													<td colSpan={2} style={{ fontSize: `11px`, color: '#999' }}><small>Request ID:</small> <small>{err.requestId}</small></td>
												</tr>
											)}
										</Fragment>
									})}
							</tbody>
						</table>
                        {!!ISSUES_URL && <div className='ErrorCatcher'>
                            <Button style={{}} onClick={() => sendIssue()}>Invia segnalazione</Button>
                        </div>}
					</Toast.Body>
				</Toast>
			</ToastContainer>
		</AlertWrapper>,
		document.body
	);
}
export default ErrorCatcher;
