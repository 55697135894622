import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Modal, Button, Table, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { setMessage } from "src/@bootstrap/features/app";
import { useDataDecorator, useSelectItemsPopulate } from 'src/app/helpers/data';
import $schema from './schema/edit/dimissioni.json';
import { GET_CARTELLA } from './gql/cartella';
import { GET_DIMISSIONE } from './gql/dimissione';
import { _assoc } from '../anagrafiche/common';

const RELATED = gql`
	query getRelatedDimissione {
		cartellaSocioSanitariaQuery {
            elenchiCartellaSocioSanitaria {
                dimissioneMotivo {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                dimissioneEsito {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                dimissioneStatoObiettivi {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
	}
`;

export function EditCartellaDettaglioDimissioni(props: any) {
    const { parent, onBack } = props;
    const { uniqueId, schede } = parent;
    
    const { domain, getClient } = useContext(DataContext);
    const { useQuery, useRestRequest } = useDataRequest();
    const [execSave, res] = useRestRequest({
        path: `api/{ver}/cartellasociosanitaria/cartelle/${uniqueId}/dimissione`,
        jwt: true,
    });
    const [execRemove] = useRestRequest({
        path: `api/{ver}/cartellasociosanitaria/cartelle/${uniqueId}/dimissione`,
        method: 'DELETE',
        jwt: true,
    });
    const dispatch = useDispatch();

    const related = useQuery(RELATED);
    const current = schede.find(s => s.tipologia.toUpperCase() === 'DIMISSIONE');
    const query = useQuery(GET_DIMISSIONE, {
        skip: !current,
        variables: {
            id: current ? current.uniqueId : undefined,
        }
    });
    const data = useDataDecorator('cartellaSocioSanitariaQuery.schedaDimissione.list', query.data);
    const record = useMemo(() => {
        const res = data.length > 0 ? data[0] : {};
        return {
            ...res,
            motivoDimissioneUniqueId: res.motivoUniqueId,
            esitoDimissioneUniqueId: res.esitoUniqueId,
            statoDimissioneUniqueId: res.statoUniqueId,
        }
    }, [data]);

    const schema = useSelectItemsPopulate(
        $schema as unknown as any,
        related,
        {
            motivoDimissioneUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.dimissioneMotivo.list', []).map(_assoc),
                { listManagement: { name: 'DimissioneMotivo', query_name: 'getRelatedDimissione' } },
            ],
            esitoDimissioneUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.dimissioneEsito.list', []).map(_assoc),
                { listManagement: { name: 'DimissioneEsito', query_name: 'getRelatedDimissione' } },
            ],
            statoDimissioneUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.dimissioneStatoObiettivi.list', []).map(_assoc),
                { listManagement: { name: 'DimissioneStatoObiettivi', query_name: 'getRelatedDimissione' } },
            ],
        }
    );

    const remove = useCallback(async () => {
        await execRemove();
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [GET_CARTELLA],
            });
        }
        dispatch(
            setMessage('Dati rimossi correttamente.')
        );
        onBack();
    }, [record]);

    const submit = useCallback(async ({ values }) => {
        await execSave({
            method: record && record.uniqueId ? 'PUT' : 'POST',
            data: {
                dati : {
                    ...values,
                },
            },
        });
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [GET_CARTELLA],
            });
        }
        dispatch(
            setMessage('Dati salvati correttamente.')
        );
    }, [record]);

    if (query.loading || related.loading) return <Spinner />
    return <div>
        <h2>Dimissioni cartella</h2>
        <FormViewer
            initialValues={record}
            schema={schema as Schema}
            onSubmit={submit}
            slots={{
                ButtonBar: (args) => <>
                    <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                        {record.uniqueId && <>
                        <Button
                            variant="danger"
                            onClick={(e) => {
                                e.stopPropagation();
                                if (window.confirm("Vuoi continuare?")) {
                                    remove();
                                }
                            }}
                        >
                            Elimina scheda dimissione
                        </Button>
                        </>}
                        <Button variant="primary" type='submit' className='ms-auto'>
                            Salva
                        </Button>
                    </div>
                </>
            }}
        />
    </div>;
}

export default EditCartellaDettaglioDimissioni;
