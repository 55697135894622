import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Modal, Stack, Table } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import { useFormBuilderSchemaLoader, useSelectItemsPopulate } from 'src/app/helpers/data';
import Schema from '@ai4/form-viewer/dist/types/schema';

interface ButtonBarProps {
	args: any;
	onCancel: () => void;
}

export const ButtonBar = (props: ButtonBarProps) => {
	const { args, onCancel } = props;
	const { form } = args;
	return <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
		<Button variant="secondary" onClick={onCancel}>
			Annulla
		</Button>
		<Button variant="primary" type='submit' disabled={!form.isValid || form.isSubmitting}>
			Salva
		</Button>
	</div>
}

interface Props {
	items: any[];
	related: any;
    onChange: (values: any) => void;
}

const Recapiti = (props: Props) => {
    const { items, related, onChange } = props;
	const [record, setRecord] = useState<any>();

	const handleClose = () => setRecord(undefined);

	const tipologie = _get(related.data, 'anagraficaQuery.elenchiAnagrafica.tipologiaRecapito.list', []);
	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-recapito');
	schema = useSelectItemsPopulate(
		schema as Schema,
		related, {
			tipologiaRecapitoUniqueId: [
				...tipologie.map((cat: any) => ({ text: cat.descrizione, value: cat.uniqueId })),
				{ listManagement: { name: 'TipologiaRecapito', query_name: 'getSubAnagrafica' } },
			],
		}
	);

	const onRemove = useCallback(async (index) => {
        onChange(items.filter((item, i) => i !== index));
	}, [items]);

	if (related.loading) return null;
	return <Fragment>
		<Card className='my-3'>
      		<Card.Body>
				<Stack direction="horizontal" gap={3}>
        			<Card.Title>Recapiti</Card.Title>
					<Button variant='success' className='ms-auto' onClick={() => setRecord({})}>Aggiungi</Button>
				</Stack>
				<Card.Text>
					{items.length === 0 && <div className='empty'>Nessun recapito inserito</div>}
					{items.length > 0 && <Table striped bordered hover>
						<thead>
							<tr>
								<th>Tipologia</th>
								<th>Valore</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{items.map((row, i) => {
							return <tr key={i} style={{ cursor: 'pointer' }} onClick={() => setRecord({ index: i, ...row })}>
								<td>{row.tipologiaRecapito ? row.tipologiaRecapito.descrizione : ''}</td>
								<td>{row.valore}</td>
								<td>
									<Button onClick={(e) => {
										e.stopPropagation();
										if (window.confirm("Vuoi continuare?")) {
											onRemove(i);
										}
									}}>Elimina</Button>
								</td>
							</tr>;
						})}
						</tbody>
					</Table>}
				</Card.Text>
			</Card.Body>
   		</Card>
		<Modal show={!!record} onHide={handleClose}>
			<Modal.Body>
				<FormViewer
					schema={schema as any}
					initialValues={record || {}}
					onSubmit={(args: any) => {
						const { values } = args;
						const tipologiaRecapito = tipologie.find(t => t.uniqueId === values.tipologiaRecapitoUniqueId);
						const obj = { ...values, tipologiaRecapito };
						const newValues = obj.index === undefined ? [...items, obj] : items.map((item, i) => i === obj.index ? obj : item);
            			onChange(newValues);
						handleClose();
					}}
					slots={{
						ButtonBar: (args) => <ButtonBar args={args} onCancel={handleClose} />
					}}
				/>
			</Modal.Body>
		</Modal>
	</Fragment>
}

export default Recapiti;