import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { gql, useDataRequest } from "@ai4/data-request";
import { GET_OPERATORI } from './List';
import { useHideNodesSchema, useSelectItemsPopulate } from 'src/app/helpers/data';
import $schema from './collega.schema.json';
import $schemaNuovo from './nuovoOperatore.schema.json';
import { FormViewer, SchemaTypes } from '@ai4/form-viewer';

interface Props {
    record: any;
    onSuccess: () => void;
    handleClose: () => void;
}

export function CollegaOperatore(props: Props) {
    const {
        record,
        onSuccess,
        handleClose,
    } = props;
    const { useQuery, useRestRequest } = useDataRequest();
	const query = useQuery(GET_OPERATORI);
	const { data, loading } = query;
    const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/users',
        method: 'GET',
        jwt: true,
    });
    const [reqSave, ] = useRestRequest({
        path: 'api/{ver}/struttureservizi/operatori/{id}/collega-utente/{userId}',
        method: 'POST',
        jwt: true,
    });

    useEffect(() => {
        reqList();
    }, []);

    const onSubmit = useCallback(async (values) => {
        if (window.confirm('Sei sicuro di procedere?')) {
            try {
                await reqSave({
                    path: `api/{ver}/struttureservizi/operatori/${values.operatoreUniqueId}/collega-utente/${values.userId}`,
                });
            } catch(e) {
            }
            onSuccess();
            handleClose();
        }
    }, []);

    const operators = _get(data, 'struttureServiziQuery.operatori.list', []);
    const operatorsConnectedUsernames = operators.filter(i => !!i.utenteCollegato).map(i => _get(i, 'utenteCollegato.userName'));
    const operatorsNotConnected = operators.filter(i => !i.utenteCollegato);
    const users = useMemo(() => {
        return (resList.data || []).filter(user => !operatorsConnectedUsernames.includes(user.userName));
    }, [operatorsConnectedUsernames, resList]);

    const schema = useSelectItemsPopulate(
        { ...record && record.uniqueId ? $schema : $schemaNuovo } as SchemaTypes.Schema,
        query,
        {
            userId: users.map((cat: any) => ({ text: `${cat.lastName} ${cat.firstName}`, value: cat.id })),
            operatoreUniqueId: operatorsNotConnected.map((cat: any) => ({ text: `${_get(cat, 'datiAnagrafici.cognome')} ${_get(cat, 'datiAnagrafici.nome')}`, value: cat.uniqueId })),
        }
    );

    if (!schema) return null;
    return (
		<Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Collega operatore</Modal.Title>
            </Modal.Header>
			<Modal.Body>
                <FormViewer
                    schema={schema}
                    initialValues={{}}
                    onSubmit={(args) => {
                        const { values, form } = args;
                        onSubmit({
                            operatoreUniqueId: record.uniqueId || values.operatoreUniqueId,
                            userId: values.userId,
                        });
                    }}
                />
            </Modal.Body>
        </Modal>
    );
}

export default CollegaOperatore;
