import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { pathToSlug } from '../../helpers/helpers';
import { Button, Card, Modal, Spinner, Table } from 'react-bootstrap';
import { FormViewer, SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import $schema from './schema/lista.json';
import { selectLists } from 'src/app/features/custom';

interface PreProps {
    name: string;
    query_name?: string;
}

export function findList(lists, name) {
	if (!lists || !name) return null;
	const list = lists.find(l => l.nome === name);
	return list;
}

const PreGestioneListe = (props: PreProps) => {
	const { name, query_name } = props;
    const lists = useSelector(selectLists);

	const { list, query_key, query_path, } = useMemo(() => {
		const list = findList(lists, name);
		if (list && list.graphInfo) {
			const { graphInfo } = list;
			const query_key = `Elenco_${pathToSlug(name)}`;
			const query_path = `${graphInfo.queryGraphFieldName}.${graphInfo.categoriaElencoGraphFieldName}.${graphInfo.elencoGraphFieldName}`;

			return {
				list, query_key, query_path
			}
		}
		return {};
	}, [name, lists]);

    const gqlQuery = useMemo(() => {
		if (list && list.graphInfo) {
			const { graphInfo } = list;
			const QUERY = gql`
				query ${query_key} {
					${graphInfo.queryGraphFieldName} {
						${graphInfo.categoriaElencoGraphFieldName} {
							${graphInfo.elencoGraphFieldName} {
								list {
									uniqueId
									codice
									descrizione
								}
							}
						}
					}
				}
			`;
			return QUERY;
		}
	}, [list]);

	if (!gqlQuery) return <>Lista non modificabile</>;
	const args = {
		list,
		query_key,
		query_path,
		gqlQuery,
	}
	return <GestioneListe {...args} {...props} />
}

interface Props extends PreProps {
	gqlQuery: any;
	list: any;
	query_key?: string;
	query_path?: string;
}

const GestioneListe = (props: Props) => {
    const { name, list, query_key, query_path, query_name, gqlQuery } = props;
	const [record, setRecord] = useState<any>();

	const { domain, getClient } = useContext(DataContext);
    const { useQuery, useRestRequest } = useDataRequest();
	const query = useQuery(gqlQuery);
	const { data, loading } = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const handleClose = () => setRecord(undefined);
	const onRefresh = useCallback(async () => {
		if (getClient) {
			const queries = [query_key || ''];
			if (query_name) queries.push(query_name);
			await getClient(domain).refetchQueries({
				include: queries,
			});
		}
	}, []);
	const onSave = useCallback(async (values) => {
		const { uniqueId, val } = values;
		const res = await execSave({
			path: `api/{ver}/elenchi/${name}/voci${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				...values,
			},
		});
		onRefresh();
		handleClose();
	}, []);
	const onRemove = useCallback(async (uniqueId) => {
		const res = await execRemove({
			path: `api/{ver}/elenchi/${name}/voci/${uniqueId}`,
			method: 'DELETE'
		});
		onRefresh();
	}, []);

	const schema = useMemo(() => {
		if (list && list.campi) {
			return traverseSchema($schema as any, (n: SchemaTypes.Field) => {
				const campo = list.campi!.find(c => c.nome.toLowerCase() === n.name);
				if (campo && campo.lunghezza) {
					return {
						...n,
						props: {
							...(n.props || {}),
							maxLength: campo.lunghezza,
						}
					};
				}
				return n;
			});
		}
		return $schema;
	}, [list]);

	const rows = _get(data, `${query_path}.list`, []);
    
    if (loading) return <Spinner />
    return <div>
		{rows.length === 0 && <div className='empty'>Nessun risultato</div>}
		{rows.length > 0 && <>
		<Table striped bordered hover>
			<thead>
				<tr>
					<th>#</th>
					<th>Codice</th>
					<th>Descrizione</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
			{rows.map((row, i) => {
				return <tr style={{ cursor: 'pointer' }} onClick={() => setRecord(row)}>
					<td>{i+1}</td>
					<td>{row.codice}</td>
					<td>{row.descrizione}</td>
					<td>
						<Button onClick={(e) => {
							e.stopPropagation();
							if (window.confirm("Vuoi continuare?")) {
								onRemove(row.uniqueId);
							}
						}}>Elimina</Button>
					</td>
				</tr>
			})}
			</tbody>
		</Table>
		</>}
        <Modal show={!!record} onHide={handleClose}>
			<Modal.Body>
				<FormViewer
					schema={schema as any}
					initialValues={record}
					onSubmit={(args: any) => {
						const { values, form } = args;
						onSave(values);
					}}
					slots={{
						ButtonBar: (args) => <>
							<div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
								<Button variant="secondary" onClick={handleClose}>
									Annulla
								</Button>
								<Button variant="primary" type='submit'>
									Salva
								</Button>
							</div>
						</>
					}}
				/>
			</Modal.Body>
		</Modal>
		<div className='d-flex justify-content-end'>
			<Button onClick={() => setRecord({})}>
				Aggiungi
			</Button>
		</div>
    </div>
}

export default PreGestioneListe;