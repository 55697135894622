import _get from 'lodash/get';
import { useCallback, useContext } from 'react';
import { DataContext, gql, useDataRequest, useFileRequest, useFormViewer, useMutationTransformer } from '@ai4/data-request';
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import { useDataDecorator, useFormBuilderSchemaLoader, useSelectItemsPopulate } from "../../helpers/data";
import { filterDefault, filterDocumentArgs, nodeContent } from "../../helpers/helpers";
import { GET_ALLEGATO } from './gql/anagrafica';
import Schema from '@ai4/form-viewer/dist/types/schema';

const RELATED = gql`
	query getSubAllegato {
		anagraficaQuery {
			elenchiAnagrafica {
				tipologiaDocumentoAllegato {
					list {
						uniqueId
						codice
						descrizione
					}
				}
			}
		}
	}
`;


function getUploadUrl( name: string ) {
	let uri = '';
	if (name === 'documento') uri = `api/{ver}/allegati`;
	return uri;
}

export const useModule = (options: any) => {
	const list = `anagraficaQuery.allegati`;
	const mutation = "anagrafica.allegati";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
	const query = useLazyQuery(GET_ALLEGATO);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const dataList = useDataDecorator('allegati', options.parent).map((r: any) => ({...r, _label: r.note}));
	const { getDownloadFileUrl, downloadFile } = useFileRequest({ endpoint: getUploadUrl('documento') });
	const { onSelectFiles, onDownloadFile, onRemoveFile } = useFormViewer({
        file: {
            endpoint: getUploadUrl,
        },
    });

	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-allegato');
	schema = useSelectItemsPopulate(
		schema as Schema,
        related, {
            'tipologiaDocumentoAllegatoUniqueId': [
				..._get(related.data, 'anagraficaQuery.elenchiAnagrafica.tipologiaDocumentoAllegato.list', []).map((cat: any) => ({ text: cat.descrizione, value: cat.uniqueId })),
				{ listManagement: { name: 'TipologiaDocumentoAllegato', query_name: 'getSubAllegato' } },
			],
        }
	);

	const BASE = `api/{ver}/anagrafica/${options.parent.uniqueId}/allegati`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: values,
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [options.graphql.details],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, []);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [options.graphql.details],
            });
        }
	}, []);

	return {
		graphql: {
			list,
            mutation,
            loading,
			details: execDetails,
        },
		actions: {
			save,
			remove,
			upload: {
				onSelectFiles,
				onDownloadFile,
				onRemoveFile,
			},
			list: {
				onLoadList: (rows: any[]) => {
					return rows.map((i: any) => ({ ...i, _label: i.oggetto, descrizione: _get(i, 'categoriaAllegato.nome'), file: i.documento }))
				},
			},
		},
		results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		extra: {
			options: {
				parent: options.parent,
				onCreateInitialValues: {
					[options.update_field]: options.parent.uniqueId,
				},
				modalSize: 'sm',
				closeWhenSaved: true,
			},
		},
		dataList,
		dataDetails,
		table: {
			columns: [
				{
					dataField: 'uniqueId',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: 'tipologiaDocumentoAllegato.descrizione',
					text: 'Tipologia',
					filter: textFilter(filterDefault()),
				},
				{
					dataField: 'documento',
					text: 'Documento',
					dataNode: `documento ${nodeContent('file')}`,
					...filterDocumentArgs({ fieldName: 'documento', onDownloadFile }),
				},
			],
		},
	};
}
