import _get from 'lodash/get';
import _set from 'lodash/set';
import { gql, useDataRequest } from "@ai4/data-request";
import {
	useFormBuilderSchemaLoader,
	useSelectItemsPopulate,
} from "../../helpers/data";
import { pathToSlug } from 'src/app/helpers/helpers';
import { useAnagraficaModule, useAnagraficaSelectItemsPopulate, _assoc } from '../anagrafiche/common';
import EditErogatore from './edit/erogatore';
import { GET_EROGATORE } from './gql/erogatori';

const RELATED = gql`
    query getRelatedErogatore {
		struttureServiziQuery {
			elenchiStruttureServizi {
				tipologiaErogatore {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

export const useModule = () => {
	const list = 'struttureServiziQuery.erogatori';
	const $common = useAnagraficaModule({
		module: 'struttureservizi',
		entity: 'erogatori',
		graphql: { list: pathToSlug(list), details: GET_EROGATORE },
		lists_servizi: ['tipologiaErogatore']
	});
	const mutation = "anagrafica.erogatori";
	const { useQuery, useLazyQuery } = useDataRequest();
	const related = useQuery(RELATED);
	const query = useLazyQuery(GET_EROGATORE);
	const [execDetails, { data: dataDetails, loading }] = query;

	// build schema	
	var schema = useFormBuilderSchemaLoader('presets.erogatore');
	schema = useAnagraficaSelectItemsPopulate({ schema, related: $common.related });
	schema = useSelectItemsPopulate(schema, $common.related, {
		'tipologiaErogatoreUniqueId': [
			..._get(related.data, "struttureServiziQuery.elenchiStruttureServizi.tipologiaErogatore.list", []).map(_assoc),
			{ listManagement: { name: 'TipologiaErogatore', query_name: 'getRelatedErogatore' } },
		],
	});
	
	return {
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
		},
		actions: {
			...$common.crud.actions,
			details: {
				onLoadDetails: (item) => item ? ({
					...item,
					cudesADI: _get(item, 'codice.cudesADI'),
					cudesUCP: _get(item, 'codice.cudesUCP'),
				}) : undefined,
			}
		},
		results: {
			...$common.crud.results,
		},
		schema,
		dataDetails,
		extra: {
			slots: {
				edit: EditErogatore,
			},
		},
		table: {
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "tipologiaErogatore.descrizione",
					text: "Tipologia",
				},
				{
					dataField: "nome",
					text: "Nome",
				},
				{
					dataField: "descrizione",
					text: "Descrizione",
				},
			],
		},
	};
};