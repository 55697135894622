import { gql } from "@ai4/data-request";

export const GET_DIMISSIONE = gql`
    query getDimissione($id: Guid!) {
        cartellaSocioSanitariaQuery {
            schedaDimissione {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    data
                    motivo {
                        uniqueId
                        descrizione
                    }
                    note
                    esito {
                        uniqueId
                        descrizione
                    }
                    stato {
                        uniqueId
                        descrizione
                    } 
                }
            }
        }
    }
`;