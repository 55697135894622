import _get from 'lodash/get';
import { useCallback, useContext } from 'react';
import { DataContext, gql, useDataRequest, useFormViewer, useMutationTransformer } from '@ai4/data-request';
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import { Formatters } from '@ai4/records-management';
import { useDataDecorator, useFormBuilderSchemaLoader, useHideNodesSchema, useSelectItemsPopulate } from "../../helpers/data";
import { filterDefault } from "../../helpers/helpers";
import Schema from '@ai4/form-viewer/dist/types/schema';

const RELATED = gql`
	query getSubContratto {
		struttureServiziQuery {
			erogatori {
				list {
					uniqueId
					nome
				}
			}
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
				}
			}
			elenchiStruttureServizi {
                tipologiaContrattoOperatore {
                    list {
                        uniqueId
                        descrizione
                    }
                }
			}
		}
	}
`;

export const useModule = (options: any) => {
	const list = `struttureServiziQuery.contratti`;
	const mutation = "struttureservizi.contratti";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
	const [execDetails, dataDetails] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const dataList = useDataDecorator('contratti', options.parent).map((r: any) => ({...r, _label: `${r.erogatore.nome}`}));
	
	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-contratto');
	schema = useHideNodesSchema(useSelectItemsPopulate(
		schema as Schema,
        related, {
            'erogatoreUniqueId': [
				..._get(related.data, 'struttureServiziQuery.erogatori.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
				{ creationURL: '/anagrafiche/erogatori' },
			],
            'operatoreUniqueId': [
				..._get(related.data, 'struttureServiziQuery.operatori.list', []).map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId })),
				{ creationURL: '/anagrafiche/operatori' },
			],
            'tipologiaContrattoOperatoreUniqueId': [
				..._get(related.data, 'struttureServiziQuery.elenchiStruttureServizi.tipologiaContrattoOperatore.list', []).map((cat: any) => ({ text: cat.descrizione, value: cat.uniqueId })),
				{ listManagement: { name: 'TipologiaContrattoOperatore', query_name: 'getSubContratto' } },
			],
        }
	), [options.update_field]);

	const save = useCallback(async (values) => {
		const { uniqueId, erogatoreUniqueId, operatoreUniqueId, tipologiaContrattoOperatoreUniqueId, ...rest } = values;
		const insertedId = await execSave({
			path: `api/{ver}/struttureservizi/${uniqueId ? `contratti/${uniqueId}` : `contratti`}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				datiContratto: {
					...rest,
					erogatoreId: erogatoreUniqueId,
					operatoreId: operatoreUniqueId,
					tipologiaContrattoId: tipologiaContrattoOperatoreUniqueId
				},
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [options.graphql.details],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, []);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `api/{ver}/struttureservizi/contratti/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [options.graphql.details],
            });
        }
	}, []);

	return {
		graphql: {
			list,
            mutation,
            loading: false,
        },
		actions: {
			details: {
				onSelectedRow: (row) => {
					console.log(row);
				}
			},
			save,
			remove,
			list: {
				onLoadList: (rows: any[]) => {
					return rows.map((i: any) => ({ ...i, _label: i.oggetto, descrizione: _get(i, 'categoriaAllegato.nome'), file: i.documento }))
				},
			},
		},
		results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		extra: {
			options: {
				parent: options.parent,
				onCreateInitialValues: {
					[options.update_field]: options.parent.uniqueId,
				},
				modalSize: 'md',
				closeWhenSaved: true,
			},
		},
		dataList,
		dataDetails,
		table: {
			columns: [
				{
					dataField: 'uniqueId',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: 'erogatore.nome',
					text: 'Erogatore',
				},
				{
					dataField: 'stato',
					text: 'Stato',
				},
				{
					dataField: 'tipologiaContrattoOperatore.descrizione',
					text: 'Tipologia',
					filter: textFilter(filterDefault()),
				},
				{
					dataField: "dataAttivazione",
					text: "Attivazione",
					formatter: Formatters.dateFormatter,
					sort: true,
				},
				{
					dataField: "dataScadenza",
					text: "Scadenza",
					formatter: Formatters.dateFormatter,
					sort: true,
				},
			],
		},
	};
}
