import _get from 'lodash/get';
import _set from 'lodash/set';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Tab, Row, Button, Alert, Col, Nav, Spinner } from 'react-bootstrap';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import RecordsManagement, { Formatters } from '@ai4/records-management';
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import Title from 'src/app/template/SharedComponents/Title';
import $schemaPianoAssistenziale from '../schema/edit/pianoAssistenziale.json';
import $schemaPrestazione from '../schema/edit/pianoAssistenzialePrestazione.json';
import { _assoc } from '../../anagrafiche/common';

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const RELATED = gql`
    query getSubPianoAssistenziale {
        struttureServiziQuery {
            prestazioni {
                list {
                    uniqueId
                    nome
                }
            }
        }
    }
`;

interface PianoAssistenzialeProps {
    record: any;
    parent: any;
}

function PianoAssistenziale(props: PianoAssistenzialeProps) {
    const { record, parent } = props;
    const { interventi = [] } = record;

    const { domain, getClient } = useContext(DataContext);
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);
    const [execSave, res] = useRestRequest({
        path: '@later',
        jwt: true,
    });

    const schema = useSelectItemsPopulate(
        $schemaPrestazione as unknown as any,
        related,
        {
            prestazioneUniqueId: [
                ..._get(related.data, 'struttureServiziQuery.prestazioni.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
            ],
        }
    );

    const intervento = useMemo(() => {
        var intervento = interventi && interventi.length > 0 ? interventi[0] : undefined;
        if (intervento) {
            intervento = _set(intervento, 'pianificazioneErogazione.numeroAccessiAutorizzati', _get(intervento, 'pianificazioneErogazione.numeroAccessi'));
        }
        return intervento || undefined;
    }, [interventi]);

    const [current, setCurrent] = useState(intervento);
    const [prestazioni, setPrestazioni] = useState(_get(intervento, 'prestazioniPianificate', []).map((row) => ({...row, $id: uuidv4()})));
    // const BASE = `api/{ver}/cartellasociosanitaria/cartelle/${parent.uniqueId}/progetti/${record.uniqueId}/piani-assistenziali/interventi`;
    const BASE = `api/{ver}/cartellasociosanitaria/interventi`;
    const savePianoAssistenziale = useCallback(async ({ values }) => {
        await execSave({
			path: `${BASE}${intervento ? `/${intervento.uniqueId}` : ''}`,
            method: intervento ? 'PUT' : 'POST',
            data: {
                dati: {
                    ..._set(current, 'prestazioniPianificate', prestazioni)
                },
                progettoUniqueId: record.uniqueId, 
            },
        });
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: ['getProgetto'],
            });
        }
    }, [intervento, current, prestazioni]);

    const args = {
        columns: [
            {
                dataField: "prestazione.descrizione",
                text: "Prestazione",
            },
            {
                dataField: "quantitaPrevista",
                text: "Quantità",
            },
        ],
		rows: prestazioni,
		tableClassName: 'wp-list-table widefat fixed striped table-view-list posts',
		onSubmit: async ({ values }: any) => {
            const { $id } = values;
            const newRecord = {
                $id: $id || uuidv4(),
                ...values,
                prestazione: _get(related.data, 'struttureServiziQuery.prestazioni.list', []).find(p => p.uniqueId === values.prestazioneUniqueId)
            }
            const res = [
                ...prestazioni.map(p => p.$id === $id ? newRecord : p),
                ...($id ? [] : [newRecord]),
            ];
            setPrestazioni(res);
            return newRecord;
		},
		onCancel: () => {
		},
		onDeleteRow: (row) => {
            setPrestazioni(prestazioni.filter(p => p.$id !== row.$id));
		},
		tableProps: {
			filtersClasses: 'filters-row',
		},
        formSchema: schema,
        options: {
            closeWhenSaved: true,
        },
        slots: {
            header: ({ onCreate }) => <div className='d-flex justify-content-start flex-row p-2 bd-highlight gap-3'>
                <Button variant="secondary" onClick={() => { onCreate(); }}>
                    Aggiungi prestazione
                </Button>
            </div>
        },
	};

    if (related.loading) return <Spinner />
    return <div>
        <div className="row">
            <div className="col-sm-6">
                <Alert variant={'primary'}>
                    <FormViewer
                        initialValues={intervento || {}}
                        schema={$schemaPianoAssistenziale as Schema}
                        onChange={({values}) => setCurrent(values)}
                        slots={{
                            ButtonBar: (args) => <></>
                        }}
                    />
                </Alert>
            </div>
        </div>
        <RecordsManagement {...args as any} />
        <Button variant="primary" onClick={savePianoAssistenziale}>
            Salva piano assistenziale
        </Button>
    </div>
}

export default PianoAssistenziale;