import React, { Component } from "react";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import FilterTable from '../../commons/filterTable';
import permissionSchemaDb from 'src/@fake/db/permissionSchema';
//import { PopupDettaglio } from './dettaglio-popup';
import { textFilter } from '@ai4/react-bootstrap-table2-filter';


class RolesList extends Component {

  state = {
    columns: [{
      dataField: 'id',
      text: 'ID',
      hidden: true,
    }, {
      dataField: 'name',
      text: 'Nome',
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<div><span>{row.name}</span>
          <br></br>
          <span className="row-subtitle">{row.description}</span></div>);
      }
    }],

    rows: permissionSchemaDb.roles,

    selectedRow: null
  }

  render() {

    let {
      columns = [],
      rows = [],
      selectedRow = null,
    } = this.state;

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
    };

    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Configurazioni", path: "#" },
            { name: "Ruoli", path: "views/config/roles/list" },
          ]}
        ></Breadcrumb>

        <FilterTable
          data={rows}
          columns={columns}
          rows={rows}
          hideToggle={true}
          onSelectedRow={(row) => {
            this.setState({ selectedRow: row })
            this.props.history.push('./detail?id=' + row.id)
          }}
        >
        </FilterTable>

      </div>
    );
  }
}

export default RolesList;

