import { gql } from "@ai4/data-request";

export const GET_VOCE_DIARIO = gql`
    query getVoceDiario($id: Guid!) {
        cartellaSocioSanitariaQuery {
            vociDiario {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    operatore {
                        uniqueId
                        datiAnagrafici {
                            nome
                            cognome
                        }
                    }
                    tipologiaVoceDiario {
                        uniqueId
                        descrizione
                    }
                    dataInserimento
                    descrizione
                }
            }
        }
    }
`;