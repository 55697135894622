import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { gql, useDataRequest } from "@ai4/data-request";
import TitleBar from "src/app/components/TitleBar";
import Edit from "src/app/components/Edit";
import { FormViewer } from '@ai4/form-viewer';
import Crud from "src/@bootstrap/components/Crud/Crud";
import { getEditFormViewer } from './common';
import Recapiti from './sub/Recapiti';
import DocumentiIdentita from './sub/DocumentiIdentita';
import Domicili from './sub/Domicili';

interface SubProps {
	parent: any;
	parent_type: string;
}

const Allegati = (props: SubProps) => {
    // FullCrud
	return <Crud
        title='Allegati'
        module='anagrafiche'
        entity='allegati'
		options={{
			...props,
            update_field: 'operatoreUniqueId',
            graphql: {
                details: 'getOperatore',
            },
            options: {
                modalSize: 'lg',
                closeWhenSaved: true,
            },
		}}
	/>;
}

const Contratti = (props: SubProps) => {
	return <Crud
        title='Contratti'
        module='anagrafiche'
        entity='contratti'
		options={{
			...props,
            update_field: 'operatoreUniqueId',
            graphql: {
                details: 'getOperatore',
            },
            options: {
                modalSize: 'lg',
				closeWhenSaved: true,
            },
		}}
	/>;
}

const QUERY = gql`
    query getSubAnagrafica {
        anagraficaQuery {
            elenchiAnagrafica {
                tipologiaRecapito {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaTesseraSanitaria {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaDocumentoIdentita {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaEnteEmittente {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaSede {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
        elenchiQuery {
            elenchiGeneraliQuery {
                provincie {
                    list {
                        uniqueId
                        siglaProvincia
                        descrizione
                    }
                }
                nazioni {
                    list {
                        uniqueId
                        denominazioneIT
                    }
                }
            }
        }
    }
`;

export function EditOperatore(props: any) {
    const {
        record,
        onAddExtraData,
        handleClose,
    } = props;
    const subProps = {
        parent: record,
        parent_type: 'operatori',
    }

    const [extraData, setExtraData] = useState({
        sedi: _get(record, 'sedi', []),
        recapiti: _get(record, 'datiRecapito.recapiti', []),
        tessereSanitarie: _get(record, 'datiSanitari.tessereSanitarie', []),
        documentiIdentita: _get(record, 'documentiIdentita', []),
        datiDomicilio: _get(record, 'datiDomicilio', []) || [],
    });
    const { useQuery } = useDataRequest();
    const query = useQuery(QUERY);
    const { data, loading } = query;

    useEffect(() => {
        onAddExtraData({
            ...extraData,
        });
    }, [extraData]);

    const formArgs = useMemo(() => {
        return getEditFormViewer(props);
    }, [props]);

    return (
        <Edit>
            <TitleBar
                path={[
                    `${record.uniqueId ? `${_get(record, 'datiAnagrafici.nome')} ${_get(record, 'datiAnagrafici.cognome')}` : "Nuovo operatore"}`,
                ]}
                onBack={handleClose}
            />
            <FormViewer
                {...formArgs}
                components={{
					recapiti: () => <Recapiti items={extraData.recapiti} related={query} onChange={(recapiti) => setExtraData({...extraData, recapiti})} />,
					documentiIdentita: () => <DocumentiIdentita items={extraData.documentiIdentita} related={query} onChange={(documentiIdentita) => setExtraData({...extraData, documentiIdentita})} />,
                    datiDomicilio: () => <Domicili single={true} items={extraData.datiDomicilio} related={query} onChange={(datiDomicilio) => setExtraData({...extraData, datiDomicilio})} />,
                    contratti: () => <>
                        {record.uniqueId && (<>
                            <Contratti {...subProps} />,
                        </>)}
                        {!record.uniqueId && (<>
                        <div className='empty'>
                            Puoi aggiungere contratti solo dopo aver creato l'elemento.
                        </div>
                        </>)}
                    </>,
                    allegati: () => <>
                        {record.uniqueId && (<>
                        <Allegati {...subProps} />
                        </>)}
                        {!record.uniqueId && (<>
                        <div className='empty'>
                            Puoi aggiungere allegati solo dopo aver creato l'elemento.
                        </div>
                        </>)}
                    </>
				}}
            />
        </Edit>
    );
}

export default EditOperatore;
