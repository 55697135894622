import React, { Component } from "react";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import FilterTable from '../../commons/filterTable';
import accountsDb from 'src/@fake/db/accounts';
import Form from "react-bootstrap/Form";
import { PopupDettaglio } from './dettaglio-popup';
import { textFilter, dateFilter } from '@ai4/react-bootstrap-table2-filter';
import UI from 'src/app/template/ui'
import { isMobile } from 'react-device-detect'


class AccountsList extends Component {

  state = {
    columns: [{
      dataField: 'id',
      text: 'ID',
      hidden: true,
    }, {
      dataField: 'avatar',
      text: '',
      align: "center",
      hidden: isMobile,
      headerStyle: { width: '50px' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<img src={UI.getAvatar(row.username, "#003473", "white")} style={{ borderRadius: '50%' }} />);
      }
    }, {
      dataField: 'username',
      text: 'Username',
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<div><span className={(row.active === false ? "row-disabled" : "")}>{row.username}</span>
          <br></br>
          <span className="row-subtitle">{row.email}</span></div>);
      }
    }, {
      dataField: 'active',
      text: 'Attivo',
      hidden: isMobile,
      sort: true,
      headerStyle: { width: '100px' },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<Form.Check
          type="switch"
          checked={row.active}
          onChange={() => { }}
          label=""
        />);
      }
    },
    {
      dataField: 'activeFrom',
      text: 'Validità',
      hidden: isMobile,
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (row.active ? row.activeFrom + " - " + row.activeTo : <span className="account-disabled">{row.activeFrom + " - " + row.activeTo}</span>);
      }
    }, {
      dataField: 'lastAccess',
      text: 'Ultimo accesso',
      hidden: isMobile,
      sort: true,
      headerStyle: { width: '225px' },
      filter: dateFilter(),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (row.active ? row.lastAccess : <span className="account-disabled">{row.lastAccess}</span>);
      }
    }],

    rows: accountsDb.accounts,

    selectedRow: null
  }

  render() {

    let {
      columns = [],
      rows = [],
      selectedRow = null,
    } = this.state;

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      hideSelectColumn: true,
    };


    const detail = selectedRow ? <PopupDettaglio account={selectedRow} handleClose={() => {
      this.setState({ selectedRow: null })
    }}></PopupDettaglio> : null;


    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Configurazioni", path: "#" },
            { name: "Accounts", path: "views/config/accounts/list" },
          ]}
        ></Breadcrumb>

        <FilterTable
          data={rows}
          columns={columns}
          rows={rows}
          onSelectedRow={(row) => {
            this.setState({ selectedRow: row })
          }}
        >
        </FilterTable>

        {detail}
      </div>
    );
  }
}

export default AccountsList;

