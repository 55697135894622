import _get from 'lodash/get';
import { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { DataContext, gql, useDataRequest, useFormViewer, useMutationTransformer } from '@ai4/data-request';
import { SchemaTypes } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { Formatters } from '@ai4/records-management';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import { hasPermission, selectCurrentUser, selectPermissions } from 'src/@bootstrap/features/auth';
import { useSelectItemsPopulate } from "../../helpers/data";
import { GET_PROGETTO_PAI } from './gql/progettoPai';
import Edit from './edit/progetto';
import $filters from './schema/filters/progetti.json';
import { _assoc, _revid } from '../anagrafiche/common';
import { ConfigContext } from 'src/@bootstrap/Config';

const filterSchema = $filters as SchemaTypes.Schema;

const RELATED = gql`
	query getRelatedProgetto {
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaProgetto {
					list {
						uniqueId
						descrizione
					}
				}
				indiceComplessita {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			aree {
				list {
					uniqueId
					nome
				}
			}
			servizi {
				list {
					uniqueId
          			nome
					percorsi {
						uniqueId
          				nome
					}
				}
			}
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
                    utenteCollegato {
                        email
                        userName
                    }
				}
			}
		}
	}
`;


function getUploadUrl( name: string ) {
	let uri = '';
	if (name === 'documento') uri = `api/{ver}/allegati`;
	return uri;
}

const useOperatoriDisponibili = (related) => {
    const user = useSelector(selectCurrentUser);
    const permissions = useSelector(selectPermissions);
    const $operatori = _get(related.data, 'struttureServiziQuery.operatori.list', []);
    if (hasPermission('GestioneProgettoGlobale', permissions)) return $operatori;
    return $operatori.filter(o => _get(o, 'utenteCollegato.email') === user.email);
}

export const useModule = (options: any) => {
	const list = `cartellaSocioSanitariaQuery.progettiPai`;
	const mutation = "cartelle.progettiPai";
    const { getEnv } = useContext(ConfigContext);
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
	const operatori = useOperatoriDisponibili(related);
	const query = useLazyQuery(GET_PROGETTO_PAI);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const { onSelectFiles, onDownloadFile, onRemoveFile } = useFormViewer({
        file: {
            endpoint: getUploadUrl,
        },
    });

	var schema = getEnv('PAI_SCHEMA') === 'pai' ? require('./schema/edit/pai.json') : require('./schema/edit/progetto.json');
	schema = useSelectItemsPopulate(
		schema as Schema,
        related, {
            tipologiaProgettoUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.tipologiaProgetto.list', []).map(_assoc),
            ],
            "modalitaCura.servizioUniqueId": [
                ..._get(related.data, 'struttureServiziQuery.servizi.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
            ],
            "modalitaCura.indiceComplessitaUniqueId": [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.indiceComplessita.list', []).map(_assoc),
            ],
            responsabileProgettoUniqueId: [
                ...operatori.map((cat: any) => ({ text: `${_get(cat, 'datiAnagrafici.nome')} ${_get(cat, 'datiAnagrafici.cognome')}`, value: cat.uniqueId })),
            ],
            altriOperatoriIds: [
                ..._get(related.data, 'struttureServiziQuery.operatori.list', []).map((cat: any) => ({ text: `${_get(cat, 'datiAnagrafici.nome')} ${_get(cat, 'datiAnagrafici.cognome')}`, value: cat.uniqueId })),
            ],
			areaUniqueId: [
                ..._get(related.data, 'struttureServiziQuery.aree.list', []).map(_assoc),
            ],
        }
	);

	const BASE = `api/{ver}/cartellasociosanitaria/progetti/pai`;
	const save = useCallback(async (values) => {
		const { uniqueId, areaUniqueId, interventi = [] } = values;
		const progettoUniqueId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: {
					modalitaCura: {},
					...values,
					altriOperatoriIds: (values.altriOperatoriIds || []),
					datiProtocollo: values.protocollo || {},
				},
				cartellaUniqueId: options.parent.uniqueId
			},
		});
		// automatically create "intervento" + "intervento => affidamento"
		if (!uniqueId) {
			const interventoUniqueId = await execSave({
				path: `api/{ver}/cartellasociosanitaria/interventi`,
				method: 'POST',
				data: {
					dati: {
						areaUniqueId,
					},
					progettoUniqueId,
				},
			});
			const affidamentoUniqueId = await execSave({
				path: `api/{ver}/cartellasociosanitaria/affidamenti`,
				method: 'POST',
				data: {
					dati: {},
					interventoUniqueId,
				},
			});
		} else {
			await interventi.reduce(async (acc, intervento) => {
				let q = await acc;
				await execSave({
					path: `api/{ver}/cartellasociosanitaria/interventi/${intervento.uniqueId}`,
					method: 'PUT',
					data: {
						dati: {
							areaUniqueId,
						},
					},
				});
				return q;
			}, Promise.resolve({}));
			
		}
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list), 'getCartella'],
            });
        }
		return {
			uniqueId: uniqueId || progettoUniqueId,
			...values,
		}
	}, [BASE, options.parent]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list), 'getCartella'],
            });
        }
	}, [BASE]);

	const onChange = (args: any) => {
		const {
			values,
			schema,
			getSchemaNode,
			setSchemaNode,
		} = args;

		const servizioUniqueId = _get(values, `modalitaCura.servizioUniqueId`);
		if (servizioUniqueId && related.data) {
			const items = _get(_get(related.data, 'struttureServiziQuery.servizi.list', []).find(s => s.uniqueId === servizioUniqueId), 'percorsi', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId }));
			const name = `modalitaCura.percorsoUniqueId`;
			const node = getSchemaNode(name);
			if (node) {
				setSchemaNode(name, {
					...node,
					props: {
						...(node.props || {}),
						items,
					},
				});
			}
		}
	};

	return {
		graphql: {
			list,
            mutation,
            loading,
			details: execDetails,
			listFilters: () => {
                return `pagedList (
                    cartellaUniqueId: "${options.parent.uniqueId}"
					pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                )`;
            },
        },
		actions: {
			save,
			remove,
			upload: {
				onSelectFiles,
				onDownloadFile,
				onRemoveFile,
			},
			details: {
				onLoadDetails: (item) => item ? ({
					...item,
					areaUniqueId: _get(item, 'interventi[0].area.uniqueId'),
					responsabileProgettoUniqueId: _get(item, 'responsabileProgetto.operatore.uniqueId'),
					altriOperatoriIds: _get(item, 'altriOperatori', []).map(i => _get(i, 'operatore.uniqueId')),
				}) : undefined,
			},
		},
		results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		extra: {
			slots: {
				edit: Edit,
			},
			options: {
				parent: options.parent,
			},
		},
		methods: {
		  onFormInit: onChange,
		  onFormChange: onChange,
		},
		dataDetails,
		filters: {
			schema: filterSchema,
		},
		table: {
			columns: [
				{
					dataField: 'uniqueId',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: "dataInizio",
					text: `Data inizio ${getEnv('PAI_LABEL_SINGLE')}`,
					formatter: Formatters.dateFormatter,
				},
				{
					dataField: "dataScadenza",
					text: `Data scadenza ${getEnv('PAI_LABEL_SINGLE')}`,
					formatter: Formatters.dateFormatter,
				},
				{
					dataField: "codice",
					text: getEnv('PAI_LABEL_SINGLE'),
				},
				/*{
					dataField: "modalitaCura.indiceComplessita.descrizione",
					text: "Modalità di cura",
					dataNode: `
					modalitaCura {
						indiceComplessita {
							descrizione
						}
					}
					`,
				},*/
			],
		},
	};
}
