import { gql } from "@ai4/data-request";

export const GET_STRUTTURA_SANITARIA = gql`
    query getStrutturaSanitaria($id: Guid!) {
        struttureServiziQuery {
            struttureSanitarie {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    nome
                    descrizione
                    note
                    datiTerritoriali {
                        enteTerritorialePrimoLivello {
                            uniqueId
                        }
                        enteTerritorialeSecondoLivello {
                            uniqueId
                        }
                        ambitoTerritoriale {
                            uniqueId
                        }
                    }
                }
            }
        }
    }
`;