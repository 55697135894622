import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";
import { RechartCreator } from "src/@bootstrap/template/@gull";

const data = [
  {
    name: "1",
    Mi: 2400,
    amt: 2400
  },
  {
    name: "2",
    Mi: 1398,
    amt: 2210
  },
  {
    name: "3",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "4",
    Mi: 3908,
    amt: 2000
  },
  {
    name: "5",
    Mi: 4800,
    amt: 2181
  },
  {
    name: "6",
    Mi: 3800,
    amt: 2500
  },
  {
    name: "7",
    Mi: 4300,
    amt: 2100
  },
  {
    name: "8",
    Mi: 1398,
    amt: 2210
  },
  {
    name: "9",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "10",
    Mi: 3908,
    amt: 2000
  },
  {
    name: "11",
    Mi: 4800,
    amt: 2181
  },
  {
    name: "12",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "13",
    Mi: 2400,
    amt: 2400
  },
  {
    name: "14",
    Mi: 1398,
    amt: 2210
  },
  {
    name: "15",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "16",
    Mi: 3908,
    amt: 2000
  },
  {
    name: "17",
    Mi: 4800,
    amt: 2181
  },
  {
    name: "18",
    Mi: 3800,
    amt: 2500
  },
  {
    name: "19",
    Mi: 4300,
    amt: 2100
  },
  {
    name: "20",
    Mi: 1398,
    amt: 2210
  },
  {
    name: "21",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "22",
    Mi: 3908,
    amt: 2000
  },
  {
    name: "23",
    Mi: 4800,
    amt: 2181
  },
  {
    name: "24",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "25",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "26",
    Mi: 1398,
    amt: 2210
  },
  {
    name: "27",
    Mi: 9800,
    amt: 2290
  },
  {
    name: "28",
    Mi: 3908,
    amt: 2000
  },
  {
    name: "29",
    Mi: 4800,
    amt: 2181
  },
  {
    name: "30",
    Mi: 4800,
    amt: 2181
  }
];

const BandwidthLineChart = ({ height, width }) => {
  return (
    <RechartCreator height={height} width={width}>
      <LineChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0
        }}
      >
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        {/* <Legend /> */}
        <Line
          type="monotone"
          dataKey="Mi"
          stroke="#8884d8"
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </RechartCreator>
  );
};

export default BandwidthLineChart;
