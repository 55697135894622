import _get from 'lodash/get';
import { useCallback, useContext } from 'react';
import { DataContext, gql, useDataRequest, useFormViewer, useMutationTransformer } from '@ai4/data-request';
import { SchemaTypes } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { Formatters } from '@ai4/records-management';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import { useDataDecorator, useFormBuilderSchemaLoader, useSelectItemsPopulate } from "../../helpers/data";
import { GET_VOCE_DIARIO } from './gql/voceDiario';
import Edit from './edit/progetto';
import { _assoc, _revid } from '../anagrafiche/common';

const RELATED = gql`
	query getRelatedVoceDiario {
		struttureServiziQuery {
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
				}
			}
		}
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaVoceDiDiario {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

export const useModule = (options: any) => {
	const list = `cartellaSocioSanitariaQuery.vociDiario`;
	const mutation = "cartelle.vociDiario";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
	const query = useLazyQuery(GET_VOCE_DIARIO);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	// var schema = useFormBuilderSchemaLoader('presets.anagrafiche-allegato');
	var schema = require('./schema/edit/voceDiario.json');
	schema = useSelectItemsPopulate(
		schema as Schema,
        related, {
            operatoreUniqueId: [
                ..._get(related.data, 'struttureServiziQuery.operatori.list', []).map((cat: any) => ({ text: `${_get(cat, 'datiAnagrafici.nome')} ${_get(cat, 'datiAnagrafici.cognome')}`, value: cat.uniqueId })),
            ],
            tipologiaVoceDiarioUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.tipologiaVoceDiDiario.list', []).map(_assoc),
            ],
        }
	);

	const BASE = `api/{ver}/cartellasociosanitaria/cartelle/${options.parent.uniqueId}/diario/voci`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: {
					...values,
				},
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, [BASE]);

	return {
		graphql: {
			list,
            mutation,
            loading,
			details: execDetails,
			listFilters: () => {
                return `pagedList (
                    ${_revid(options.parent_type)}: "${options.parent.uniqueId}"
					pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                )`;
            },
        },
		actions: {
			save,
			remove,
		},
		results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		extra: {
			slots: {
				// edit: Edit,
			},
			options: {
				parent: options.parent,
				closeWhenSaved: true,
			},
		},
		dataDetails,
		table: {
			columns: [
				{
					dataField: 'uniqueId',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: "dataInserimento",
					text: "Data evento",
					formatter: Formatters.dateFormatter,
				},
				{
					dataField: "tipologiaVoceDiario.descrizione",
					text: "Tipologia evento",
				},
				{
					dataField: "descrizione",
					text: "Descrizione",
				},
				{
					dataField: "operatore",
					text: "Operatore",
					formatter: (cell, row) => `${_get(row, 'operatore.datiAnagrafici.nome')} ${_get(row, 'operatore.datiAnagrafici.cognome')}`,
					dataNode: `
					operatore {
						datiAnagrafici {
							nome
							cognome
						}
						qualificaOperatore {
						  descrizione
						}
					}
					`
				},
				{
					dataField: "tipologiaOperatore",
					text: "Tipo Oper.",
					formatter: (cell, row) => `${_get(row, 'operatore.qualificaOperatore.descrizione', '')}`,
					noData: true,
				},
			],
		},
	};
}
