const getSchema = () => (

    /*** JSON */

    {
        "defaultActiveTabId": "Titolo",
        "id": "root",
        "type": "tabs",
        "tabs": [
            {
                "id": "Titolo",
                "title": "Titolo",
                "rows": [
                    {
                        "id": "columns.cvwwi2mbd",
                        "type": "columns",
                        "defaultSizes": [
                            12
                        ],
                        "columns": [
                            {
                                "id": "column.9lhphgahf",
                                "name": "affidamentoUniqueId",
                                "type": "field",
                                "fieldType": "select",
                                "label": "Progetto PAI",
                                "props": {},
                                "validation": [
                                  {
                                    "rule": "required"
                                  }
                                ]
                            },
                            {
                                "id": "column.xjcx7wxbe"
                            }
                        ]
                    },
                    {
                        "id": "columns.evdw04uxn",
                        "type": "columns",
                        "defaultSizes": [
                            6,
                            6
                        ],
                        "columns": [
                            {
                                "id": "column.78lewb2bf",
                                "name": "dataInizio",
                                "type": "field",
                                "fieldType": "date",
                                "label": "Inizio",
                                "props": {},
                                "validation": [
                                  {
                                    "rule": "required"
                                  }
                                ]
                            },
                            {
                                "id": "column.294585suz",
                                "name": "dataFine",
                                "type": "field",
                                "fieldType": "date",
                                "label": "Fine",
                                "props": {},
                                "validation": [
                                  {
                                    "rule": "required"
                                  }
                                ]
                            }
                        ]
                    },
                    {
                        "id": "columns.e9mgayhcv",
                        "type": "columns",
                        "defaultSizes": [
                            12
                        ],
                        "columns": [
                            {
                                "id": "column.fswqtfpsw",
                                "name": "motivoSospensioneUniqueId",
                                "type": "field",
                                "fieldType": "select",
                                "label": "Motivo sospensione",
                                "props": {},
                                "validation": [
                                  {
                                    "rule": "required"
                                  }
                                ]
                            },
                            {
                                "id": "column.9oxqijw1a"
                            }
                        ]
                    },
                    {
                        "id": "columns.e628zh1wp",
                        "type": "columns",
                        "defaultSizes": [
                            12,
                            6
                        ],
                        "columns": [
                            {
                                "id": "column.yrgmj6vow",
                                "name": "note",
                                "type": "field",
                                "fieldType": "textarea",
                                "label": "Note",
                                "props": {}
                            }
                        ]
                    }
                ]
            }
        ]
    }

    /*** JSON */

)

export default getSchema;