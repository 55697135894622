import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'


export function PopupDettaglio(props) {

  const [show, setShow] = useState(props.account !== null && props.account !== 'undefined');

  const handleClose = () => {
    setShow(false);
  };

  const handleClosed = () => {
    props.handleClose()
  };

  const account = props.account;



  return (
    <div>

      <Modal show={show} onHide={handleClose} onExited={handleClosed} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>{account.username}</Modal.Title>
        </Modal.Header>

        <Modal.Body>

        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Chiudi senza salvare</Button>
          <Button variant="primary" onClick={handleClose}>Salva</Button>
        </Modal.Footer>

      </Modal>

    </div>
  );

}

export default PopupDettaglio;

