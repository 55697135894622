import { Formatters } from "@ai4/records-management";
import React, { Component } from "react";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { isMobile } from 'react-device-detect'
import TableWidget from "./TableWidget";

interface Props {
  data?: {
    codice: string;
    codiceFiscale: string;
    data: number;
    struttura: string;
    uniqueId: string;
  }[]
}

export function NuoveCartelle(props: Props) {
  const { data } = props;
  const state = {
    dataTablePage: 0,
    dataTablePerPage: 6,
  };

  let { dataTablePage, dataTablePerPage } = state;

  let dotStyle= { marginLeft: '4px', backgroundColor: 'black !important'};

return (  
  <div className="card h-100">
    <div className="card-body">
      <div className="ul-widget__head border-0 mb-2">
        <div className="ul-widget__head-label">
          <h3 className="ul-widget__head-title">Nuove cartelle</h3>
          <p>Inserite negli ultimi 7 giorni.</p>
        </div>
      </div>
      {!data && <Spinner />}
      {data && data.length === 0 && <div className="empty">Nessuna nuova cartella</div>}
      {data && data.length > 0 && <>
      <TableWidget
          data={data}
          ipp={dataTablePerPage}
          head={() => <thead>
            <tr className="ul-widget6__tr--sticky-th">
              <th scope="col">Cod. Cartella</th>
              <th scope="col">Data</th>
              {!isMobile && <>
              <th scope="col">Cod. Fiscale</th>
              <th scope="col">Struttura</th>
              </>}
              <th scope="col"></th>
            </tr>
          </thead>}
          row={(item, i) => <tr key={i}>
            <td>
              <span>
                {item.codice}
              </span>
            </td>
            <td>{Formatters.dateFormatter(item.data, item, i, null)}</td>
            {!isMobile && <>
            <td>
              {item.codiceFiscale}
            </td>
            <td>
              {item.struttura}
            </td>
            </>}
            <td>
              <a href={`/cartelle/dettaglio/${item.uniqueId}`}>
                  <i className="bi bi-arrow-right-circle" />
              </a>
            </td>
          </tr>}
        />
        </>}
      </div>
    </div>
  );
}

export default NuoveCartelle;
