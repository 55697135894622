import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Modal, Stack, Table } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import { useDataDecorator, useFormBuilderSchemaLoader, useSelectItemsPopulate } from 'src/app/helpers/data';
import { ButtonBar } from './Recapiti';
import { useAnagraficaModule, useAnagraficaSelectItemsPopulate, useChangeComuniByProvincia, _assoc } from '../common';
import Schema from '@ai4/form-viewer/dist/types/schema';

interface Props {
	items: any[];
	related: any;
    onChange: (values: any) => void;
}

const TessereSanitarie = (props: Props) => {
    const { items, related, onChange } = props;
	const [record, setRecord] = useState<any>();
	const $common = useAnagraficaModule({
		module: 'anagrafica',
		entity: 'tessereSanitarie',
		graphql: { list: null, details: null },
		lists: ['tipologiaTesseraSanitaria']
	});

	const handleClose = () => setRecord(undefined);

	const tipologie = _get(related.data, 'anagraficaQuery.elenchiAnagrafica.tipologiaTesseraSanitaria.list', []);
	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-tesseraSanitaria');
	schema = useAnagraficaSelectItemsPopulate({ schema, related: $common.related });
	schema = useSelectItemsPopulate(
		schema as Schema,
		related, {
			tipologiaTesseraSanitariaUniqueId: [
				...tipologie.map((cat: any) => ({ text: cat.descrizione, value: cat.uniqueId })),
				{ listManagement: { name: 'TipologiaTesseraSanitaria', query_name: 'getSubAnagrafica' } },
			],
			'datiAnagrafici.statoCivileUniqueId': _get(related.data, "anagraficaQuery.elenchiAnagrafica.statoCivile.list", []).map(_assoc),
		}
	);
	const { onChangeProvincia } = useChangeComuniByProvincia();

	const onRemove = useCallback(async (index) => {
        onChange(items.filter((item, i) => i !== index));
	}, [items]);
	
	const onFormChange = (args: any) => {
		onChangeProvincia({ ns: 'datiNascita', ...args });
	};

	if (related.loading) return null;
	return <Fragment>
		<Card className='my-3'>
      		<Card.Body>
			  	<Stack direction="horizontal" gap={3}>
        			<Card.Title>Tessere sanitarie</Card.Title>
					<Button variant='success' className='ms-auto' onClick={() => setRecord({})}>Aggiungi</Button>
				</Stack>
				<Card.Text>
					{items.length === 0 && <div className='empty'>Nessun risultato</div>}
					{items.length > 0 && <Table striped bordered hover>
						<thead>
							<tr>
								<th>Tipologia</th>
								<th>Scadenza</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{items.map((row, i) => {
							const tipo = tipologie.find(t => t.uniqueId === row.tipologiaTesseraSanitariaUniqueId);
							return <tr key={i} style={{ cursor: 'pointer' }} onClick={() => setRecord({ index: i, ...row })}>
								<td>{tipo ? tipo.descrizione : ''}</td>
								<td>{row.dataScadenza}</td>
								<td>
									<Button onClick={(e) => {
										e.stopPropagation();
										if (window.confirm("Vuoi continuare?")) {
											onRemove(i);
										}
									}}>Elimina</Button>
								</td>
							</tr>;
						})}
						</tbody>
					</Table>}
				</Card.Text>
			</Card.Body>
   		</Card>
		<Modal show={!!record} onHide={handleClose}>
			<Modal.Body>
				<FormViewer
					schema={schema as any}
					initialValues={record || {}}
					onInit={(form: any) => {
						if (onFormChange) {
							onFormChange({
								...form,
							});
						}
					}}
					onChange={(form: any) => {
						if (onFormChange) {
							onFormChange({
								...form,
							});
						}
					}}
					onSubmit={(args: any) => {
						const { values, form } = args;
						const newValues = values.index === undefined ? [...items, values] : items.map((item, i) => i === values.index ? values : item);
            			onChange(newValues);
						handleClose();
					}}
					slots={{
						ButtonBar: (args) => <ButtonBar args={args} onCancel={handleClose} />
					}}
				/>
			</Modal.Body>
		</Modal>
	</Fragment>
}

export default TessereSanitarie;