import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Dropdown, DropdownButton, Alert, Button, ButtonGroup } from 'react-bootstrap';
import { isMobile } from 'react-device-detect'
import { textFilter, dateFilter, selectFilter } from "@ai4/react-bootstrap-table2-filter";
import RecordsManagement, { Formatters } from '@ai4/records-management';
import { filterDefault, formatDate, strContains } from 'src/app/helpers/helpers';

interface Props {
    rows: any[];
    onChangeDate?: (date: string) => void;
    onEdit: (row: any) => void;
    hideNavigation?: boolean;
}

const AccessiGiorno = (props: Props) => {
    const { rows, hideNavigation, onChangeDate, onEdit } = props;
    const [date, setDate] = useState<Date>(new Date());

    useEffect(() => {
        if (onChangeDate)
            onChangeDate(date.toISOString().split('T')[0]);
    }, [date]);
    
    const { prev, next } = useMemo(() => {
        const prev = new Date(date);
        prev.setDate(prev.getDate() - 1);
        const next = new Date(date);
        next.setDate(next.getDate() + 1);
        return { prev, next };
    }, [date]);

    if (!rows) return null;

    const args = {
        columns: [
            {
                dataField: "stato",
                text: "Stato",
                formatter: (cell, row, i, args) => {
                    return <>
                        <span className={`accesso-stato ${cell.toLowerCase()}`} />
                        {cell}
                    </>
                }
            },
            {
                dataField: "assistito",
                text: "Assistito",
                formatter: (cell, row, i, args) => {
                    const nascita = _get(row, 'assistito.datiNascita.data');
                    return <>
                        <div>{_get(row, 'assistito.datiAnagrafici.nome')} {_get(row, 'assistito.datiAnagrafici.cognome')}</div>
                        {nascita && <div>{formatDate(nascita, 'date')}</div>}
                    </>
                },
                // filter: textFilter(filterDefault()),
            },
            {
                dataField: "orario",
                text: "Orario",
                formatter: (cell: any, row: any) => `${formatDate(row.dataOreInizioPianificata, 'time')} - ${formatDate(row.dataOreFinePianificata, 'time')}`,
                // filter: textFilter(filterDefault()),
            },
            {
                dataField: "operatorePianificato.qualificaOperatore.descrizione",
                text: "Fig. professionale",
                // filter: textFilter(filterDefault()),
            },
        ],
		rows: rows.map((i) => ({ ...i, id: i.uniqueId })),
		tableClassName: 'wp-list-table widefat fixed striped table-view-list posts',
		tableProps: {
			filtersClasses: 'filters-row',
		},
        checkFeature: (feature, row) => {
            if (feature === 'delete') return false;
            return true;
        },
        onSelectedRow: (row) => {
            onEdit(row);
        },
        hideEditColumns: true,
		clickRowToSelect: true,
        slots: {
            header: (args) => <>
            </>
        }
	};
	return <div>
        {!hideNavigation && <>
            <header>
                <ButtonGroup>
                    <Button variant="secondary" onClick={() => setDate(prev)}>
                        <i className="bi bi-chevron-left" />
                    </Button>
                    <Button variant="secondary">
                        {formatDate(date.toISOString(), 'date')}
                    </Button>
                    <Button variant="secondary" onClick={() => setDate(next)}>
                        <i className="bi bi-chevron-right" />
                    </Button>
                </ButtonGroup>
            </header>
        </>}
        <RecordsManagement {...args as any} />
    </div>
}

export default AccessiGiorno;