import { gql } from "@ai4/data-request";

export const GET_ACCESSO_DIRETTO = gql`
    query getAccessoDiretto($id: Guid!) {
        cartellaSocioSanitariaQuery {
            accessiDiretti {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    dataOreInizioErogazione
                    dataOreFineErogazione
                    affidamento {
                        uniqueId
                    }
                    operatoreErogazione {
                        uniqueId
                    }
                    tipologiaAccessoDiretto {
                        uniqueId
                    }
                    stato
                    prestazioniErogate {
                        prestazione {
                            uniqueId
                        }
                    }
                }
            }
        }
    }
`;