import { gql } from "@ai4/data-request";

export const GET_VALUTAZIONE = gql`
    query getValutazione($id: Guid!) {
        cartellaSocioSanitariaQuery {
            valutazioni {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    operatore {
                        uniqueId
                    }
                    progetto {
                        uniqueId
                    }
                    tipologia
                    data
                    formData
                    formVersion
                }
            }
        }
    }
`;