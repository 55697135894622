import _get from 'lodash/get';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Stack } from 'react-bootstrap';
import { SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import { gql, useAuthRequest, useDataRequest, useFormViewer } from '@ai4/data-request';
import { textFilter, dateFilter } from '@ai4/react-bootstrap-table2-filter';
import Collega from './collegaOperatore';
import FilterTable from '../../commons/filterTable';

export const GET_OPERATORI = gql`
    query getOperatori {
		struttureServiziQuery {
			operatori {
				list {
					uniqueId
					metadata {
						insertDate
						updateDate
					}
					datiAnagrafici {
						nome
						cognome
					}
					qualificaOperatore {
						uniqueId
						descrizione
					}
					utenteCollegato {
						email
						userName
					}
				}
			}
		}
	}
`;

const AccountOperatori = () => {
	const [selected, setSelected] = useState<any>();
	const { useQuery, useRestRequest } = useDataRequest();
	const query = useQuery(GET_OPERATORI);
	const { data, loading, refetch } = query;
	const dataList = _get(data, 'struttureServiziQuery.operatori.list', []).filter(i => !!i.utenteCollegato);

	const [execDelete, resDelete] = useRestRequest({
		path: 'api/{ver}/struttureservizi/operatori/{id}/rimuovi-utente',
		method: 'DELETE',
		jwt: true,
	});

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		const ret = await execDelete({
			path: `api/{ver}/struttureservizi/operatori/${uniqueId}/rimuovi-utente`,
		});
		refetch();
	}, []);

	const rows = dataList;

	const columns = [
		{
			dataField: 'id',
			text: 'ID',
			hidden: true,
		},
		{
			dataField: "datiAnagrafici.nome",
			text: "Nome",
		},
		{
			dataField: "datiAnagrafici.cognome",
			text: "Cognome",
		},
		{
			dataField: 'qualificaOperatore.descrizione',
			text: 'Qualifica operatore',
		},
		{
			dataField: 'utenteCollegato.userName',
			text: 'Utente collegato',
		},
	];
	
	return (
		<div>
			<Stack direction="horizontal" gap={3}>
				<h2>Account operatori</h2>
				<Button variant='success' onClick={() => { setSelected({}); }}>Nuovo account operatore</Button>
			</Stack>

			<FilterTable
				data={rows}
				columns={columns}
				rows={rows}
				onSelectedRow={(row) => {
					setSelected(row);
				}}
				onDeleteRow={(row) => {
					remove(row);
				}}
			>
			</FilterTable>

			{selected && <Collega
				record={selected}
				onSuccess={() => { refetch(); }}
				handleClose={() => setSelected(undefined)}
			/>}
		</div>
	);
}

export default AccountOperatori;