import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { gql, useDataRequest } from "@ai4/data-request";
import TitleBar from "src/app/components/TitleBar";
import Edit from "src/app/components/Edit";
import { FormViewer } from '@ai4/form-viewer';
import { getEditFormViewer } from '../../anagrafiche/common';
import Recapiti from '../../anagrafiche/sub/Recapiti';
import Sedi from '../../anagrafiche/sub/Sedi';

const QUERY = gql`
    query getSubAnagrafica {
        anagraficaQuery {
            elenchiAnagrafica {
                tipologiaRecapito {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaSede {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
        elenchiQuery {
            elenchiGeneraliQuery {
                provincie {
                    list {
                        uniqueId
                        siglaProvincia
                        descrizione
                    }
                }
                nazioni {
                    list {
                        uniqueId
                        denominazioneIT
                    }
                }
            }
        }
    }
`;

export function EditErogatore(props: any) {
    const {
        record,
        onAddExtraData,
        handleClose,
    } = props;

    const [extraData, setExtraData] = useState({
        sedi: _get(record, 'sedi', []),
        recapiti: _get(record, 'recapiti', []),
    });
    const { useQuery } = useDataRequest();
    const query = useQuery(QUERY);
    const { data, loading } = query;

    useEffect(() => {
        onAddExtraData({
            ...extraData,
        });
    }, [extraData]);

    const formArgs = useMemo(() => {
        return getEditFormViewer(props);
    }, [props]);

    return (
        <Edit>
            <TitleBar
                path={[
                    `${record.uniqueId ? record.nome : "Nuovo erogatore"}`,
                ]}
                onBack={handleClose}
            />
            <FormViewer
                {...formArgs}
                components={{
                    sedi: () => <Sedi items={extraData.sedi} related={query} onChange={(sedi) => setExtraData({...extraData, sedi})} />,
					recapiti: () => <Recapiti items={extraData.recapiti} related={query} onChange={(recapiti) => setExtraData({...extraData, recapiti})} />,
				}}
            />
        </Edit>
    );
}

export default EditErogatore;
