import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Tabs, Tab, Button, Spinner } from 'react-bootstrap';
import { Helpers } from '@ai4/records-management';
import { useDataRequest } from '@ai4/data-request';
import Crud from "src/@bootstrap/components/Crud/Crud";
import AuthFlow from './AuthFlow';

const SGDT = () => {
    const [busy, setBusy] = useState(false);
    const [auth, setAuth] = useState(false);

	const { useRestRequest } = useDataRequest();
    const [execCheckToken] = useRestRequest({
        method: 'GET',
        path: 'api/{ver}/sgdt/auth/token',
		jwt: true,
	});

    const checkToken = useCallback(async () => {
        setBusy(true);
        try {
            const res: any = await execCheckToken();
            if (res && res.result === true) {
                setAuth(true);
            }
        } catch(e) {

        }
        setBusy(false);
    }, []);

    useEffect(() => {
        checkToken();
    }, []);
    
    if (busy) return <Spinner />
    if (!auth) return <AuthFlow onSuccess={() => setAuth(true)} />
    
    return <Crud module='cartelle' entity='sgdt' path='cartelle/sgdt/sgdt' />;
}


export default SGDT;