import React from "react";
import { Link } from "react-router-dom";

interface Props {
    title: string;
    onBack: () => void;
}

function Title(props: Props) {
    const { title, onBack } = props;

    return (
        <div className="d-flex flex-row align-items-center mb-4">
            <button type="button" className="btn btn-link" onClick={onBack}>
                <i className="bi bi-backspace-fill" style={{ fontSize: '2rem' }} />
            </button>
            <h2>{title}</h2>  
        </div>
    );
}

export default Title;
