import React, { useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
import productsDb from 'src/@fake/db/products';


export function AddPermissionPopup(props) {

  const [show, setShow] = useState(props.show);

  const handleClose = () => {
    setShow(false);
  };

  const handleClosed = () => {
    props.onCloseAddPopup()
  };




  return (
    <div>

      <Modal show={show} onHide={handleClose} onExited={handleClosed} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Aggiungi permesso</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h5>Seleziona il ruolo da aggiungere</h5>
          <ListGroup>
            {productsDb.products[0].permissionSchema.roles.map(role => {
              return (<ListGroup.Item action onClick={handleClose}>{role.name}</ListGroup.Item>)
            })}
          </ListGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Annulla</Button>
        </Modal.Footer>

      </Modal>

    </div>
  );

}

export default AddPermissionPopup;

