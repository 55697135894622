import _get from 'lodash/get';
import _set from 'lodash/set';
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { pathToSlug } from 'src/app/helpers/helpers';
import { _assoc } from '../anagrafiche/common';
import { GET_STRUTTURA_SANITARIA } from './gql/strutturaSanitaria';
import { useCallback, useContext } from 'react';
import { useSelectItemsPopulate } from 'src/app/helpers/data';

const RELATED = gql`
    query getRelatedStruttureSanitarie {
		anagraficaQuery {
			elenchiAnagrafica {
				enteTerritorialePrimoLivello {
					list {
						uniqueId
						descrizione
					}
				}
				enteTerritorialeSecondoLivello {
					list {
						uniqueId
						descrizione
					}
				}
				ambitoTerritoriale {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

export const useModule = () => {
	const list = 'struttureServiziQuery.struttureSanitarie';
	const mutation = "struttureServizi.struttureSanitarie";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);
	const query = useLazyQuery(GET_STRUTTURA_SANITARIA);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	// build schema	
	var schema = require('./schema/edit/strutturaSanitaria.json');
    schema = useSelectItemsPopulate(schema, related, {
		'datiTerritoriali.enteTerritorialePrimoLivelloUniqueId': [
			..._get(related.data, "anagraficaQuery.elenchiAnagrafica.enteTerritorialePrimoLivello.list", []).map(_assoc),
			{ listManagement: { name: 'EnteTerritorialePrimoLivello', query_name: 'getRelatedAnagrafica' } },
		],
		'datiTerritoriali.enteTerritorialeSecondoLivelloUniqueId': [
			..._get(related.data, "anagraficaQuery.elenchiAnagrafica.enteTerritorialeSecondoLivello.list", []).map(_assoc),
			{ listManagement: { name: 'EnteTerritorialeSecondoLivello', query_name: 'getRelatedAnagrafica' } },
		],
		'datiTerritoriali.ambitoTerritorialeUniqueId': [
			..._get(related.data, "anagraficaQuery.elenchiAnagrafica.ambitoTerritoriale.list", []).map(_assoc),
			{ listManagement: { name: 'AmbitoTerritoriale', query_name: 'getRelatedAnagrafica' } },
		],
	});

	const BASE = `api/{ver}/struttureservizi/struttura-sanitaria`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				...values,
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, [BASE]);
	
	return {
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
		},
		actions: {
			save,
			remove,
		},
		results: {
            save: resSave,
            remove: resDelete,
		},
		schema,
		dataDetails,
		table: {
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "nome",
					text: "Nome",
				},
			],
		},
	};
};