import React, { Component } from "react";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import permissionSchemaDb from 'src/@fake/db/permissionSchema';
import productsDb from 'src/@fake/db/products';
import Accordion from 'react-bootstrap/Accordion'
import PermissionsGroups from '../permissions/groups'
import { isMobile } from 'react-device-detect'
import UI from 'src/app/template/ui'

class RolesDetail extends Component {


  render() {

    const selectedProduct = productsDb.products[0];

    const roleId = UI.getUrlParam('id');

    const getRole = (id) => {
      for (let i = 0; i < permissionSchemaDb.roles.length; i++) {
        if (permissionSchemaDb.roles[i].id === id) {
          return permissionSchemaDb.roles[i];
        }
      }
      return null;
    }

    const accordionClassName = isMobile ? "accordion-lg" : null;

    let selectedRole = getRole(roleId);
    if (!selectedRole) {
      selectedRole = permissionSchemaDb.roles[0];
    }

    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Configurazioni", path: "#" },
            { name: "Ruoli", path: "config/roles/list" },
            { name: selectedRole.name },
          ]}
        ></Breadcrumb>

        <div className="row">
          <div className="form-group col-md-6">
            <label
              htmlFor="inputEmail1"
              className="ul-form__label"
            >
              Nome
            </label>
            <input
              type="text"
              className="form-control"
              defaultValue={selectedRole.name}
            />
            <small
              id="passwordHelpBlock"
              className="ul-form__text form-text "
            >
              obbligatorio
            </small>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label
              htmlFor="inputEmail2"
              className="ul-form__label"
            >
              Descrizione
            </label>
            <textarea
              className="form-control"
            >{selectedRole.description}</textarea>
            <small
              id="passwordHelpBlock"
              className="ul-form__text form-text "
            >

            </small>
          </div>
        </div>

        <hr></hr>
        <h4>Permessi</h4>


        <Accordion defaultActiveKey={selectedProduct.modules[0].code} className={accordionClassName}>

          {selectedProduct.modules.map(module => {
            return (<Accordion.Item
              key={module.code}
              className="ul-card__border-radius border-0 card"
              eventKey={module.code}
            >
              <Accordion.Header>
                <h6 className="card-title mb-0 text-primary">
                  {module.name}
                </h6>
              </Accordion.Header>
              <Accordion.Body>

                <PermissionsGroups
                  functionGroups={module.functionGroups}
                  filterByRoleId={selectedRole.id}
                  onRemovePermission={() => { }}
                  showRemoveButton={true}
                  showAddButton={false}>

                </PermissionsGroups>

              </Accordion.Body>
            </Accordion.Item>)
          })}


        </Accordion>


      </div >
    );
  }
}

export default RolesDetail;

