import { faker } from '@faker-js/faker/locale/it';

const anagraficheDb = {

  tipologieAnagrafica: [{
    id: 'bd345c44-5109-11ec-bf63-0242ac134442',
    nome: "Assistiti",
    codice: "ASS"
  }, {
    id: 'bd345c44-5109-11ec-bf63-0242ac134443',
    nome: "Medici",
    codice: "MED"
  }, {
    id: 'bd345c44-5109-11ec-bf63-0242ac134444',
    nome: "Pediatri",
    codice: "PED"
  }, {
    id: 'bd345c44-5109-11ec-bf63-0242ac134445',
    nome: "Collegati ad assistito",
    codice: "REL"
  }, {
    id: 'bd345c44-5109-11ec-bf63-0242ac134445',
    nome: "Operatori",
    codice: "OP"
  }, {
    id: 'bd345c44-5109-11ec-bf63-0242ac134445',
    nome: "Account",
    codice: "USER"
  }],

  stati: [{
    id: 'ad345c44-5109-11ec-bf63-0242ac134442',
    nome: 'Italia'
  },
  {
    id: 'ad345c55-5109-11ec-bf63-0242ac134442',
    nome: 'Burundi'
  }],

  provincie: [{
    id: 'ad345c44-5109-11ec-bf63-0242ac134442',
    nome: 'Modena'
  }],

  comuni: [{
    id: 'fr345c44-5609-11ec-bf63-0242ac134442',
    nome: 'Modena'
  },
  {
    id: 'fr3465c44-5609-11ec-bf63-0242ac134442',
    nome: 'Carpi'
  }],

  sesso: {
    'M': "Maschio",
    'F': "Femmina"
  },

  statiCivili: [{
    id: 'ad345c55-5109-11rc-bf63-0242ac134442',
    descrizione: 'Celibe'
  },
  {
    id: 'ad345c55-5109-11rc-br63-0242ac134442',
    descrizione: 'Nubile'
  },
  {
    id: 'ad345ce5-5109-11rc-br63-0242ac134442',
    descrizione: 'Coniugato'
  }],

  statusLavorativi: [{
    id: 'ad345c55-5109-11rc-bf63-0242ac134442',
    descrizione: 'Lavoratore pubblico'
  },
  {
    id: 'ad345c55-5109-11rc-br63-0242ac134442',
    descrizione: 'Lavoratore privato'
  },
  {
    id: 'ad345ce5-5109-11rc-br63-0242ac134442',
    descrizione: 'Lavoratore autonomo'
  },
  {
    id: 'ad345ce5-5109-11rc-br63-0442ac134442',
    descrizione: 'Libero professionista'
  }],

  professioni: [{
    id: 'ad345c55-5109-11rc-bf63-0242ac134442',
    descrizione: 'Agricoltura, silvicoltura e pesca'
  },
  {
    id: 'ad345c55-5109-11rc-br63-0242ac134442',
    descrizione: 'Produzioni alimentari'
  },
  {
    id: 'ad345ce5-5109-11rc-br63-0242ac134442',
    descrizione: 'Servizi di telecomunicazione e poste'
  },
  {
    id: 'ad345ce5-5109-11rc-br63-0442ac134442',
    descrizione: 'Servizi di educazione, formazione e lavoro'
  }],

  titoliDiStudio: [{
    id: 'ad345c55-5109-11rc-bf63-0242ac134442',
    descrizione: 'Diploma di scuola superiore'
  },
  {
    id: 'ad345c55-5109-11rc-br63-0242ac134442',
    descrizione: 'Diploma Univeristario'
  },
  {
    id: 'ad345ce5-5109-11rc-br63-0242ac134442',
    descrizione: 'Laurea Magistrale'
  }],

  tipiCarte: [{
    id: "ad345c55-5109-11rc-bf63-0242ac134122",
    descrizione: "TEAM"
  },
  {
    id: "ad345c55-e409-11rc-bf63-0242ac134122",
    descrizione: "STP"
  },
  {
    id: "ad345c55-51t9-11rc-bf63-0242ac134122",
    descrizione: "ENI"
  }],

  tipiDocumentoDiSoggiorno: [{
    id: 'ad345c55-5109-11rc-bf63-0242ac134122',
    descrizione: "Adozione"
  }, {
    id: 'ad345c55-5109-11rc-bf63-0242rt134122',
    descrizione: "Affidamento"
  }, {
    id: 'ad345c55-5123-11rc-bf63-0242ac134122',
    descrizione: "Aggiornamento permesso di soggiorno"
  }, {
    id: 'ad345c55-5112-11rc-bf63-0242ac134122',
    descrizione: "Attesa occupazione"
  }, {
    id: 're345c55-5112-11rc-bf63-0242ac134122',
    descrizione: "Attesa riacquisto cittadinanza"
  }, {
    id: 'uj345c55-5112-11rc-bf63-0242ac134122',
    descrizione: "Asilo politico rinnovo"
  }, {
    id: 'ad345c55-5112-11rc-bf63-0242ac134145',
    descrizione: "Conversione permesso di soggiorno"
  }, {
    id: 'ad345c55-5112-11rc-bf43-0242ac134122',
    descrizione: "Duplicato Permesso di soggiorno"
  }, {
    id: 'ad345c55-4512-11rc-bf43-0242ac134122',
    descrizione: "Famiglia"
  }, {
    id: 'ad345c55-5342-11rc-bf43-0242ac134122',
    descrizione: "Famiglia minore 14-18 anni"
  }, {
    id: 'ad345c55-5112-67c-bf43-0242ac134122',
    descrizione: "Lavoro Autonomo"
  }, {
    id: 'ad345c55-5112-11rc-b453-0242ac134122',
    descrizione: "Lavoro Subordinato"
  },],

};

anagraficheDb.carte = [
  {
    id: 'ad345c55-5109-11rc-bf63-0242ac134442',
    nome: 'Giovanni',
    cognome: 'Zuffolini',
    tipoCarta: anagraficheDb.tipiCarte[1],
    nazione: anagraficheDb.stati[0],
    numeroIdentificazionePersonale: '00063596854',
    numeroIdentificazioneIstituzioneCompetente: '5478963594',
    nomeIdentificazioneIstituzioneCompetente: 'Comune di Carpi',
    numeroIdentificazione: '00063596854',
    dataScadenza: '31/12/2048'
  }
]

anagraficheDb.indirizzi = [{
  id: 'ad339c44-5109-11ec-bf63-0242ac134442',
  stato: anagraficheDb.stati[0],
  provincia: anagraficheDb.provincie[0],
  comune: anagraficheDb.comuni[0],
  cap: '41123',
  indirizzo: 'Via Zucchi',
  civico: '21',
  scala: '',
  piano: '1'
},
{
  id: 'ad339c44-5109-11ec-bf63-0242ac134442',
  stato: anagraficheDb.stati[0],
  provincia: anagraficheDb.provincie[0],
  comune: anagraficheDb.comuni[1],
  cap: '41012',
  indirizzo: 'Via Alghisi',
  civico: '29',
  scala: '',
  piano: '4'
}]

anagraficheDb.cittadinanze = [{
  id: 'ad339f64-5109-11ec-bf63-0242ac130002',
  stato: anagraficheDb.stati[0],
  idDocumentoSoggiorno: 'CI8265GM',
  idTipoDocumentoSoggiorno: 'Familiare',
  numeroDocumento: '0000891762441',
  dataRilascio: '01/01/2000',
  dataScadenza: '01/01/2025',
  provinciaQuestura: anagraficheDb.provincie[0],
  statoProvenienza: anagraficheDb.stati[1],
  dataArrivoItalia: '01/12/1999',
  dataArrivoComune: '09/12/1999',
}]

anagraficheDb.anagrafiche = [{
  id: 'ad339c44-5109-11ec-bf63-0242ac130002',
  tipologie: [anagraficheDb.tipologieAnagrafica[0]],
  nome: "Giovanni",
  cognome: "Zuffolini",
  cf: 'GNNZFFKDIU28983',
  sesso: 'M',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[2],
  dataNascita: "06/07/1979",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  domicilioCoincideConResidenza: true,
  corrispondenzaCoincideConResidenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[0],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: anagraficheDb.carte[0],
  cartaSTP: null,
  cartaENI: null,
  dataDecesso: "",
}, {
  id: 'ad339c44-5109-11ec-bf63-0242ac130102',
  tipologie: [anagraficheDb.tipologieAnagrafica[0], anagraficheDb.tipologieAnagrafica[1]],
  nome: "Mario",
  cognome: "Rossi",
  cf: 'MRiRSSKDIU28983',
  sesso: 'M',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[1],
  dataNascita: "07/07/1979",
  indirizzoNascita: anagraficheDb.indirizzi[1],
  indirizzoResidenza: anagraficheDb.indirizzi[0],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  coincideConDomicilio: true,
  coincideConCorrispondenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[1],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: anagraficheDb.carte[0],
  cartaSTP: null,
  cartaENI: null,
  dataDecesso: "",
},
{
  id: 'ad339c44-5109-11ec-bf63-0242ac134002',
  tipologie: [anagraficheDb.tipologieAnagrafica[0]],
  nome: "Maria",
  cognome: "Pellacani",
  cf: 'MRTPLCKDIU28983',
  sesso: 'F',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[0],
  dataNascita: "01/07/1989",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  coincideConDomicilio: true,
  coincideConCorrispondenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[2],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: null,
  cartaSTP: anagraficheDb.carte[0],
  cartaENI: null,
  dataDecesso: "",
},
{
  id: 'ad339c44-5109-11ec-bf63-0242ac134003',
  tipologie: [anagraficheDb.tipologieAnagrafica[3]],
  nome: "Mohammed",
  cognome: "Ali",
  cf: 'MHMALICKDIU28983',
  sesso: 'M',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[0],
  dataNascita: "01/07/1961",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  coincideConDomicilio: true,
  coincideConCorrispondenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[2],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: null,
  cartaSTP: anagraficheDb.carte[0],
  cartaENI: null,
  dataDecesso: "",
}]



anagraficheDb.storicoVariazioni = [{
  dataModifica: '01/12/2021',
  nome: "Giovanni",
  cognome: "Zuffolini",
  cf: 'GNNZFFKDIU28983',
  sesso: 'M',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[2],
  dataNascita: "06/07/1979",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  domicilioCoincideConResidenza: true,
  corrispondenzaCoincideConResidenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[0],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: anagraficheDb.carte[0],
  cartaSTP: null,
  cartaENI: null,
  dataDecesso: "",
}, {
  dataModifica: '15/11/2021',
  nome: "Giovanni",
  cognome: "Zuffolini",
  cf: 'GNNZFFKDIU28983',
  sesso: 'M',
  telefono: '059658974',
  cellulare: '333659874',
  email: 'pippo2@mailinator.com',
  fax: '059/896534',
  statoCivile: anagraficheDb.statiCivili[2],
  dataNascita: "06/07/1979",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  domicilioCoincideConResidenza: true,
  corrispondenzaCoincideConResidenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[0],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: anagraficheDb.carte[0],
  cartaSTP: null,
  cartaENI: null,
  dataDecesso: "",
},
{
  dataModifica: '12/11/2021',
  nome: "Giovanni",
  cognome: "Zuffolini",
  cf: 'GNNZFFKDIU28983',
  sesso: 'M',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[2],
  dataNascita: "06/07/1979",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  domicilioCoincideConResidenza: true,
  corrispondenzaCoincideConResidenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[0],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: anagraficheDb.carte[0],
  cartaSTP: null,
  cartaENI: null,
  dataDecesso: "",
},
{
  dataModifica: '02/11/2021',
  nome: "Giovanni",
  cognome: "Zuffolini",
  cf: 'GNNZFFKDIU28983',
  sesso: 'M',
  telefono: '059658962',
  cellulare: '333659874',
  email: 'pippo@mailinator.com',
  fax: '059/896523',
  statoCivile: anagraficheDb.statiCivili[2],
  dataNascita: "06/07/1979",
  indirizzoNascita: anagraficheDb.indirizzi[0],
  indirizzoResidenza: anagraficheDb.indirizzi[1],
  indirizzoDomicilio: anagraficheDb.indirizzi[0],
  indirizzoCorrispondenza: anagraficheDb.indirizzi[1],
  domicilioCoincideConResidenza: true,
  corrispondenzaCoincideConResidenza: true,
  cittadinanza: anagraficheDb.cittadinanze[0],
  titoloStudio: anagraficheDb.titoliDiStudio[0],
  noteTitoloStudio: '',
  statusLavorativo: anagraficheDb.statusLavorativi[1],
  professioneCorrente: anagraficheDb.professioni[2],
  professionePrecedente: anagraficheDb.professioni[2],
  cartaTEAM: anagraficheDb.carte[0],
  cartaSTP: null,
  cartaENI: null,
  dataDecesso: "",
}]
/******************
 * FAMIGLIE
 *****************/

anagraficheDb.tipologieFamiglie = [{
  id: 'ad34rt55-5109-11rc-bf63-0242ac134122',
  descrizione: "Anagrafica"
}, {
  id: 'ad34rt55-5109-11rc-bf63-0242ac144122',
  descrizione: "Famiglia di fatto"
}, {
  id: 'ad34rt55-5109-11rc-bf63-02652ac134122',
  descrizione: "Rete sociale"
},];

anagraficheDb.ruoliFamigliaAnagrafica = [{
  id: 'ad34rt55-5109-11rc-bf63-0242ac134122',
  descrizione: "Padre"
}, {
  id: 'ad34rt55-5109-11rc-bf63-0242ac134156',
  descrizione: "Madre"
}, {
  id: 'ad34rt55-5109-11rc-bf63-0232ac134156',
  descrizione: "Figlio"
}, {
  id: 'ad34rt55-5109-11rc-bf63-02467ac134156',
  descrizione: "Figlia"
}];

anagraficheDb.ruoliFamigliaDiFatto = [{
  id: 'ad34rt55-5109-11rc-bf63-0242ac134122',
  descrizione: "Convivente"
}, {
  id: 'ad34rt55-5109-11rc-bf63-0242ac134156',
  descrizione: "Badante"
}];

anagraficheDb.ruoliFamigliaReteSociale = [{
  id: 'ad34rt55-5109-11rc-bf63-0242ac134122',
  descrizione: "Amico"
}, {
  id: 'ad34rt55-5109-11rc-bf63-0242ac134156',
  descrizione: "Maestro di yoga"
}];

anagraficheDb.famiglie = [{
  id: 'ad34rt55-5109-11rc-bf63-026wrac134122',
  tipologia: anagraficheDb.tipologieFamiglie[0],
  codice: "JDBGHE7892J",
  docStatoDiFamiglia: "filename.pdf",
  dataModificaStatoDiFamiglia: "02/08/2021",
  motivoModificaStatoDifamiglia: "",
  monogenitoriale: true,
  nucleoStraniero: false,
  nucleoStranieroMisto: false,
  nucleoNomade: false,
  note: "",
  ruoli: [{
    id: 'ad34rt55-5109-11rc-bf63-026wrac134122',
    anagrafica1: anagraficheDb.anagrafiche[0],
    ruolo1: anagraficheDb.ruoliFamigliaAnagrafica[0],
    anagrafica2: anagraficheDb.anagrafiche[2],
    ruolo2: anagraficheDb.ruoliFamigliaAnagrafica[3],
  }, {
    id: 'ad34rt55-5109-11rc-bf63-026wrac234122',
    anagrafica1: anagraficheDb.anagrafiche[0],
    ruolo1: anagraficheDb.ruoliFamigliaAnagrafica[0],
    anagrafica2: anagraficheDb.anagrafiche[1],
    ruolo2: anagraficheDb.ruoliFamigliaAnagrafica[2],
  }]
}, {
  id: 'ad34rt55-5109-11rc-bf63-026wrac172922',
  tipologia: anagraficheDb.tipologieFamiglie[2],
  codice: "",
  docStatoDiFamiglia: "",
  dataModificaStatoDiFamiglia: "",
  motivoModificaStatoDifamiglia: "",
  monogenitoriale: false,
  nucleoStraniero: false,
  nucleoStranieroMisto: false,
  nucleoNomade: false,
  note: "",
  ruoli: [{
    id: 'ad34rt55-5109-11rc-bf63-026wrac134122',
    anagrafica1: anagraficheDb.anagrafiche[0],
    ruolo1: anagraficheDb.ruoliFamigliaAnagrafica[0],
    anagrafica2: anagraficheDb.anagrafiche[3],
    ruolo2: anagraficheDb.ruoliFamigliaReteSociale[0],
  }]
},]


/*******************
 * DOCUMENTI
 ******************/

anagraficheDb.tipologieDocumenti = [{
  id: 'ad339f64-5109-11ec-bf63-0242ac136702',
  descrizione: "Consenso"
}, {
  id: 'ad339f64-5109-11ec-bf63-0242ac1303402',
  descrizione: "Revoca consenso"
}, {
  id: 'ad339f64-5109-11ec-bf63-0242ac1323002',
  descrizione: "Sospensione consenso"
}, {
  id: 'ad339f64-5109-11ec-bf63-0242ac13wd002',
  descrizione: "Altro"
},
]

anagraficheDb.schedeDocumenti = [{
  id: 'ad339f64-5109-11ec-bf63-0223ac136702',
  descrizione: "Cartella"
}, {
  id: 'ad339f64-5109-11ec-bf63-02hht1303402',
  descrizione: "Domanda SIA"
}, {
  id: 'ad339f64-5109-11ec-bf63-02sdvac1323002',
  descrizione: "Domanda FNA"
}, {
  id: 'ad339f64-5109-11ec-bf63-02vfac13wd002',
  descrizione: "PUA"
},
]

anagraficheDb.documenti = [{
  id: 'ad339f64-5109-11ec-bf63-0242ac130002',
  tipologia: anagraficheDb.tipologieDocumenti[0],
  scheda: anagraficheDb.schedeDocumenti[0],
  data: '01/12/2021',
  operatore: 'mario.rossi',
  descrizione: 'Documento 1',
  nota: '',
}, {
  id: 'ad339f64-5109-11ec-bf63-0242ac130004',
  tipologia: anagraficheDb.tipologieDocumenti[1],
  scheda: anagraficheDb.schedeDocumenti[1],
  data: '04/12/2021',
  operatore: 'mario.rossi',
  descrizione: 'Documento 2',
  nota: '',
}, {
  id: 'ad339f64-5109-11ec-bf63-0242ac130005',
  tipologia: anagraficheDb.tipologieDocumenti[2],
  scheda: anagraficheDb.schedeDocumenti[2],
  data: '11/12/2021',
  operatore: 'mario.rossi',
  descrizione: 'Documento 3',
  nota: '',
}]

export function genAnagrafica() {
  return {
    uniqueId: faker.datatype.uuid(),
    datiAnagrafici: {
      nome: faker.name.firstName(),
      cognome: faker.name.lastName(),
      cf: faker.finance.routingNumber(),
    },
    datiNascita: {
      data: faker.date.past(30).toISOString(),
    },
    indirizzo: faker.address.streetAddress(true),
    nazioneResidenza: faker.address.country(),
    provinciaResidenza: faker.address.state(),
    comuneResidenza: faker.address.city(),
    aslResidenza: `ASTT ${faker.address.state()}`,
    nazioneDomicilio: faker.address.country(),
    provinciaDomicilio: faker.address.state(),
    comuneDomicilio: faker.address.city(),
    telefono: faker.phone.number(),
    qualificaOperatore: {
      descrizione: faker.helpers.arrayElement(['Inf.', 'TDR', 'Asst']),
    },
    // 'INF', 'TDR', 'OSS'
  }
}


export default anagraficheDb;