
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'

export function CollapsableMenu(props) {

  const menu = props.menu;
  const defaultTab = props.defaultTab;
  const baseTabId = props.baseTabId;

  const [dropDownMenu, setDropDownMenu] = useState([]);
  const [selectedDropDownMenuItem, setSelectedDropDownMenuItem] = useState({});

  const selectDropDownItem = (item) => {

    const selectTab = (tabCode) => {
      let activeTab = document.querySelector(".nav-item > .active");
      if (activeTab) {
        activeTab.classList.remove("active");
      }

      let tabToSelect = document.getElementsByClassName(tabCode);
      if (tabToSelect && tabToSelect.length > 0) {
        tabToSelect[0].className += " active";
      }
    }

    const showTabPane = (tabCode) => {

      let tabPanes = document.getElementsByClassName("tab-pane");
      for (let i = 0; i < tabPanes.length; i++) {
        tabPanes[i].style.display = "none";
      }

      let dropdownDiv = document.getElementById(baseTabId + "-" + tabCode);
      if (dropdownDiv) {
        dropdownDiv.style.display = "block";
      }

    }

    let selectedMenuItem = null;
    let selectableMenuItems = [];

    for (let i = 0; i < menu.items.length; i++) {
      if (menu.items[i].tabToOpen === item.tabToOpen) {
        selectedMenuItem = menu.items[i];
      }
      else {
        selectableMenuItems.push(menu.items[i]);
      }
    }
    setDropDownMenu(selectableMenuItems);
    setSelectedDropDownMenuItem(selectedMenuItem);

    showTabPane(item.tabToOpen);
    selectTab(item.tabToOpen);
  }

  if (!selectedDropDownMenuItem.tabToOpen) {
    for (let i = 0; i < menu.items.length; i++) {
      if (menu.items[i].tabToOpen === defaultTab) {
        selectDropDownItem(menu.items[i]);
        break;
      }
    }
  }

  return (<div>
    <div className="d-none d-md-inline">
      <Nav justify variant="pills" className="justify-content" defaultActiveKey={defaultTab}>
        {menu.items.map(menuItem => {

          return (<Nav.Item key={menuItem.tabToOpen}>
            <Nav.Link href="#" id={menuItem.tabToOpen + "-tab"} eventKey={menuItem.tabToOpen} className={menuItem.tabToOpen}><span>{menuItem.title}</span></Nav.Link>
          </Nav.Item>);
        })}

      </Nav>
    </div>

    <Dropdown className="nav-tabs d-md-none">
      <Dropdown.Toggle id="dropdown-menu" style={{ display: 'block', width: '100%' }}>
        {selectedDropDownMenuItem.title}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ width: '100%' }} >
        {dropDownMenu.map(menuItem => {

          return (<Dropdown.Item className="nav-item" key={menuItem.tabToOpen} onClick={() => {
            selectDropDownItem({ title: menuItem.title, tabToOpen: menuItem.tabToOpen });
          }}>{menuItem.title}</Dropdown.Item>);
        })}
      </Dropdown.Menu>
    </Dropdown>
  </div>);
}
export default CollapsableMenu;
