import { textFilter, selectFilter, dateFilter } from "@ai4/react-bootstrap-table2-filter";
import { Formatters } from "@ai4/records-management";

export function filterDefault() {
    return { placeholder: 'Filtra'}
}

export function filterDocumentArgs(args: any) {
    const { fieldName, onDownloadFile } = args;

    return { 
        filter: textFilter(filterDefault()),
        filterValue: (cell: any) => {
            return cell.fileName;
        },
        formatter: (cell, row, rowIndex, extraData) => {
            return <a className="btn btn-outline-dark btn-sm" {...onDownloadFile({ field: [{ name: fieldName, value: cell }], file: cell })}>{Formatters.fileFormatter(cell, row, rowIndex, extraData)}</a>
        },
    }
}

export function nodeContent(type: string) {
    if (type === 'file') return `{
        uniqueId
        fileName
        fileLength
        fileExt
    }`;
}

export function changeUrlParams(changeMap: Record<string, string>) {
    const url = new URL(window.location.href);
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    Object.keys(changeMap).forEach(k => {
        searchParams.set(k, changeMap[k]);
    });
    return `${url.pathname}?${searchParams.toString()}`;
}

export function removeHash() { 
    // eslint-disable-next-line no-restricted-globals
    history.pushState("", document.title, window.location.pathname + window.location.search);
}

export function pathToSlug(path: string) { 
    return path.replace(/\./g, '_')
}

export function testRepeatArray( arr: any[], num: number ) {
    let res = [] as any[];
    for (let i=0; i<num; i++) {
        res = [...res, ...arr];
    }
    return res;
}

export function strContains( haystack: string, needle: string ) {
    return haystack.toLowerCase().includes(needle.toLowerCase());
}

export function lowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function formatDate(raw: string, format: string = 'full') {
    if (!raw) return null;
    if (raw.substring(0,1) === '0') return null;
    const [date, time] = raw.split('T');
    const [y, m, d] = (date || '').split('-');
    const [hh, mm, ss] = (time || '').split(':');
    switch (format) {
        case 'date':
            return `${d}/${m}/${y}`;
        case 'time':
            return `${hh}:${mm}`;
        case 'full':
            return `${d}/${m}/${y} ${hh}:${mm}`;
        case 'date-iso':
            return `${y}-${m}-${d}`;
    }
}

export function currentDateTime() {
    var now     = new Date(); 
    var year    = `${now.getFullYear()}`;
    var month   = `${now.getMonth()+1}`;
    var day     = `${now.getDate()}`;
    var hour    = `${now.getHours()}`;
    var minute  = `${now.getMinutes()}`;
    var second  = `${now.getSeconds()}`;
    if (month.length === 1) {
        month = '0'+month;
    }
    if(day.length === 1) {
        day = '0'+day;
    }   
    if(hour.length === 1) {
        hour = '0'+hour;
    }
    if(minute.length === 1) {
        minute = '0'+minute;
    }
    if(second.length === 1) {
        second = '0'+second;
    }
    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

/**
* Splits a Pascal-Case word into individual words separated by spaces. 
* @param {Object} word
* @returns {String}
*/
export function splitPascalCase(word) {
   var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
   return word.match(wordRe).join(" ");
}


/**
* Splits a camelCase or PascalCase word into individual words separated by spaces. 
* @param {Object} word
* @returns {String}
*/
export function splitCamelCase(word) {
   var output, i, l, capRe = /[A-Z]/;
   if (typeof(word) !== "string") {
       throw new Error("The \"word\" parameter must be a string.");
   }
   output = [];
   for (i = 0, l = word.length; i < l; i += 1) {
       if (i === 0) {
           output.push(word[i].toUpperCase());
       }
       else {
           if (i > 0 && capRe.test(word[i])) {
               output.push(" ");
           }
           output.push(word[i]);
       }
   }
   return output.join("");
}
