import React, { Component } from "react";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import NotificationsCard from "./components/NotificationsCard";
import { TaskManagerList } from "./components/TaskManagerList";

class UsersDashboard extends Component {
  state = {
    cardList1: [
      {
        icon: "i-Address-Book",
        title: "62.859",
        subtitle: "anagrafiche",
      },
      {
        icon: "i-Bell",
        title: "24.596",
        subtitle: "consensi mancanti",
      },
      {
        icon: "i-Over-Time-2",
        title: "7",
        subtitle: "progetti in scadenza",
      },
      {
        icon: "i-File-Block",
        title: "2",
        subtitle: "progetti da validare",
      }
    ]
  };

  getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  render() {
    let {
      cardList1 = [],
    } = this.state;

    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Dashboard", path: "dashboard/users/DashboardUsers" },
            { name: "Operatore" }
          ]}
        ></Breadcrumb>
        <div className="row">
          {cardList1.map((card, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center">
                  <i className={card.icon}></i>
                  <div className="content">
                    <p className="text-muted mt-2 mb-0 text-capitalize">
                      {card.subtitle}
                    </p>
                    <p className="lead text-primary text-24 mb-2 text-capitalize">
                      {card.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12" style={{ paddingBottom: '15px' }}>

            <TaskManagerList></TaskManagerList>
          </div>
          <div className="col-lg-6 col-md-12" style={{ paddingBottom: '15px' }}>
            <NotificationsCard></NotificationsCard>
          </div>
        </div>


      </div>
    );
  }
}

export default UsersDashboard;
