import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Tabs, Tab, Button } from 'react-bootstrap';
import { gql, useDataRequest } from '@ai4/data-request';
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import $schema from './schema/edit/nuova.cartella.schema.json';
import { GET_ASSISTITO } from '../anagrafiche/gql/assistiti';
import { _assoc } from '../anagrafiche/common';
import { ConfigContext } from 'src/@bootstrap/Config';

const RELATED = gql`
	query getRelatedCartella {
		struttureServiziQuery {
			struttureSanitarie {
                list {
                    uniqueId
                    nome
                }
			}
            erogatori {
				list {
					uniqueId
					nome
				}
			}
		}
	}
`;

export function Dt({ label, value }) {
    return <div className="row">
        <label className="col-sm-4 col-form-label"><strong>{label}</strong></label>
        <div className="col-sm-8">
            <input type="text" readOnly className="form-control-plaintext" value={value} />
        </div>
    </div>;
}

const NuovaCartella = () => {
    const { anagrafica } = useParams();
    const { hasFeature } = useContext(ConfigContext);
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(RELATED);
	const navigate = useNavigate();

	const query = useQuery(GET_ASSISTITO, {
        variables: {
            id: anagrafica,
        }
    });
    const data = _get(query.data, 'anagraficaQuery.assistiti.list', []);
    const assistito = useMemo(() => {
        return data.length > 0 ? data[0] : {};
    }, [data]);

    const [execSave, resSave] = useRestRequest({
        path: 'api/{ver}/cartellasociosanitaria/cartelle',
		jwt: true,
	});

    const [execSavePresaInCarico, resSavePresaInCarico] = useRestRequest({
        path: 'api/{ver}/cartellasociosanitaria/cartelle/{cartellaId}/presa-in-carico',
		jwt: true,
	});

	const schema = useSelectItemsPopulate(
		$schema as Schema,
        related, {
            strutturaSanitariaId: [
                ..._get(related.data, 'struttureServiziQuery.struttureSanitarie.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
            ],
            erogatoreUniqueId: [
                ..._get(related.data, 'struttureServiziQuery.erogatori.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
            ],
        }
	);
    
    const submit = useCallback(async ({values}) => {
        const { erogatoreUniqueId, ...rest } = values;
        try {
            const insertedId = await execSave({
                data: {
                    dati: {
                        ...rest,
                        assistitoUniqueId: anagrafica
                    },
                }
            });
            if (erogatoreUniqueId) {
                await execSavePresaInCarico({
                    path: `api/{ver}/cartellasociosanitaria/cartelle/${insertedId}/presa-in-carico`,
                    data: {
                        dati: {
                            erogatoreUniqueId,
                        },
                    }
                });
            }
            navigate(`/cartelle/ricerca?assistitoUniqueId=${anagrafica}`);
        } catch(e) {
        }
    }, [anagrafica]);
    
    const directCreate = useCallback(async () => {
        try {
            const insertedId = await execSave({
                data: {
                    dati: {
                        assistitoUniqueId: anagrafica
                    },
                }
            });
            navigate(`/cartelle/ricerca?assistitoUniqueId=${anagrafica}`);
        } catch(e) {
        }
    }, [anagrafica]);

    if (!hasFeature("cartelle_creation")) {
        return <div>
            <h1>Nuova cartella</h1>
            <div className="row g-3">
                <div className="col-sm-6">
                    <Alert variant={'primary'}>
                        <p>Stai per creare una nuova cartella per <strong>{_get(assistito, 'datiAnagrafici.nome')} {_get(assistito, 'datiAnagrafici.cognome')}</strong> (CF: {_get(assistito, 'datiAnagrafici.codiceFiscale')})</p>
                        <p>Vuoi continuare?</p>
                    </Alert>
                    <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                        <Button variant="secondary" onClick={() => navigate(`/cartelle/ricerca?assistitoUniqueId=${_get(assistito, 'uniqueId')}`)}>
                            Annulla
                        </Button>
                        <Button variant="primary" onClick={directCreate}>
                            Crea nuova cartella
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    }

    if (!assistito || !schema) return null;
    return <div>
        <h1>Nuova cartella</h1>
        <div className="row g-3">
            <div className="col-sm-6">
                <Alert variant={'primary'}>
                    <Dt label='Nome' value={_get(assistito, 'datiAnagrafici.nome')} />
                    <Dt label='Cognome' value={_get(assistito, 'datiAnagrafici.cognome')} />
                    <Dt label='Indirizzo' value={_get(assistito, 'datiAnagrafici.datiResidenza.luogo.comune.descrizione')} />
                    <Dt label='Cod. Fiscale' value={_get(assistito, 'datiAnagrafici.codiceFiscale')} />
                </Alert>
                <FormViewer
                    initialValues={{}}
                    schema={schema as Schema}
                    onSubmit={submit}
                    slots={{
                        ButtonBar: (args) => <>
                            <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                                <Button variant="secondary" onClick={() => navigate(`/cartelle/ricerca?assistitoUniqueId=${_get(assistito, 'uniqueId')}`)}>
                                    Annulla
                                </Button>
                                <Button variant="primary" type='submit'>
                                    Crea nuova cartella
                                </Button>
                            </div>
                        </>
                    }}
                />
            </div>
        </div>
    </div>
}


export default NuovaCartella;