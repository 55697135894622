import React, { Component } from "react";
import { Dropdown, Spinner } from "react-bootstrap";
import { isMobile } from 'react-device-detect'
import { Formatters, Helpers } from '@ai4/records-management';
import TableWidget from "./TableWidget";

interface Props {
  data?: {
    uniqueId: string;
    cartellaUniqueId: string;
    codice: string;
    assistito: string;
    dataInizio: string;
    dataChiusura: string;
    stato: number;
  }[]
}

export function ProgettiInScadenza(props: Props) {
  const { data = [] } = props;
  const state = {
    dataTablePage: 0,
    dataTablePerPage: 6,
  };

  let { dataTablePage, dataTablePerPage } = state;

  let dotStyle= { marginLeft: '4px', backgroundColor: 'black !important'};

  return (  
    <div className="card h-100">
      <div className="card-body">
        <div className="ul-widget__head border-0 mb-2">
            <div className="ul-widget__head-label">
              <h3 className="ul-widget__head-title">Progetti in scadenza</h3>
              <p>Su progetti inseriti negli ultimi 7 giorni.</p>
            </div>
        </div>
        {!data && <Spinner />}
        {data && data.length === 0 && <div className="empty">Nessun titolo in scadenza</div>}
        {data && data.length > 0 && <>
        <TableWidget
          data={data}
          ipp={dataTablePerPage}
          head={() => <thead>
            <tr className="ul-widget6__tr--sticky-th">
              <th scope="col">Cod. Cartella</th>
              {!isMobile && <>
              <th scope="col">Assistito</th>
              <th scope="col">Inizio</th>
              </>}
              <th scope="col">Conclusione</th>
              <th scope="col">Azione</th>
            </tr>
          </thead>}
          row={(item, i) => <tr key={i} /*className={ pratica.fine === "10/03/2022" ? "list-group-item-danger" : "" }*/>
            <td>
              <span>
                {item.codice}
              </span>
            </td>
            {!isMobile && <>
            <td>{item.assistito}</td>
            <td>{Formatters.dateFormatter(item.dataInizio, item, i, null)}</td>
            </>}
            <td>{Formatters.dateFormatter(item.dataChiusura, item, i, null)}</td>
            <td>
              <a href={`/cartelle/dettaglio/${item.cartellaUniqueId}#${Helpers.buildHash([{ tab: 'affidamenti' }, { entity: 'affidamenti', action: 'edit', uniqueId: item.uniqueId }])}`}>
                  <i className="bi bi-arrow-right-circle" />
              </a>
            </td>
          </tr>}
        />
        </>}
      </div>
    </div>
    );
}

export default ProgettiInScadenza;
