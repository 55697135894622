import { authRoles } from "src/app/roles";
import Reports from "./Reports";

const reportsRoutes = [
    {
        path: "reports/:tipo",
        element: <Reports />,
        // auth: authRoles.admin
    },
];

export default reportsRoutes;
