import { gql } from "@ai4/data-request";

export const GET_PRESTAZIONE = gql`
    query getPrestazione($id: Guid!) {
        struttureServiziQuery {
            prestazioni {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    codice
                    descrizione
                    nome
                }
            }
        }
    }
`;