import React, { Suspense, useMemo } from 'react';
import { Theme } from 'src/@bootstrap/types';

const theme: Theme = {
    header: {
        logo: '/assets/images/4C.png',
    },
    footer: {
        copyright: '&copy; 2021 Pikkart',
        notes: 'All rights reserved',
    }
}


export default theme;