import { authRoles } from "src/app/roles";
import Crud from "src/@bootstrap/components/Crud/Crud";

const struttureServiziRoutes = [
    {
        path: "strutture-servizi/erogatori",
        element: <Erogatori />,
        auth: authRoles.admin
    },
    {
        path: "strutture-servizi/servizi",
        element: <Servizi />,
        auth: authRoles.admin
    },
    {
        path: "strutture-servizi/prestazioni",
        element: <Prestazioni />,
        auth: authRoles.admin
    },
    {
        path: "strutture-servizi/struttureSanitarie",
        element: <StruttureSanitarie />,
        auth: authRoles.admin
    },
];

function Erogatori() {
    // FullCrud
    return <Crud module='strutture-servizi' entity='erogatori' />;
};

function Servizi() {
    // FullCrud
    return <Crud module='strutture-servizi' entity='servizi' />;
};

function Prestazioni() {
    // FullCrud
    return <Crud module='strutture-servizi' entity='prestazioni' />;
};

function StruttureSanitarie() {
    return <Crud module='strutture-servizi' entity='struttureSanitarie' />;
};


export default struttureServiziRoutes;
