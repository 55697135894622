import React, { Component } from "react";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import { FormViewer } from "@ai4/form-viewer";
import $schema from './schema-progetto.json';

class DettaglioProgetto extends Component {


  render() {


    return (
      <div>
        
        <Breadcrumb
          routeSegments={[
            { name: "Progetto 2CBFD338-7 di ROSSI GIUSEPPE" }
          ]}
        ></Breadcrumb>
        <FormViewer schema={$schema} />
      </div>
    );
  }
}

export default DettaglioProgetto;
