import React from "react";
import FilterTable from '../../commons/filterTable';
import Badge from 'react-bootstrap/Badge';
import { isMobile } from 'react-device-detect'

export function PermissionsList(props) {

  const showAddButton = props.showAddButton;
  const showRemoveButton = props.showRemoveButton;

  const onRemovePermission = (role, moduleFunction) => {
    props.onRemovePermission(role, moduleFunction);
  }

  const onAddPermission = (moduleFunction) => {
    props.onAddPermission(moduleFunction);
  }

  const columns = [{
    dataField: 'id',
    text: 'ID',
    hidden: true,
  }, {
    dataField: 'name',
    text: 'Funzione',
    sort: true,
    formatter: (cell, row, rowIndex, formatExtraData) => {
      const description = isMobile ? null : (<div><span className="row-subtitle">{row.description}</span></div>);
      return (<div><span>{row.name}</span>
        {description}</div>);
    }
  }, {
    dataField: '',
    text: 'Ruoli',
    formatter: (cell, row, rowIndex, formatExtraData) => {
      return row.roles.map(item => {
        return (<div key={item.id}><Badge style={{ marginRight: '5px' }}>{item.name} <a href="#" style={{ fontWeight: 'bold' }} onClick={() => {
          onRemovePermission(item, row);
        }}>x</a></Badge></div>)
      });
    }
  },
  ];

  if (showAddButton) {
    columns.push({
      dataField: 'add', text: '', editable: false, align: "center", headerStyle: { width: 30 }, formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<i className="nav-icon i-Add font-weight-bold" onClick={() => {
          onAddPermission(row);
        }}><span className="visually-hidden">aggiungi</span> </i>);
      }
    })
  }

  if (showRemoveButton) {
    columns.push({
      dataField: 'remove', text: '', editable: false, align: "center", headerStyle: { width: 30 }, formatter: (cell, row, rowIndex, formatExtraData) => {
        return (<i className="nav-icon i-Remove font-weight-bold" style={{ color: 'red' }} onClick={() => {
          onRemovePermission(row);
        }}><span className="visually-hidden">rimuovi</span> </i>);
      }
    })
  }

  const rows = props.functionsList;

  return (
    <div>

      <FilterTable
        data={rows}
        columns={columns}
        rows={rows}
        hidePagination={true}
        hideToggle={true}
        hideEditColumns={true}
        onSelectedRow={(row) => {

        }}
      >
      </FilterTable>

    </div>
  );


}

export default PermissionsList;

