import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormViewer } from "@ai4/form-viewer";
import * as yup from "yup";
import $schema from './schema-evento.json';

const CalendarEventDialog = ({
  open,
  eventObject,
  handleSubmit,
  handleDeleteEvent,
  closeDialog,
}) => {
  const handleFormSubmit = (values) => {
    handleSubmit(values);
  };

  return (
    <Modal show={open} centered={true} onHide={closeDialog}>
      <Modal.Header className="bg-primary text-white d-flex flex-wrap justify-content-between align-items-center">
        <h4 className="m-0">Appuntamento</h4>
        <i
          className="i-Close-Window cursor-pointer text-18"
          onClick={closeDialog}
        ></i>
      </Modal.Header>
      <Modal.Body>
        <h4>Rossi Giuseppe </h4>

          <FormViewer schema={$schema}  />

         
      </Modal.Body>
    </Modal>
  );
};

const eventSchema = yup.object().shape({
  title: yup.string().required("Title is required"),
  start: yup.string().required("Start date is required"),
});

export default CalendarEventDialog;