import { lazy } from "react";
import { authRoles } from "src/app/roles";

//const DashboardAdmin = lazy(() => import("./admin/DashboardAdmin"));
//const DashboardUsers = lazy(() => import("./users/DashboardUsers"));
const DashboardSupervisor = lazy(() => import("./supervisor/DashboardSupervisor"));

const dashboardRoutes = [
  /*{
    path: "dashboard/admin/DashboardAdmin",
    element: <DashboardAdmin />,
    auth: authRoles.admin
  },
  {
    path: "dashboard/users/DashboardUsers",
    element: <DashboardUsers />,
    auth: authRoles.editor
  }*/
  {
    path: "dashboard/supervisor/DashboardSupervisor",
    element: <DashboardSupervisor />,
    auth: authRoles.editor
  }
];

export default dashboardRoutes;
