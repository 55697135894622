import React, { Component } from "react";
import Tab from 'react-bootstrap/Tab'
import CollapsableMenu from '../../commons/collapsable-menu'
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import PermissionsGroups from './groups';
import productsDb from 'src/@fake/db/products';
import RemovePermissionPopup from './remove-permission-popup';
import AddPermissionPopup from './add-permission-popup';

export class PermissionsMain extends Component {

  state = {
    showRemovePermissionPopup: false,
    showAddPermissionPopup: false
  }

  render() {

    let {
      showRemovePermissionPopup,
      showAddPermissionPopup
    } = this.state;

    const menu = {
      items: [
        {
          title: "Anagrafiche",
          tabToOpen: "anagrafiche"
        },
        {
          title: "Cartella",
          tabToOpen: "cartella"
        },
        {
          title: "Configurazione",
          tabToOpen: "config"
        }
      ]
    }

    const onRemovePermission = (role, moduleFunction) => {
      this.setState({ showRemovePermissionPopup: true })
    }

    const onAddPermission = (moduleFunction) => {
      this.setState({ showAddPermissionPopup: true })
    }

    const onCloseRemovePopup = () => {
      this.setState({ showRemovePermissionPopup: false })
    }

    const onCloseAddPopup = () => {
      this.setState({ showAddPermissionPopup: false })
    }


    const removePopup = showRemovePermissionPopup ? <RemovePermissionPopup show={showRemovePermissionPopup} onCloseRemovePopup={onCloseRemovePopup}></RemovePermissionPopup> : null;

    const addPopup = showAddPermissionPopup ? <AddPermissionPopup show={showAddPermissionPopup} onCloseAddPopup={onCloseAddPopup}></AddPermissionPopup> : null;

    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Configurazioni", path: "#" },
            { name: "Permessi", path: "views/config/permissions/main" },
          ]}
        ></Breadcrumb>

        <Tab.Container id="permissions-container" defaultActiveKey="anagrafiche">

          <CollapsableMenu menu={menu} defaultTab="anagrafiche" baseTabId="permissions-container-tabpane"></CollapsableMenu>

          <Tab.Content>

            <Tab.Pane id="anagrafiche" eventKey="anagrafiche" role="tabpanel" aria-labelledby="anagrafiche-tab">

              <PermissionsGroups
                functionGroups={productsDb.products[0].modules[0].functionGroups}
                onRemovePermission={onRemovePermission}
                onAddPermission={onAddPermission}
                showRemoveButton={false}
                showAddButton={true}>

              </PermissionsGroups>

            </Tab.Pane>

            <Tab.Pane id="cartella" eventKey="cartella" role="tabpanel" aria-labelledby="cartella-tab">

              <PermissionsGroups
                functionGroups={productsDb.products[0].modules[1].functionGroups}
                onRemovePermission={onRemovePermission}
                onAddPermission={onAddPermission}
                showRemoveButton={false}
                showAddButton={true}>

              </PermissionsGroups>

            </Tab.Pane>

            <Tab.Pane id="config" eventKey="config" role="tabpanel" aria-labelledby="config-tab">

              <PermissionsGroups
                functionGroups={productsDb.products[0].modules[2].functionGroups}
                onRemovePermission={onRemovePermission}
                onAddPermission={onAddPermission}
                showRemoveButton={false}
                showAddButton={true}>

              </PermissionsGroups>

            </Tab.Pane>

          </Tab.Content>

        </Tab.Container>

        {removePopup}
        {addPopup}

      </div>);
  }
}

export default PermissionsMain;

