import { gql } from "@ai4/data-request";

export const GET_CARTELLA_PROGETTI = gql`
    query getCartellaProgetti
    # ($cartella: Guid!) 
    {
        cartellaSocioSanitariaQuery {
            progetti {
                list
                # (where: { uniqueId: { _eq: $id } })
                {
                    uniqueId
                    codice
                }
            }
        }
    }
`;

export const GET_ASSISTITO_BY_CARTELLA = gql`
    query getAssistitoByCartellaPai($id: Guid!) {
        cartellaSocioSanitariaQuery {
            cartelle {
                list (where: { uniqueId: { _eq: $id } }) {
                    assistito {
                        uniqueId
                    }
                }
            }
        }
    }
`;

export const GET_PROGETTO_PAI = gql`
    query getProgettoPai($id: Guid!) {
        cartellaSocioSanitariaQuery {
            progettiPai {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    codice
                    descrizione
                    obiettivi
                    sintesiBisogno
                    risultatiAttesi
                    altro
                    dataInizio
                    dataScadenza
                    cartella {
                        uniqueId
                    }
                    protocollo {
                        numeroProtocollo
                        dataProtocollo
                    }
                    tipologiaProgetto {
                        uniqueId
                    }
                    modalitaCura {
                        servizio {
                            uniqueId
                            descrizione
                        }
                        percorso {
                            uniqueId
                            descrizione
                        }
                        indiceComplessita {
                            uniqueId
                            descrizione
                        }
                    }
                    responsabileProgetto {
                        uniqueId
                        operatore {
                            uniqueId
                        }
                    }
                    interventi {
                        uniqueId
                        codice
                        tipologiaIntervento {
                            uniqueId
                            codice
                            descrizione
                        }
                        pianificazioneErogazione {
                            numeroAccessi
                            numeroTotaleAccessi
                        }
                        prestazioniPianificate {
                            uniqueId
                            quantitaPrevista
                            prestazione {
                                uniqueId
                                codice
                                descrizione
                                nome
                            }
                        }
                        affidamenti {
                            uniqueId
                            codice
                            dataChiusura
                            motivoChiusura {
                                uniqueId
                            }
                            motivoChiusuraAltro
                        }
                        area {
                            uniqueId
                        }
                    }
                    altriOperatori {
                        uniqueId
                        operatore {
                            uniqueId
                            datiAnagrafici {
                                nome
                                cognome
                            }
                        }
                    }
                }
            }
        }
    }
`;