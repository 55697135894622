import { lazy } from "react";
import { authRoles } from "src/app/roles";

const ListsManagement = lazy(() => import("./lists/ListsManagement"));
const AccountOperatori = lazy(() => import("./accountOperatori/List"));

const configRoutes = [
  {
    path: "config/lists",
    element: <ListsManagement />,
    auth: authRoles.editor
  },
  {
    path: "config/accountOperatori/list",
    element: <AccountOperatori />,
    auth: authRoles.editor
  },
];

export default configRoutes;
