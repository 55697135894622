import _get from 'lodash/get';
import _set from 'lodash/set';
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { ConfigContext } from 'src/@bootstrap/Config';
import {
	useFormBuilderSchemaLoader,
	useHideNodesSchema,
	useSelectItemsPopulate,
} from "../../helpers/data";
import { pathToSlug } from 'src/app/helpers/helpers';
import { _assoc } from '../anagrafiche/common';
import EditServizio from './edit/servizio';
import { GET_SERVIZIO } from './gql/servizi';
import { useCallback, useContext } from 'react';
import Schema from '@ai4/form-viewer/dist/types/schema';

export const useModule = () => {
	const list = 'struttureServiziQuery.servizi';
	const mutation = "struttureServizi.servizi";
    const { hasFeature } = useContext(ConfigContext);
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const query = useLazyQuery(GET_SERVIZIO);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	// build schema	
	var schema = require('./schema/edit/servizio.json');
	if (!hasFeature('servizi_percorsi')) delete schema.tabs[1];

	const BASE = `api/{ver}/struttureservizi/servizi`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dto: {
					...values,
				},
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, [BASE]);
	
	return {
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
		},
		actions: {
			save,
			remove,
		},
		results: {
            save: resSave,
            remove: resDelete,
		},
		schema,
		dataDetails,
		extra: {
			slots: {
				edit: EditServizio,
			},
		},
		table: {
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "codice",
					text: "Codice",
				},
				{
					dataField: "nome",
					text: "Nome",
				},
				{
					dataField: "descrizione",
					text: "Descrizione",
				},
			],
		},
	};
};