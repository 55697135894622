import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Modal, Button, Spinner, ListGroup, Tabs, Tab } from 'react-bootstrap';
import moment from "moment";
import { FormViewer, SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { useDataDecorator, useHideNodesSchema } from 'src/app/helpers/data';
import { selectCurrentUser, selectPermissions, hasPermission } from 'src/@bootstrap/features/auth';
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import $schemaNuovo from '../schema/edit/nuovoAccesso.json';
import getSchemaErogato from '../schema/edit/accessoErogato';
import getSchemaPianificato from '../schema/edit/accessoPianificato';
import { formatDate } from 'src/app/helpers/helpers';
import { _assoc } from '../../anagrafiche/common';
import { useAccessoAnnullamento, useAccessoElimina, useAccessoErogazione, useOperatoriDisponibili } from '../common';
import { GET_CALENDARIO_ACCESSO } from '../gql/calendario.accesso';
import { MAX_TIME, MIN_TIME } from '../Calendario';

const RELATED = gql`
    query getRelatedAccessoCalendario {
        anagraficaQuery {
            assistiti {
                list {
                    uniqueId
                    datiAnagrafici {
                        nome
                        cognome
                    }
                }
            }
        }
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaProgetto {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
                    utenteCollegato {
                        email
                        userName
                    }
				}
			}
            prestazioni {
                list {
                    uniqueId
                    nome
                }
            }
		}
	}
`;

const GET_AFFIDAMENTI_BY_ASSISTITO = gql`
    query getAffidamentiByAssistito($assistitoUniqueId: String) {
        cartellaSocioSanitariaQuery {
            affidamenti {
                list(
                  uniqueIdAssistito: $assistitoUniqueId
                ) {
                  uniqueId
                  codice
                  intervento {
                    progetto {
                      codice
                    }
                  }
                }
            }
        }
    }
`;

interface Props {
    record: any;
    onReload: () => void;
    onClose: () => void;
}

export function CreateAccesso(props: Props) {
    const { record, onReload, onClose } = props;
    const [values, setValues] = useState<any>();
    const { useQuery, useLazyQuery } = useDataRequest();
	const related = useQuery(RELATED);
    const { domain, getClient } = useContext(DataContext);
    const client = getClient ? getClient(domain) : undefined;
    const execAffidamenti = useCallback(({ variables }: any) => {
        if (client) {
            return client.query({
                query: GET_AFFIDAMENTI_BY_ASSISTITO,
                variables
            });
        }
        return Promise.resolve();
    }, []);

    const schemaNuovo = useSelectItemsPopulate(
        $schemaNuovo as unknown as any,
        related, {
            assistitoUniqueId: [..._get(related.data, 'anagraficaQuery.assistiti.list', []).map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId }))],
        }
    );

    const onChange = (args: any) => {
		const {
			values,
			schema,
			getSchemaNode,
			setSchemaNode,
		} = args;

        const assistitoUniqueId = _get(values, 'assistitoUniqueId');
        if (assistitoUniqueId) {
            execAffidamenti({ variables: { assistitoUniqueId }}).then(res => {
                // affidamentoUniqueId: _get(related.data, 'cartellaSocioSanitariaQuery.progettiPai.list', []).map((pai: any) => { return { text: _get(pai, 'codice'), value: _get(pai, 'interventi[0].affidamenti[0].uniqueId') }}),
                const items = _get(res.data, 'cartellaSocioSanitariaQuery.affidamenti.list', [])
                const node = getSchemaNode('affidamentoUniqueId');
                setSchemaNode('affidamentoUniqueId', {
                    ...node,
                    props: {
                        ...(node.props || {}),
                        items: items.map((i) => ({ text: _get(i, 'intervento.progetto.codice'), value: i.uniqueId })),
                        disabled: false,
                    },
                });
            })
        }
	};
    
    const onSubmit = (args: any) => {
        setValues(args.values);
    };

    if (_get(values, 'affidamentoUniqueId')) {
        return <EditAccesso
            {...props}
            record={{
                ...record,
                ...values,
                stato: 'pianificato',
                assistito: _get(related.data, 'anagraficaQuery.assistiti.list', []).find(i => i.uniqueId === values.assistitoUniqueId),
            }}
        />
    }

    return <Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>Crea nuova pianificazione accesso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {related.loading && <Spinner />}
            {!related.loading && <>
            <FormViewer
                initialValues={{}}
                schema={schemaNuovo as Schema}
                onChange={onChange}
                onSubmit={onSubmit}
                slots={{
                    ButtonBar: (args) => {
				        const { form } = args;
                        return <>
                            <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                                <Button variant="secondary" className='ms-auto' onClick={onClose}>
                                    Chiudi
                                </Button>
                                <Button variant="primary" type='submit' disabled={!form.isValid || form.isSubmitting}>
                                    Avanti
                                </Button>
                            </div>
                        </>
                    } 
                }}
            />
            </>}
        </Modal.Body>
    </Modal>;
}

export function EditAccesso(props: Props) {
    const { record: srcRecord, onReload, onClose } = props;
    const [values, setValues] = useState<any>();
    const [inSalvataggio, setInSalvataggio] = useState<any>();
    const [modoSalvataggio, setModoSalvataggio] = useState<string>();
    const [inAnnullamento, setInAnnullamento] = useState<any>();
    const [inErogazione, setInErogazione] = useState<any>();
    const [inEliminazione, setInEliminazione] = useState<any>();

    const user = useSelector(selectCurrentUser);
    const permissions = useSelector(selectPermissions);
    const { useQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
    const query = useQuery(GET_CALENDARIO_ACCESSO, {
        variables: { id: srcRecord.uniqueId },
        skip: !srcRecord.uniqueId,
    });
    const details = useDataDecorator('cartellaSocioSanitariaQuery.accessiDiretti.list', query.data);
    const [exec] = useRestRequest({
        path: '@later',
		jwt: true,
	});

    const { annullamentoModale } = useAccessoAnnullamento({
        record: inAnnullamento,
        onSuccess: async () => {
            onReload();
            onClose();
        },
        onCancel: () => setInAnnullamento(undefined),
    });
    const { erogazioneModale } = useAccessoErogazione({
        record: inErogazione,
        onSuccess: async () => {
            onReload();
            onClose();
        },
        onCancel: () => setInErogazione(undefined),
    });
    const { eliminazioneModale } = useAccessoElimina({
        record: inEliminazione,
        onSuccess: async () => {
            onReload();
            onClose();
        },
        onCancel: () => setInEliminazione(undefined),
    });
    
    const operatori = useOperatoriDisponibili(related);
    const allOperatori = _get(related.data, 'struttureServiziQuery.operatori.list', []);
    const record = useMemo(() => {
        if (!srcRecord.uniqueId) return srcRecord;
        return details ? details[0] : undefined;
    }, [srcRecord, details]);
    const [note, setNote] = useState<string>();
    useEffect(() => {
        setNote(_get(record, 'note'));
    }, [record]);
    const { uniqueId, affidamentoUniqueId, ricorrenzaId } = record || {};
    const isRicorrenza = !!ricorrenzaId;
    const selectedOperatore = (allOperatori || []).find(o => o.uniqueId === _get(values, 'operatorePianificatoUniqueId'));
    const isMe = user.email === _get(selectedOperatore, 'utenteCollegato.email');
    const canEdit = hasPermission(['GestioneAccessoGlobale'], permissions) || 
                    (hasPermission(['GestioneAccessoBase'], permissions) && isMe);
    const canErogare = canEdit || (hasPermission(['RendicontazioneAccessoBase'], permissions) && isMe);

    const schemaErogato = useSchemaReadonly(useHideNodesSchema(useSelectItemsPopulate(
        useTimeMinMax(getSchemaErogato() as unknown as any, MIN_TIME, MAX_TIME),
        related, {
            operatoreErogazioneUniqueId: [...operatori.map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId }))],
            prestazioniErogate: [..._get(related.data, 'struttureServiziQuery.prestazioni.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId }))],
        }
    ), canEdit ? [] : ['operatoreErogazioneUniqueId']), !canEdit);
    const schemaPianificato = useSchemaReadonly(useHideNodesSchema(useSelectItemsPopulate(
        useTimeMinMax(getSchemaPianificato() as unknown as any, MIN_TIME, MAX_TIME),
        related, {
            operatorePianificatoUniqueId: [...operatori.map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId }))],
            prestazioniPianificate: [..._get(related.data, 'struttureServiziQuery.prestazioni.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId }))],
        }
    ), canEdit ? [] : ['operatorePianificatoUniqueId']), !canEdit);

    const salvaDiretto = useCallback(async (values) => {
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi/diretto`;
		const insertedId = await exec({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: {
                    ...values,
                },
                affidamentoUniqueId,
			},
		});
        onReload();
        onClose();
    }, [uniqueId, affidamentoUniqueId]);

    const salvaPianificazione = useCallback(async (modo, values) => {
        const { ricorrenzaId, data, oraInizio, oraFine, ...rest } = values;
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi`;
		const insertedId = await exec({
			path: `${BASE}${uniqueId ? `/${uniqueId}/pianificazione` : '/pianificazione'}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: {
                    ...rest,
                    tipoModifica: uniqueId ? modo : undefined,
                    dataOreInizioPianificata: `${data}T${oraInizio}:00`,
                    dataOreFinePianificata: `${data}T${oraFine}:00`,
                },
                affidamentoUniqueId,
			},
		});
        onReload();
        onClose();
    }, [uniqueId, affidamentoUniqueId]);

    const fixValues = (values) => {
        if (!values.operatorePianificatoUniqueId) values.operatorePianificatoUniqueId = null;
        if (!values.operatoreErogazioneUniqueId) values.operatoreErogazioneUniqueId = null;
        return values;
    }

    const onChange = useCallback(async (args) => {
        const { values } = args;
        const fixedValues = fixValues(values);
        setValues(fixedValues);
    }, []);

    const preSubmit = useCallback(async (args) => {
        const { values } = args;
        const fixedValues = fixValues({
            ...values,
            note,
        });
        const { stato } = fixedValues;
        if (stato === 'eseguito') {
            salvaDiretto(fixedValues);
        } else {
            if (isRicorrenza) {
                setInSalvataggio(fixedValues);
            } else {
                salvaPianificazione('Selezionato', fixedValues);
            }
        }
    }, [salvaDiretto, salvaPianificazione, isRicorrenza, note]);

    const formPianificazione = useCallback(() => {
        const dt = record.dataOreInizioPianificata ? formatDate(record.dataOreInizioPianificata, 'date-iso') : undefined;
        const canAction = canErogare && (dt === moment().format('YYYY-MM-DD') || dt === moment().add(1, 'd').format('YYYY-MM-DD'));
        return <FormViewer
            initialValues={{
                ...record,
                data: record.dataOreInizioPianificata ? formatDate(record.dataOreInizioPianificata, 'date-iso') : undefined,
                oraInizio: record.dataOreInizioPianificata ? formatDate(record.dataOreInizioPianificata, 'time') : undefined,
                oraFine: record.dataOreFinePianificata ? formatDate(record.dataOreFinePianificata, 'time') : undefined,
                prestazioniPianificate: (record.prestazioniPianificate || []).map(p => p.prestazioneUniqueId),
                prestazioniErogate: (record.prestazioniErogate || []).map(p => p.prestazioneUniqueId),
            }}
            components={{
                note: () => <Note value={note} onChange={(note) => setNote(note)} />,
            }}
            schema={schemaPianificato as Schema}
            onInit={onChange}
            onChange={onChange}
            onSubmit={preSubmit}
            slots={{
                ButtonBar: (args) => <>
                    {record.stato === 'pianificato' && <>
                        {record.uniqueId && canAction && <>
                            <Alert variant={'primary'}>
                                <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                                    <Button variant="danger" onClick={() => setInAnnullamento(record)}>
                                        Erogazione non eseguita
                                    </Button>
                                    <Button variant="success" onClick={() => setInErogazione(record)}>
                                        Avvia erogazione adesso
                                    </Button>
                                </div>
                            </Alert>
                        </>}
                        <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                            {record.uniqueId && canEdit && <Button variant="danger" disabled={!canEdit} onClick={() => setInEliminazione(record)}>
                                Elimina
                            </Button>}
                            <Button variant="secondary" className='ms-auto' onClick={onClose}>
                                Chiudi
                            </Button>
                            {canEdit && <Button variant="primary" type='submit'>
                                Salva
                            </Button>}
                        </div>
                    </>}
                    {record.stato === 'annullato' && <>
                        <Alert variant={'warning'}>
                            <p>
                                <strong>Accesso annullato</strong>
                            </p>
                            <p>
                                {_get(record, 'motivoNonEsecuzione.descrizione') || _get(record, 'motivoNonEsecuzioneAltro')}
                            </p>
                        </Alert>
                        <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                            <Button variant="secondary" className='ms-auto' onClick={onClose}>
                                Chiudi
                            </Button>
                        </div>
                    </>}
                </>
            }}
        />
    }, [record, schemaPianificato, canErogare, preSubmit, onClose]);

    const formErogazione = useCallback(() => {
        return <FormViewer
            initialValues={{
                ...record,
                data: record.dataOreInizioErogazione ? formatDate(record.dataOreInizioErogazione, 'date-iso') : undefined,
                oraInizio: record.dataOreInizioErogazione ? formatDate(record.dataOreInizioErogazione, 'time') : undefined,
                oraFine: record.dataOreFineErogazione ? formatDate(record.dataOreFineErogazione, 'time') : undefined,
                prestazioniPianificate: (record.prestazioniPianificate || []).map(p => p.prestazioneUniqueId),
                prestazioniErogate: (record.prestazioniErogate || []).map(p => p.prestazioneUniqueId),
            }}
            components={{
                note: () => <Note value={note} onChange={(note) => setNote(note)} />,
            }}
            schema={schemaErogato as Schema}
            onSubmit={preSubmit}
            slots={{
                ButtonBar: (args) => <>
                    <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                        <Button variant="danger" disabled={!canEdit} onClick={() => setInEliminazione(record)}>
                            Elimina
                        </Button>
                        <Button variant="secondary" className='ms-auto' onClick={onClose}>
                            Chiudi
                        </Button>
                        <Button variant="primary" disabled={!canEdit} type='submit'>
                            Salva
                        </Button>
                    </div>
                </>
            }}
        />
    }, [record, schemaErogato, canEdit, preSubmit, onClose])

    if (inAnnullamento) return annullamentoModale();
    if (inErogazione) return erogazioneModale();
    if (inEliminazione) return eliminazioneModale();
    if (inSalvataggio) {
        return <Modal show={true}>
            <Modal.Body>
                <p>Quali accessi vuoi aggiornare?</p>
                <ListGroup>
                    <ListGroup.Item action active={modoSalvataggio === 'Selezionato'} onClick={() => setModoSalvataggio('Selezionato')}>
                        Questo accesso
                    </ListGroup.Item>
                    <ListGroup.Item action active={modoSalvataggio === 'SelezionatoSuccessivi'} onClick={() => setModoSalvataggio('SelezionatoSuccessivi')}>
                        Tutti i successivi
                    </ListGroup.Item>
                    <ListGroup.Item action active={modoSalvataggio === 'Tutti'} onClick={() => setModoSalvataggio('Tutti')}>
                        Tutti quelli della ricorrenza
                    </ListGroup.Item>
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setInSalvataggio(undefined)}>Annulla</Button>
                <Button variant="primary" disabled={!modoSalvataggio} onClick={() => salvaPianificazione(modoSalvataggio, inSalvataggio) }>Procedi</Button>
            </Modal.Footer>
        </Modal>;
    }
    return <>
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{`${record && record.uniqueId ? (record.stato === 'pianificato' ? 'Pianificazione accesso' : record.stato === 'eseguito' ? 'Dettaglio accesso' : 'Accesso annullato') : "Nuovo accesso"}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!record && <Spinner />}
                {!!record && <>
                {related.loading && <Spinner />}
                {!related.loading && <>
                <Alert>
                    {record.assistito && <p>Assistito: <strong>{_get(record, 'assistito.datiAnagrafici.nome')} {_get(record, 'assistito.datiAnagrafici.cognome')}</strong></p>}
                    {record.operatoreErogazione && <p>Operatore: <strong>{_get(record, 'operatoreErogazione.datiAnagrafici.nome')} {_get(record, 'operatoreErogazione.datiAnagrafici.cognome')} ({_get(record, 'operatoreErogazione.qualificaOperatore.descrizione')})</strong></p>}
                </Alert>

                {record.stato === 'eseguito' && <>
                <Tabs
                    defaultActiveKey="erogazione"
                    className="mb-3"
                >
                    <Tab eventKey="erogazione" title="Dati di erogazione">
                        {formErogazione()}
                    </Tab>
                    <Tab eventKey="pianificato" title="Pianificazione originale">
                        {formPianificazione()}
                    </Tab>
                </Tabs>
                </>}
                {record.stato !== 'eseguito' && <>
                    {formPianificazione()}
                </>}
                </>}
                </>}
            </Modal.Body>
        </Modal>
    </>
}

interface NoteProps {
    value?: string;
    onChange: (val: string) => void;
}

function Note(props: NoteProps) {
    const { value, onChange } = props;

    return <div className="Field mb-3">
        <div>
            <div className="hstack gap-1"><div>
                <label className="form-label" htmlFor="note">Note</label></div>
                <div className="ms-auto"></div>
            </div>
        </div>
        <div className="Field-input">
            <textarea
                id='note'
                className="form-control"
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        </div>
    </div>
}

export function useTimeMinMax(schema: Schema | null, min: string, max: string) {
	if (!schema) return null;
	return traverseSchema(schema, (n: SchemaTypes.Field) => {
		const name = n.name || n.id;
		if (name === 'oraInizio') {
			return {
				...n,
                props: {
                    ...(n.props || {}),
                    range: { min },
                }
			};
		}
		if (name === 'oraFine') {
			return {
				...n,
                props: {
                    ...(n.props || {}),
                    range: { max },
                }
			};
		}
		return n;
	});
}

function useSchemaReadonly(schema: Schema | null, readonly: boolean) {
    if (!readonly) return schema;
    if (!schema) return null;
    return traverseSchema(schema, (n: SchemaTypes.Field) => {
        return {
            ...n,
            props: {
                ...(n.props || {}),
                readonly: true,
            }
        };
    });
}

export default EditAccesso;
