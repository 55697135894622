import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { gql, useDataRequest } from "@ai4/data-request";
import TitleBar from "src/app/components/TitleBar";
import Edit from "src/app/components/Edit";
import { FormViewer } from '@ai4/form-viewer';
import { getEditFormViewer } from '../../anagrafiche/common';
import Crud from 'src/@bootstrap/components/Crud/Crud';

interface SubProps {
	parent: any;
	parent_type: string;
}

const Percorsi = (props: SubProps) => {
	return <Crud
        title='Percorsi'
        module='strutture-servizi'
        entity='percorsi'
		options={{
			...props,
            graphql: {
                details: 'getServizio',
            },
            options: {
                modalSize: 'lg',
                closeWhenSaved: true,
            },
		}}
	/>;
}

export function EditPrestazione(props: any) {
    const {
        record,
        handleClose,
    } = props;
    const subProps = {
        parent: record,
        parent_type: 'servizi',
    }

    const formArgs = useMemo(() => {
        return getEditFormViewer(props);
    }, [props]);

    return (
        <Edit>
            <TitleBar
                path={[
                    `${record.uniqueId ? record.nome : "Nuovo servizio"}`,
                ]}
                onBack={handleClose}
            />
            <FormViewer
                {...formArgs}
                components={{
                    percorsi: () => <>
                        {record.uniqueId && (<>
                        <Percorsi {...subProps} />
                        </>)}
                        {!record.uniqueId && (<>
                        <div className='empty'>
                            Puoi aggiungere percorsi solo dopo aver creato l'elemento.
                        </div>
                        </>)}
                    </>
				}}
            />
        </Edit>
    );
}

export default EditPrestazione;
