import { lazy } from "react";
import { authRoles } from "src/app/roles";

const Dettaglio = lazy(() => import("./dettaglio-progetto"));
const DettaglioTitolo = lazy(() => import("./dettaglio-titolo"));
const Pianificazione = lazy(() => import("./calendar/AppCalendar"));

const progettiRoutes = [
  {
    path: "progetti/dettaglio",
    element: <Dettaglio />,
    auth: authRoles.editor
  },
  {
    path: "progetti/dettaglioTitolo",
    element: <DettaglioTitolo />,
    auth: authRoles.editor
  },
  {
    path: "progetti/pianificazione",
    element: <Pianificazione />,
    auth: authRoles.editor
  }
];

export default progettiRoutes;
