import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Modal, Stack, Table } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import { useFormBuilderSchemaLoader, useSelectItemsPopulate } from 'src/app/helpers/data';
import { ButtonBar } from './Recapiti';
import { useChangeComuniByProvincia, _assoc } from '../common';
import Schema from '@ai4/form-viewer/dist/types/schema';

interface Props {
	items: any[];
	related: any;
    onChange: (values: any) => void;
}

const Sedi = (props: Props) => {
    const { items, related, onChange } = props;
	const [record, setRecord] = useState<any>();

	const handleClose = () => setRecord(undefined);

	const tipologie = _get(related.data, 'anagraficaQuery.elenchiAnagrafica.tipologiaSede.list', []);
	const province = _get(related.data, "elenchiQuery.elenchiGeneraliQuery.provincie.list", []);
	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-sede');
	schema = useSelectItemsPopulate(
		schema as Schema,
		related, {
			nazioneUniqueId: _get(related.data, "elenchiQuery.elenchiGeneraliQuery.nazioni.list", []).map((cat: any) => ({ text: cat.denominazioneIT, value: cat.uniqueId })),
			provinciaUniqueId: province.map(_assoc),
			tipologiaSedeUniqueId: [
				...tipologie.map((cat: any) => ({ text: cat.descrizione, value: cat.uniqueId })),
				{ listManagement: { name: 'TipologiaSede', query_name: 'getSubAnagrafica' } },
			],
		}
	);
	const { onChangeProvincia } = useChangeComuniByProvincia();

	const onRemove = useCallback(async (index) => {
        onChange(items.filter((item, i) => i !== index));
	}, [items]);

	const onFormChange = (args: any) => {
		onChangeProvincia({ ...args });
	};

	if (related.loading) return null;
	return <Fragment>
		<Card className='my-3'>
      		<Card.Body>
				<Stack direction="horizontal" gap={3}>
        			<Card.Title>Sedi</Card.Title>
					<Button variant='success' className='ms-auto' onClick={() => setRecord({})}>Aggiungi sede</Button>
				</Stack>
				<Card.Text>
					{items.length === 0 && <div className='empty'>Nessuna sede inserito</div>}
					{items.length > 0 && <Table striped bordered hover>
						<thead>
							<tr>
								<th>Tipologia</th>
								<th>Provincia</th>
								<th>Località</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{items.map((row, i) => {
							return <tr key={i} style={{ cursor: 'pointer' }} onClick={() => setRecord({ index: i, ...row })}>
								<td>{row.tipologiaSede ? row.tipologiaSede.descrizione : ''}</td>
								<td>{row.provincia ? row.provincia.descrizione : ''}</td>
								<td>{row.localita}</td>
								<td>
									<Button onClick={(e) => {
										e.stopPropagation();
										if (window.confirm("Vuoi continuare?")) {
											onRemove(i);
										}
									}}>Elimina</Button>
								</td>
							</tr>;
						})}
						</tbody>
					</Table>}
				</Card.Text>
			</Card.Body>
   		</Card>
		<Modal show={!!record} onHide={handleClose}>
			<Modal.Body>
				<FormViewer
					schema={schema as any}
					initialValues={record || {}}
					onInit={(form: any) => {
						if (onFormChange) {
							onFormChange({
								...form,
							});
						}
					}}
					onChange={(form: any) => {
						if (onFormChange) {
							onFormChange({
								...form,
							});
						}
					}}
					onSubmit={(args: any) => {
						const { values, form } = args;
						const tipologiaSede = tipologie.find(t => t.uniqueId === values.tipologiaSedeUniqueId);
						const provincia = province.find(t => t.uniqueId === values.provinciaUniqueId);
						const obj = { ...values, tipologiaSede, provincia };
						const newValues = obj.index === undefined ? [...items, obj] : items.map((item, i) => i === obj.index ? obj : item);
            			onChange(newValues);
						handleClose();
					}}
					slots={{
						ButtonBar: (args) => <ButtonBar args={args} onCancel={handleClose} />
					}}
				/>
			</Modal.Body>
		</Modal>
	</Fragment>
}

export default Sedi;