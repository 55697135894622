import _get from 'lodash/get';
import _set from 'lodash/set';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { SchemaTypes } from '@ai4/form-viewer';
import { Formatters } from '@ai4/records-management';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import { getQueryParam } from 'src/@bootstrap/helpers';
import {
	useFormBuilderSchemaLoader,
	useHideNodesSchema,
	useSelectItemsPopulate,
} from "../../helpers/data";
import { GET_CARTELLA } from './gql/cartella';
import $filters from './schema/filters/cartelle.json';
import { _assoc } from '../anagrafiche/common';
import { GET_ASSISTITO } from '../anagrafiche/gql/assistiti';
import { ConfigContext } from 'src/@bootstrap/Config';

const RELATED = gql`
    query getRelatedCartelle {
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaProgetto {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			erogatori {
				list {
					uniqueId
					nome
				}
			}
			aree {
				list {
					uniqueId
					nome
				}
			}
		}
	}
`;

export const usePreModule = () => {
    const { useQuery } = useDataRequest();
    const assistitoUniqueId = getQueryParam('assistitoUniqueId');
	const query2 = useQuery(GET_ASSISTITO, {
		skip: !assistitoUniqueId,
        variables: {
            id: assistitoUniqueId,
        }
    });
    const data = _get(query2.data, 'anagraficaQuery.assistiti.list', []);
    const assistito = useMemo(() => {
        return data.length > 0 ? data[0] : undefined;
    }, [data]);

	if (assistitoUniqueId && !assistito) return null;
	return {
		filters: {
			perAssistito: assistitoUniqueId ? true : false,
			assistito,
		},
	}
}

export const useModule = (options: any) => {
	const { filters } = options || {};
	const list = 'cartellaSocioSanitariaQuery.cartelle';
	const mutation = "cartella.cartelle";
	const { domain, getClient } = useContext(DataContext);
	const navigate = useNavigate();
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
	const query = useLazyQuery(GET_CARTELLA);
	const [execDetails, { data: dataDetails, loading }] = query;
	
    const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resRemove] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	
	const save = useCallback(
        async (values) => {
            const { uniqueId } = values;
            // extraData
            const {  } = values;
            let newValues = { ...values };
            const insertedId = await execSave({
				path: `api/{ver}/cartellasociosanitaria/cartelle${uniqueId ? `/${uniqueId}` : ''}`,
				method: uniqueId ? 'PUT' : 'POST',
				data: {
					dati: {
						...newValues,
					}
				},
			});
            if (getClient) {
                await getClient(domain).refetchQueries({
					include: [pathToSlug(list)],
                });
            }
			return {
				uniqueId: uniqueId || insertedId,
				...values,
			}
        },
        []
    );

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `api/{ver}/cartellasociosanitaria/cartelle/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, []);

	// build schema	
	var schema = useFormBuilderSchemaLoader('presets.erogatore') as SchemaTypes.Schema | null;
	schema = useSelectItemsPopulate(schema, related, {
		tipologiaErogatoreUniqueId: [
			..._get(related.data, "struttureServiziQuery.elenchiStruttureServizi.tipologiaErogatore.list", []).map(_assoc),
			{ listManagement: { name: 'TipologiaErogatore', query_name: 'getRelatedErogatore' } },
		],
	});
	
    const filterSchema = useHideNodesSchema(useSelectItemsPopulate(
		$filters as any,
		related, {
			erogatoreUniqueId: _get(related.data, "struttureServiziQuery.erogatori.list", []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
			areaUniqueId: _get(related.data, 'struttureServiziQuery.aree.list', []).map(_assoc),
		}
	), filters.perAssistito ? ['codiceFiscale'] : []);
	
	return {
		noPagination: true,
		titleGenerator: filters.perAssistito ? (props: any) => `Cartelle (${_get(filters.assistito, 'datiAnagrafici.cognome')} ${_get(filters.assistito, 'datiAnagrafici.nome')})` : undefined,
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
			queryWithVars: () => {
				return `${pathToSlug(list)} (
					$uniqueIdAssistito: String ${filters.perAssistito ? `= "${_get(filters.assistito, 'uniqueId')}"` : ''}
					$nome: String
					$cognome: String
					$codiceFiscale: String
					$erogatoreUniqueId: String
					$areaUniqueId: String
				)`
			},
			listFilters: () => {
                return `list(
                    uniqueIdAssistito: $uniqueIdAssistito
                    uniqueIdErogatore: $erogatoreUniqueId
                    areaUniqueId: $areaUniqueId
                    nomeAssistito: $nome
                    cognomeAssistito: $cognome
                    codiceFiscaleAssistito: $codiceFiscale
					order: {
						dataAttivazione: DESC
					}
                )`;
            },
		},
		actions: {
			list: {
				onSelectedRow: (row) => {
					navigate(`/cartelle/dettaglio/${row.uniqueId}`);
				},
			},
			save,
			remove,
		},
		features: {
			creation: filters.perAssistito,
		},
		results: {
			resSave,
			resRemove,
		},
		schema,
		dataDetails,
		extra: {
			options: {
				onCreate: () => {
					navigate(`/cartelle/nuovo/${filters.assistito.uniqueId}`);
				}
			}
		},
		filters: {
			schema: filterSchema,
		},
		table: {
			dateNascoste: true,
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "codice",
					text: "Codice cartella",
				},
				{
					dataField: "schedaPresaInCarico.erogatore.nome",
					text: "Erogatore",
					dataNode: `schedaPresaInCarico {
						erogatore {
							nome
						}
					}`
				},
				{
					dataField: "assistito.datiAnagrafici.nome",
					text: "Nome",
					dataNode: `assistito {
						datiAnagrafici {
							nome
							cognome
							codiceFiscale
						}
					}`
				},
				{
					dataField: "assistito.datiAnagrafici.cognome",
					text: "Cognome",
					noData: true,
				},
				{
					dataField: "assistito.datiAnagrafici.codiceFiscale",
					text: "CF",
					noData: true,
				},
				{
					dataField: 'metadata.insertDate',
					text: 'Inserito il',
					formatter: Formatters.dateFormatter
				},
			],
		},
	};
};