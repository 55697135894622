import { gql } from "@ai4/data-request";

export const GET_PRESA_IN_CARICO = gql`
    query getPresaInCarico($id: Guid!) {
        cartellaSocioSanitariaQuery {
            schedaPresaInCarico {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    motivo {
                        uniqueId
                        descrizione
                    }
                    esito {
                        uniqueId
                        descrizione
                    }
                    erogatore {
                      uniqueId
                      nome
                    }
                    note
                }
            }
        }
    }
`;