import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dropdown, DropdownButton, Accordion, Button, Modal, ListGroup, Spinner, ButtonGroup } from 'react-bootstrap';
import FullCalendar, { DateSelectArg } from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import itLocale from '@fullcalendar/core/locales/it';
import { isMobile } from 'react-device-detect'
import { FormViewer, SchemaTypes } from '@ai4/form-viewer';
import { gql, useDataRequest } from '@ai4/data-request';
import { filterDefault, formatDate, strContains } from 'src/app/helpers/helpers';
import { useDataDecorator, useSelectItemsPopulate } from 'src/app/helpers/data';
import EditAccesso, { CreateAccesso } from './edit/accesso';
import AccessiGiorno from './AccessiGiorno';
import $schema from './schema/filters/calendario.json';
import { GET_CALENDARIO_PIANIFICATO, GET_CALENDARIO_ESEGUITO } from './gql/calendario';
import './calendario.scss';
import { selectPermissions, hasPermission } from 'src/@bootstrap/features/auth';

const RELATED_CALENDARIO = gql`
    query getRelatedCalendario {
        anagraficaQuery {
            assistiti {
                list {
                    uniqueId
                    datiAnagrafici {
                        nome
                        cognome
                    }
                }
            }
        }
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaProgetto {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
				}
			}
		}
	}
`;

export const MIN_TIME = '07:00';
export const MAX_TIME = '20:00'
const VIEWS = [{key: 'month', label: 'Mese'}, {key: 'week', label: 'Settimana'}, {key: 'list', label: 'Elenco giornaliero'}];

function getCalendarProps(view) {
    return {
        locale: itLocale,
        headerToolbar: {
            start: 'title',
            center: '',
            end: 'today prev,next'
        },
        expandRows: true,
        contentHeight: 1000,
        plugins: [ interactionPlugin, dayGridPlugin, timeGridPlugin ],
        initialView: view === 'day' ? 'timeGridDay' : view === 'week' ? 'timeGridWeek' : 'dayGridMonth',
        slotMinTime: MIN_TIME,
        slotMaxTime: MAX_TIME,
        eventDisplay: 'block',
    }
}

function renderEventContent(evt) {
    const [stato, assistito, operatore] = evt.event.title.split('|');
    const { data } = evt.event.extendedProps;
// const isTele = data.prestazioni.find(p => p.nome.toLowerCase() === 'teleriabilitazione');
    if (isMobile) return <div>&nbsp;</div>;
    return (<div>
        <div>
            {evt.timeText} - {stato}
        </div>
        <div>
            <div>
                {assistito} {operatore}
            </div>
        </div>
{/*isTele && <div>
    <a href="https://telehealth.ai4health.ai/" target="_blank" onClick={(e) => e.stopPropagation()}>
        <strong>TELERIABILITAZIONE</strong>
        &nbsp;<i className="bi bi-box-arrow-up-right" />
    </a>
    </div>*/}
    </div>)
}

export function useCanEditAccesso() {
    const permissions = useSelector(selectPermissions);
    const canEdit = hasPermission(['GestioneAccessoGlobale'], permissions) || hasPermission(['GestioneAccessoBase'], permissions);
    
    return canEdit;
}

const Calendario = () => {
    const [filters, setFilters] = useState<any>({
        mode: 'pianificato'
    });
    const { mode } = filters;
    const canEdit = useCanEditAccesso();
    const permissions = useSelector(selectPermissions);
    const { useQuery, useLazyQuery } = useDataRequest();
	const related = useQuery(RELATED_CALENDARIO);
    const [execLoad, { data, loading }] = useLazyQuery(mode === 'pianificato' ? GET_CALENDARIO_PIANIFICATO : GET_CALENDARIO_ESEGUITO);
    const calendarRef = useRef<any>();
    const [view, setView] = useState<string>(localStorage.getItem('calendario::view') || (hasPermission('GestioneAccessoGlobale', permissions) ? 'month' : 'list'));
    const [record, setRecord] = useState<any>();
    const [records, setRecords] = useState<any>();

    const rows = useDataDecorator('cartellaSocioSanitariaQuery.accessiDiretti.list', data);

    const schema = useSelectItemsPopulate(
        $schema as unknown as any,
        related,
        {
            'operatoreUniqueIds': [
				..._get(related.data, 'struttureServiziQuery.operatori.list', []).map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId })),
				{ creationURL: '/anagrafiche/operatori' },
			],
            'assistitoUniqueIds': [
				..._get(related.data, 'anagraficaQuery.assistiti.list', []).map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId })),
				{ creationURL: '/anagrafiche/assistiti' },
			],
        }
    );
    
    useEffect(() => {
        loadItems();
    }, [filters]);

    useEffect(() => {
        localStorage.setItem('calendario::view', view)
    }, [view]);

    const loadItems = useCallback(async () => {
        if (!filters.from || !filters.to) return;
        await execLoad({
            variables: {
               ...filters,
            }
        });
    }, [execLoad, filters]);

    const changeView = useCallback((view: string) => {
        if (calendarRef.current) {
            let cal = calendarRef.current.getApi();
            cal.changeView(view === 'day' ? 'timeGridDay' : view === 'week' ? 'timeGridWeek' : 'dayGridMonth');
        }
        setView(view);
    }, []);

    const changeMode = useCallback((mode: string) => {
        setFilters(state => ({
            ...state,
            mode, 
        }));
    }, []);

    const onChangeDate = useCallback((date) => {
        setFilters(state => ({
            ...state,
            from: `${date}T00:00:00`, to: `${date}T23:59:59`, 
        }));
    }, []);

    const onChangeDates = useCallback((args) => {
        const { startStr: from, endStr: to } = args;
        setFilters(state => ({
            ...state,
            from, to, 
        }));
    }, []);

    const handleSelect = useCallback((evt) => {
        const { startStr, endStr } = evt;
        if (!canEdit) return;
        if (mode !== 'pianificato') return;

        setRecord({
            dataOreInizioPianificata: startStr,
            dataOreFinePianificata: endStr,
        });

    }, [mode, canEdit]);

    const handleEventClick = useCallback((evt) => {
        const { event } = evt;
        const { extendedProps } = event;
        const { type, data } = extendedProps;
        if (type === 'single') {
            setRecord({
                ...data,
            });
        } else {
            setRecords(data);
        }
    }, []);

    const transformEvent = useCallback((r: any) => {
        const operatore = mode === 'pianificato' ? _get(r, 'operatorePianificato') : _get(r, 'operatoreErogazione');
        return {
            id: `${r.uniqueId}`,
            // title: `${r.stato}|${_get(r, 'assistito.datiAnagrafici.nome')} ${_get(r, 'assistito.datiAnagrafici.cognome')}|${_get(operatore, 'datiAnagrafici.nome')} ${_get(operatore, 'datiAnagrafici.cognome')} (${_get(operatore, 'qualificaOperatore.descrizione')})`,
            title: r.stato,
            start: r[mode === 'pianificato' ? 'dataOreInizioPianificata' : 'dataOreInizioErogazione'],
            end: r[mode === 'pianificato' ? 'dataOreFinePianificata' : 'dataOreFineErogazione'],
            extendedProps: {
                type: 'single',
                data: r,
            },
            borderColor: _get(operatore, 'qualificaOperatore.colore'),
            classNames: [r.stato].map(c => c.toLowerCase()),
        }
    }, [mode]);

    const events = useMemo(() => {
        const map = rows.reduce((acc, r, k) => {
            const qualifica = _get(mode === 'pianificato' ? _get(r, 'operatorePianificato') : _get(r, 'operatoreErogazione'), 'qualificaOperatore.uniqueId');
            const stato = r.stato;
            const data = formatDate(r[mode === 'pianificato' ? 'dataOreInizioPianificata' : 'dataOreInizioErogazione'], 'date-iso');
            const key = `${qualifica}:${stato}:${data}`;

            return {
                ...acc,
                [key]: [ ...(acc[key] || []), r ],
            }
        }, {});

        return Object.keys(map).reduce<any[]>((acc, key) => {
            const items = map[key];
            const r = items[0];
            let evt = transformEvent(r);
            if (items.length > 1) {
                evt = {
                    ...evt,
                    id: `${key}`,
                    title: `${items.length} accessi`,
                    extendedProps: {
                        type: 'multiple',
                        data: items,
                    },
                }
            }
            return [...acc, evt];
        }, []);
    }, [rows, mode]);

    if (!rows) return null;

	return <div>
        {record && !record.uniqueId && <CreateAccesso
            record={record}
            onReload={() => loadItems()}
            onClose={() => setRecord(undefined)}
        />}
        {record && record.uniqueId && <EditAccesso
            record={record}
            onReload={() => loadItems()}
            onClose={() => setRecord(undefined)}
        />}
        {records && <Modal show={true} onHide={() => setRecords(undefined)}>
            <Modal.Header closeButton>
                <Modal.Title>Accessi del {formatDate(records[0].dataOreInizioErogazione || records[0].dataOreInizioPianificata, 'date')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AccessiGiorno
                    rows={records}
                    hideNavigation
                    onEdit={(row) => {
                        setRecord(row);
                        setRecords(undefined);
                    }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setRecords(undefined)}>Chiudi</Button>
            </Modal.Footer>
        </Modal>}
        <div className='row mb-3'>
            <div className="col-sm-2">
                <DropdownButton className="d-grid gap-2" size='lg' title={VIEWS.find(v => v.key === view)?.label}>
                    {VIEWS.map(({key, label}) => {
                        return <Dropdown.Item onClick={() => changeView(key)}>{label}</Dropdown.Item>
                    })}
                </DropdownButton>
            </div>
            <div className="col-sm-4">
                <ButtonGroup size="lg">
                    <Button onClick={() => changeMode('pianificato')} variant={filters.mode === 'pianificato' ? 'primary' : 'secondary'}>Pianificato</Button>
                    <Button onClick={() => changeMode('eseguito')} variant={filters.mode === 'eseguito' ? 'primary' : 'secondary'}>Erogato</Button>
                </ButtonGroup>
            </div>
        </div>
        {schema && <div className="row">
            <div className="col-sm-12">
                <Accordion className='mb-3' defaultActiveKey={isMobile ? '0' : undefined}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Ricerca</Accordion.Header>
                        <Accordion.Body>
                            <FormViewer
                                schema={schema as any}
                                initialValues={{}}
                                onSubmit={(args) => {
                                    const { values, form } = args;
                                    setFilters(state => ({ ...state, ...values }));
                                }}
                                slots={{
                                    ButtonBar: (args) => <>
                                        <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                                            <Button variant="secondary" onClick={() => {}}>
                                                Annulla filtro
                                            </Button>
                                            <Button variant="primary" type='submit'>
                                                Applica
                                            </Button>
                                        </div>
                                    </>
                                }}
                            />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>}
        {view !== 'list' && <div className='Calendar'>
            {loading && <div className="loading"><span><Spinner /></span></div>}
            <FullCalendar
                {...getCalendarProps(view)}
                dayHeaderFormat={{ weekday: 'short', day: view === 'month' ? undefined : 'numeric', omitCommas: true }}
                ref={calendarRef}
                events={events}
                selectable
                eventContent={renderEventContent}
                eventClick={handleEventClick}
                select={handleSelect}
                datesSet={onChangeDates}
            />
        </div>}
        {view === 'list' && <>
            <AccessiGiorno
                rows={rows}
                onChangeDate={onChangeDate}
                onEdit={(row) => setRecord(row)}
            />
        </>}
    </div>
}

export default Calendario;