import { authRoles } from "src/app/roles";
import Crud from "src/@bootstrap/components/Crud/Crud";

const anagraficheRoutes = [
    {
        path: "anagrafiche/assistiti",
        element: <Assistiti />,
        auth: authRoles.admin
    },
    {
        path: "anagrafiche/medici",
        element: <Medici />,
        auth: authRoles.admin
    },
    {
        path: "anagrafiche/operatori",
        element: <Operatori />,
        auth: authRoles.admin
    },
];

function Assistiti() {
    // FullCrud
    return <Crud module='anagrafiche' entity='assistiti' />;
};

function Medici() {
    // FullCrud
    return <Crud module='anagrafiche' entity='medici' />;
};

function Operatori() {
    // FullCrud
    return <Crud module='anagrafiche' entity='operatori' />;
};

export default anagraficheRoutes;
