import _set from 'lodash/set';
import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Accordion, Tabs, Tab, Button, Table, Stack, } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import { DataContext, useDataRequest, useFileRequest } from '@ai4/data-request';
import { Formatters } from "@ai4/records-management";
import FilterTable from 'src/@bootstrap/components/filterTable';
import $filterSchema from './schema/filters.schema.json';
import { dateFormatter, timeFormatter } from 'src/app/helpers/formatters';

interface ReportsFilters {
    anno: string;
    mese: string;
}

const TIPI_REPORTS = {
	'affidamento': {
		titolo: 'Reports',
		columns: [
			{
				dataField: 'id',
				text: 'ID',
				hidden: true,
			},
			{
				dataField: "mese",
				text: "Mese",
			},
			{
				dataField: "dataInserimento",
				text: "Data inserimento",
				formatter: Formatters.dateFormatter,
			},
			{
				dataField: "assistito",
				text: "Assistito",
			},
			{
				dataField: "codiceFiscale",
				text: "Codice fiscale",
			},
			{
				dataField: 'codicePratica',
				text: 'Codice pratica',
			},
			{
				dataField: 'durata',
				text: 'Durata',
			},
			{
				dataField: 'numeroAccessiErogati',
				text: 'N° accessi erogati',
			},
			{
				dataField: 'percorso',
				text: 'Percorso',
			},
		]
	},
	'accessi': {
		titolo: 'Reports accessi',
		columns: [
			{
				dataField: 'id',
				text: 'ID',
				hidden: true,
			},
			{
				dataField: 'dataAccesso',
				text: 'Data accesso',
				formatter: dateFormatter,
			},
			{
				dataField: 'assistito',
				text: 'Assistito',
				dataNode: `
					nomeAssistito
					cognomeAssistito
				`,
				formatter: (cell, row) => `${row.cognomeAssistito} ${row.nomeAssistito}`,
			},
			{
				dataField: 'codiceFiscaleAssistito',
				text: 'CF Assistito',
				hidden: true,
			},
			{
				dataField: 'codicePAI',
				text: 'Codice PAI',
			},
			{
				dataField: 'profiloAssistenzialePAI',
				text: 'Profilo assistenziale',
			},
			{
				dataField: 'dataInizio',
				text: 'Inizio',
				formatter: timeFormatter,
			},
			{
				dataField: 'dataFine',
				text: 'Fine',
				formatter: timeFormatter,
			},
			{
				dataField: 'operatore',
				text: 'Operatore',
				dataNode: `
					nomeOperatore
					cognomeOperatore
				`,
				formatter: (cell, row) => `${row.cognomeOperatore} ${row.nomeOperatore}`,
			},
			{
				dataField: 'codiceFiscaleOperatore',
				text: 'CF Operatore',
				hidden: true,
			},
			{
				dataField: 'qualificaOperatore',
				text: 'Qualifica',
			},
			{
				dataField: 'codiceAffidamento',
				text: 'Codice',
				hidden: true,
			},
			{
				dataField: 'prestazione',
				text: 'Prestazione',
				hidden: true,
			},
			{
				dataField: 'erogatore',
				text: 'Pattante PAI',
				hidden: true,
			},
			{
				dataField: 'codiceRichiesta',
				text: 'Codice richiesta',
				hidden: true,
			},
			{
				dataField: 'modalitaDiCura',
				text: 'Modalita di cura',
				hidden: true,
			},
		]
	},
	'diario': {
		titolo: 'Reports diario',
		columns: [
			{
				dataField: 'id',
				text: 'ID',
				hidden: true,
			},
			{
				dataField: 'nomeAssistito',
				text: 'Nome',
			},
			{
				dataField: 'cognomeAssistito',
				text: 'Cognome',
			},
			{
				dataField: 'codiceFiscaleAssistito',
				text: 'Codice Fiscale',
			},
			{
				dataField: 'dataNascitaAssistito',
				text: 'Data di nascita',
				formatter: dateFormatter,
			},
			{
				dataField: 'codiceCartella',
				text: 'Codice cartella',
			},
			{
				dataField: 'data',
				text: 'Data',
				formatter: dateFormatter,
			},
			{
				dataField: "tipo",
				text: "Tipo",
			},
			{
				dataField: "descrizione",
				text: "Descrizione",
			},
			{
				dataField: "operatore",
				text: "Operatore",
			},
			{
				dataField: "tipoOperatore",
				text: "Tipo Oper.",
			},
		]
	}
}

const Reports = () => {
    const { tipo = 'diario' } = useParams();
	const { domain, domains, apiVersion = 1 } = useContext(DataContext);
	const { downloadFile } = useFileRequest({ endpoint: '' });
	const { base, xAPIKey } = domains[domain];
    const [currentFilters, setCurrentFilters] = useState<ReportsFilters>({
        anno: `${new Date().getFullYear()}`,
        mese: `${new Date().getMonth() + 1}`,
    });
	const [isDownloading, setIsDownloading] = useState(false);
    const { useRestRequest } = useDataRequest();
    const [reqList, resList] = useRestRequest({
        path: '@later',
        method: 'GET',
        jwt: true,
    });

	const path = useMemo(() => {
        const { anno, mese } = currentFilters;
		return `api/{ver}/cartellasociosanitaria/report/${tipo}/${anno}/${mese}`;
	}, [tipo, currentFilters]);
	const tipoReport = TIPI_REPORTS[tipo];

    useEffect(() => {
        reqList({
            path,
        });
    }, [path]);

	const downloadExport = useCallback(async () => {
        const { anno, mese } = currentFilters;
		setIsDownloading(true);
		await downloadFile({
			path: `api/{ver}/cartellasociosanitaria/report/${tipo}/${anno}/${mese}?export=excel`,
			fileName: 'sample-test.xls',
		});
		setIsDownloading(false);
	}, [currentFilters]);

    const rows = useMemo(() => {
        return _get(resList, 'data', []);
    }, [resList]);
    
    return <>
		<Stack direction="horizontal" gap={3} className='mb-3'>
			<h2>{tipoReport.titolo}</h2>
			<div>
				<Button
					variant="success"
					onClick={downloadExport}
				>
					{isDownloading ? 'Esportazione...' : 'Esporta in Excel'}
				</Button>
			</div>
		</Stack>
		<Accordion className="mb-3" activeKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header>Ricerca</Accordion.Header>
				<Accordion.Body>
					<FormViewer
						schema={$filterSchema as any}
						initialValues={currentFilters || {}}
						onSubmit={(args) => {
							const { values, form } = args;
							setCurrentFilters(values as ReportsFilters);
						}}
						slots={{
							ButtonBar: (args) => (
								<>
									<div className="d-flex justify-content-end flex-row bd-highlight gap-3">
										<Button
											variant="secondary"
											onClick={() => {
												args.form.resetForm({});
												args.form.submitForm();
											}}
										>
											Annulla filtro
										</Button>
										<Button variant="primary" type="submit">
											Applica
										</Button>
									</div>
								</>
							),
						}}
					/>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
        {rows.length === 0 && <div className='empty'>Nessun report disponibile</div>}
        {rows.length > 0 && <>
            <FilterTable
                data={rows}
                columns={tipoReport.columns}
                rows={rows}
				hideEditColumns
            />
        </>}
    </>
}


export default Reports;