import React, { Component } from "react";
import BandwidthLineChart from "./components/BandwidthLineChart";
import BandwidthPieChart from "./components/BandwidthPieChart";
import ApiLineChart from "./components/ApiLineChart";
import ApiPieChart from "./components/ApiPieChart";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import SimpleCard from "src/@bootstrap/template/@gull/components/cards/SimpleCard";
import { Dropdown } from "react-bootstrap";

class AdminDashboard extends Component {
  state = {
    cardList1: [
      {
        icon: "i-Flag-2",
        title: "205",
        subtitle: "customers",
      },
      {
        icon: "i-At-Sign",
        title: "4021",
        subtitle: "accounts",
      },
      {
        icon: "i-Suitcase",
        title: "2",
        subtitle: "products",
      },
      {
        icon: "i-Money-2",
        title: "120",
        subtitle: "expense",
      },
    ],
    eventsLog: [
      {
        type: "danger",
        product: "SISS",
        module: "Anagrafica",
        message: "object not fuond",
        time: "01/11/21 11:12",
      },
      {
        type: "warning ",
        product: "SISS",
        module: "Anagrafica",
        message: "object not fuond",
        time: "01/11/21 11:12",
      },
      {
        type: "light",
        product: "SISS",
        module: "Anagrafica",
        message: "object not fuond",
        time: "01/11/21 11:12",
      },
      {
        type: "danger",
        product: "SISS",
        module: "Anagrafica",
        message: "object not fuond",
        time: "01/11/21 11:12",
      },
    ],
  };

  getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  render() {
    let {
      cardList1 = [],
      eventsLog = [],
    } = this.state;

    return (
      <div>
        <Breadcrumb
          routeSegments={[
            { name: "Dashboard", path: "dashboard/admin/DashboardAdmin" },
            { name: "Admin" }
          ]}
        ></Breadcrumb>
        <div className="row">
          {cardList1.map((card, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-6">
              <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                <div className="card-body text-center">
                  <i className={card.icon}></i>
                  <div className="content">
                    <p className="text-muted mt-2 mb-0 text-capitalize">
                      {card.subtitle}
                    </p>
                    <p className="lead text-primary text-24 mb-2 text-capitalize">
                      {card.title}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-12">
            <SimpleCard title="30 days bandwidth usage (GB)" className="mb-4">
              <BandwidthLineChart height="260px"></BandwidthLineChart>
            </SimpleCard>
          </div>
          <div className="col-lg-4 col-sm-12">
            <SimpleCard title="Bandwidth usage by Customers" className="mb-4">
              <BandwidthPieChart height="260px"></BandwidthPieChart>
            </SimpleCard>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8 col-md-12">
            <SimpleCard title="30 days api usage (GB)" className="mb-4">
              <ApiLineChart height="260px"></ApiLineChart>
            </SimpleCard>
          </div>
          <div className="col-lg-4 col-sm-12">
            <SimpleCard title="Api usage by Customers" className="mb-4">
              <ApiPieChart height="260px"></ApiPieChart>
            </SimpleCard>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-header card-title mb-0 d-flex align-items-center justify-content-between border-0">
                <h3 className="w-50 float-left card-title m-0">
                  Events log
                </h3>
                <Dropdown>
                  <Dropdown.Toggle
                    as="span"
                    className="toggle-hidden cursor-pointer"
                  >
                    <i className="nav-icon i-Gear-2"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>View only errors</Dropdown.Item>
                    <Dropdown.Item>Go to details</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className="">
                <div className="table-responsive">
                  <table id="user_table" className="table  text-center">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Event Type</th>
                        <th scope="col">Product</th>
                        <th scope="col">Module</th>
                        <th scope="col">Message</th>
                        <th scope="col">Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {eventsLog.map((event, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td><span
                              className={`badge bg-${event.type}`}
                            >{event.type}</span></td>
                          <td>{event.product}</td>
                          <td>{event.module}</td>
                          <td>{event.message}</td>
                          <td>{event.time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default AdminDashboard;
