import _get from 'lodash/get';
import { useCallback, useContext } from 'react';
import { isMobile } from 'react-device-detect'
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { useDataDecorator } from "../../helpers/data";
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';

export const useModule = (options: any) => {
	const list = `struttureServiziQuery.percorsi`;
	const mutation = "struttureServizi.percorsi";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
	const dataList = useDataDecorator('percorsi', options.parent).map((r: any) => ({...r, _label: r.descrizione}));

	var schema = require('./schema/edit/percorso.json');

	const BASE = `api/{ver}/struttureservizi/servizi/${options.parent.uniqueId}/percorsi`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
                servizioUniqueId: options.parent.uniqueId,
                dto: {
                    ...values,
                },
            }
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, []);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, []);

	return {
		graphql: {
			list,
            mutation,
        },
		actions: {
			save,
			remove,
		},
		results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		extra: {
			options: {
				parent: options.parent,
				onCreateInitialValues: {
					servizioUniqueId: options.parent.uniqueId,
				},
				modalSize: 'sm',
				closeWhenSaved: true,
			},
		},
		dataList,
		table: {
			columns: [
				{
					dataField: 'uniqueId',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: 'nome',
					text: 'Nome',
				},
				{
					dataField: 'codice',
					text: 'Codice',
					hidden: isMobile,
				},
				{
					dataField: 'descrizione',
					text: 'Descrizione',
					hidden: isMobile,
				},
			],
		},
	};
}
