import { gql } from "@ai4/data-request";

export const GET_SERVIZIO = gql`
    query getServizio($id: Guid!) {
        struttureServiziQuery {
            servizi {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    percorsi {
                        uniqueId
                        metadata {
                            insertDate
                            updateDate
                        }
                        codice
                        nome
                        descrizione
                    }
                    codice
                    descrizione
                    nome
                }
            }
        }
    }
`;