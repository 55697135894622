import { gql } from "@ai4/data-request";

export const GET_AFFIDAMENTO = gql`
    query getAffidamento($id: Guid!) {
        cartellaSocioSanitariaQuery {
            affidamenti {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    intervento {
                        uniqueId
                    }
                    codice
                    dataChiusura
                    motivoChiusura {
                        uniqueId
                    }
                    motivoChiusuraAltro
                }
            }
        }
    }
`;