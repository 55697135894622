const getSchema = () => (

  /*** JSON */

  {
    "defaultActiveTabId": "Titolo",
    "id": "root",
    "type": "tabs",
    "tabs": [
      {
        "id": "Titolo",
        "title": "Titolo",
        "rows": [
          {
            "id": "columns.evdw04uxn",
            "type": "columns",
            "defaultSizes": [
              6,
              6
            ],
            "columns": [
              {
                "id": "column.78lewb2bf",
                "name": "dataInizio",
                "type": "field",
                "fieldType": "date",
                "label": "Inizio",
                "props": {}
              },
              {
                "id": "column.294585suz",
                "name": "dataFine",
                "type": "field",
                "fieldType": "date",
                "label": "Fine",
                "props": {}
              }
            ]
          },
          {
            "id": "columns.e9mgayhcv",
            "type": "columns",
            "defaultSizes": [
              12
            ],
            "columns": [
              {
                "id": "column.fswqtfpsw",
                "name": "motivoSospensioneUniqueId",
                "type": "field",
                "fieldType": "select",
                "label": "Motivo sospensione",
                "props": {}
              },
              {
                "id": "column.9oxqijw1a"
              }
            ]
          },
          {
            "id": "columns.e628zh1wp",
            "type": "columns",
            "defaultSizes": [
              12,
              6
            ],
            "columns": [
              {
                "id": "column.yrgmj6vow",
                "name": "note",
                "type": "field",
                "fieldType": "textarea",
                "label": "Note",
                "props": {}
              }
            ]
          }
        ]
      }
    ]
  }

  /*** JSON */

)

export default getSchema;