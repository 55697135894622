import _get from 'lodash/get';
import _set from 'lodash/set';
import { useCallback, useEffect, useContext, useMemo } from "react";
import { isMobile } from 'react-device-detect';
import { Formatters } from "@ai4/records-management";
import { useDataRequest } from "@ai4/data-request";
import {
	useFormBuilderSchemaLoader,
	useSelectItemsPopulate,
} from "../../helpers/data";
import { pathToSlug } from 'src/app/helpers/helpers';
import { useAnagraficaModule, useAnagraficaSelectItemsPopulate, useChangeComuniByProvincia, useFillTabs, _assoc } from './common';
import EditOperatore from './editOperatore';
import { GET_OPERATORE } from './gql/operatori';

export const useModule = () => {
	const list = 'struttureServiziQuery.operatori';
	const $common = useAnagraficaModule({
		module: 'struttureservizi',
		entity: 'operatori',
		graphql: { list: pathToSlug(list), details: GET_OPERATORE },
		lists_servizi: ['qualificaOperatore']
	});
	const mutation = "anagrafica.operatori";
	const { useLazyQuery } = useDataRequest();
	const query = useLazyQuery(GET_OPERATORE);
	const [execDetails, { data: dataDetails, loading }] = query;

	// build schema	
	var schema = useFormBuilderSchemaLoader('presets.operatore');
	schema = useFillTabs(schema, { 'anagrafica': ['anagrafica'], 'studiolavoro': ['studiolavoro-operatore'], 'contatti': ['contatti'], 'sanita': false });
	schema = useAnagraficaSelectItemsPopulate({ schema, related: $common.related });
	schema = useSelectItemsPopulate(schema, $common.related, {
		'qualificaOperatoreUniqueId': [
			..._get($common.related.data, "struttureServiziQuery.elenchiStruttureServizi.qualificaOperatore.list", []).map(_assoc),
			{ listManagement: { name: 'QualificaOperatore', query_name: 'getRelatedAnagrafica' } },
		],
	});
	const { onChangeProvincia } = useChangeComuniByProvincia();

	const onChange = (args: any) => {
		const {
			values,
			schema,
			getSchemaNode,
			setSchemaNode,
		} = args;
		['datiCorrispondenza'].forEach(ns => {
			const flag = _get(values, `${ns}.coincideConResidenza`);
			['denominazione', 'note', 'luogo.nazioneUniqueId', 'luogo.provinciaUniqueId', 'luogo.comuneUniqueId', 'luogo.comuneEstero', 'luogo.localita', 'luogo.cap', 'luogo.note'].forEach(k => {
				const name = `${ns}.${k}`;
				const node = getSchemaNode(name);
				setSchemaNode(name, {
					...node,
					hidden: flag,
				});
			});
		});
		['datiNascita', 'datiResidenza.luogo', 'datiCorrispondenza.luogo'].forEach(ns => {
			onChangeProvincia({ ns, ...args })
		});
	};

	const { schema: formSchema, ...gqlFilters } = $common.filters;
	return {
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
			...gqlFilters,
		},
		actions: {
			...$common.crud.actions,
		},
		results: {
			...$common.crud.results,
		},
		schema,
		dataDetails,
		extra: {
			slots: {
				edit: EditOperatore,
			},
		},
		methods: {
		  onFormInit: onChange,
		  onFormChange: onChange,
		},
		filters: {
			schema: formSchema,
		},
		table: {
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "datiAnagrafici.nome",
					text: "Nome",
				},
				{
					dataField: "datiAnagrafici.cognome",
					text: "Cognome",
				},
				{
					dataField: "datiAnagrafici.codiceFiscale",
					text: "Codice fiscale",
					hidden: isMobile,
				},
				{
					dataField: "datiAnagrafici.sesso",
					text: "Sesso",
					hidden: isMobile,
				},
				{
					dataField: 'datiAnagrafici.statoCivile.descrizione',
					text: 'Stato Civile',
					sort: true,
					hidden: true,
					dataNode: `datiAnagrafici {
						statoCivile { descrizione }
					}`,
				},
				{
					dataField: "datiNascita.data",
					text: "Data di nascita",
					formatter: Formatters.dateFormatter,
					sort: true,
					hidden: isMobile,
				},
				{
					dataField: 'datiNascita.comune.descrizione',
					text: 'Comune di nascita',
					sort: true,
					hidden: true,
					dataNode: `datiNascita {
						comune { descrizione }
					}`,
				},
				{
					dataField: 'datiResidenza.luogo.comune.descrizione',
					text: 'Comune di residenza',
					sort: true,
					hidden: true,
					dataNode: `datiResidenza {
						luogo { comune { descrizione } }
					}`,
				},
			],
		},
	};
};