import _get from 'lodash/get';
import React, { useCallback, useContext, useMemo } from "react";
import { DataContext, gql, useDataRequest, useFormViewer } from "@ai4/data-request";
import { useDataDecorator } from 'src/app/helpers/data';
import { GET_PROGETTO_PAI } from '../gql/progettoPai';

export const useModule = (options: any) => {
    const { parent, parent_type } = options || {};
    const list = 'cartellaSocioSanitariaQuery.progetti';
    const mutation = "cartelle.progetti.piano-assistenziale";
	const { domain, getClient } = useContext(DataContext);
    const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
    const query = useLazyQuery(GET_PROGETTO_PAI);
	const [execDetails, { data: dataDetails, loading }] = query;
    const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const dataList = useDataDecorator("pianoAssistenziale", parent);

    const BASE = `api/{ver}/cartellasociosanitaria/cartelle/${_get(options.parent, 'cartella.uniqueId')}/progetti/${_get(options.parent, 'uniqueId')}`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: values,
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [options.graphql.details],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [options.graphql.details],
            });
        }
	}, [BASE]);

    return {
        graphql: {
            list,
            mutation,
            loading,
			details: execDetails,
        },
        actions: {
            save,
			remove,
        },
        results: {
            save: resSave,
            remove: resDelete,
        },
        dataList,
        table: {
            columns: [
                {
                    dataField: "uniqueId",
                    text: "ID",
                    hidden: true,
                },
                {
                    dataField: "didascalia",
                    text: "Didascalia",
                },
            ],
        },
        extra: {
            options: {
                onCreateInitialValues: { [`${parent_type}UniqueId`]: parent.uniqueId },
                onEditValues: { [`${parent_type}UniqueId`]: parent.uniqueId },
                modalSize: "sm",
            },
        },
    };
};
