import { gql } from "@ai4/data-request";
import { ANAGRAFICA_FIELDS, LUOGO_FRAGMENT } from "./anagrafica";

export const GET_OPERATORE = gql`
    query getOperatore($id: Guid!) {
        struttureServiziQuery {
            operatori {
                list (where: { uniqueId: { _eq: $id } }) {
                    ${ANAGRAFICA_FIELDS}
                    qualificaOperatore {
                        uniqueId
                        descrizione
                    }
                    qualificaOperatoreAltro
                    note
                    contratti {
                        uniqueId
                        dataAttivazione
                        dataScadenza
                        stato
                        note
                        erogatore {
                            uniqueId
                            nome
                        }
                        operatore {
                            uniqueId
                            datiAnagrafici {
                                nome
                                cognome
                            }
                        }
                        tipologiaContrattoOperatore {
                            uniqueId
                            descrizione
                        }
                        tipologiaAltro
                    }
                }
            }
        }
    }

    ${LUOGO_FRAGMENT}
`;