import _get from 'lodash/get';
import _set from 'lodash/set';
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { pathToSlug } from 'src/app/helpers/helpers';
import { _assoc } from '../anagrafiche/common';
import EditPrestazione from './edit/prestazione';
import { GET_PRESTAZIONE } from './gql/prestazioni';
import { useCallback, useContext } from 'react';

export const useModule = () => {
	const list = 'struttureServiziQuery.prestazioni';
	const mutation = "struttureServizi.prestazioni";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const query = useLazyQuery(GET_PRESTAZIONE);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	// build schema	
	var schema = require('./schema/edit/prestazione.json');

	const BASE = `api/{ver}/struttureservizi/prestazioni`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				...values,
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, [BASE]);
	
	return {
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
		},
		actions: {
			save,
			remove,
		},
		results: {
            save: resSave,
            remove: resDelete,
		},
		schema,
		dataDetails,
		extra: {
			slots: {
				edit: EditPrestazione,
			},
		},
		table: {
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "codice",
					text: "Codice",
				},
				{
					dataField: "nome",
					text: "Nome",
				},
			],
		},
	};
};