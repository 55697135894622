import _get from 'lodash/get';
import _set from 'lodash/set';
import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext, gql, useDataRequest } from "@ai4/data-request";
import { SchemaTypes } from '@ai4/form-viewer';
import { Formatters } from '@ai4/records-management';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import { getQueryParam } from 'src/@bootstrap/helpers';
import {
	useFormBuilderSchemaLoader,
	useHideNodesSchema,
	useSelectItemsPopulate,
} from "../../../helpers/data";
import { GET_CARTELLA } from '../gql/cartella';
import $filters from '../schema/filters/cartelle.json';
import { _assoc } from '../../anagrafiche/common';
import { ConfigContext } from 'src/@bootstrap/Config';

const RELATED = gql`
    query getRelatedCartelle {
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				tipologiaProgetto {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			erogatori {
				list {
					uniqueId
					nome
				}
			}
		}
	}
`;

export const useModule = (options: any) => {
	const { filters } = options || {};
	const list = 'cartellaSocioSanitariaQuery.cartelle';
	const mutation = "cartella.cartelle";
	const { domain, getClient } = useContext(DataContext);
	const navigate = useNavigate();
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED);
	const query = useLazyQuery(GET_CARTELLA);
	const [execDetails, { data: dataDetails, loading }] = query;
	
    const filterSchema = useSelectItemsPopulate(
		$filters as any,
		related, {
			'erogatoreUniqueId': _get(related.data, "struttureServiziQuery.erogatori.list", []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
		}
	);
	
	return {
		noPagination: true,
		titleGenerator: `Pratiche individuali SGDT`,
		graphql: {
			list,
			mutation,
			loading,
			details: execDetails,
			queryWithVars: () => {
				return `${pathToSlug(list)} (
					$nome: String
					$cognome: String
					$codiceFiscale: String
					$erogatoreUniqueId: String
				)`
			},
			listFilters: () => {
                return `list(
                    uniqueIdErogatore: $erogatoreUniqueId
                    nomeAssistito: $nome
                    cognomeAssistito: $cognome
                    codiceFiscaleAssistito: $codiceFiscale
					order: {
						dataAttivazione: DESC
					}
                )`;
            },
		},
		actions: {
			list: {
				onSelectedRow: (row) => {
					navigate(`/cartelle/dettaglio/${row.uniqueId}`);
				},
			},
		},
		results: {},
		features: {
			creation: false
		},
		dataDetails,
		filters: {
			schema: filterSchema,
		},
		table: {
			dateNascoste: true,
			columns: [
				{
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "codice",
					text: "Codice cartella",
				},
				{
					dataField: "schedaPresaInCarico.erogatore.nome",
					text: "Erogatore",
					dataNode: `schedaPresaInCarico {
						erogatore {
							nome
						}
					}`
				},
				{
					dataField: "assistito.datiAnagrafici.nome",
					text: "Nome",
					dataNode: `assistito {
						datiAnagrafici {
							nome
							cognome
							codiceFiscale
						}
					}`
				},
				{
					dataField: "assistito.datiAnagrafici.cognome",
					text: "Cognome",
					noData: true,
				},
				{
					dataField: "assistito.datiAnagrafici.codiceFiscale",
					text: "CF",
					noData: true,
				},
				{
					dataField: 'metadata.insertDate',
					text: 'Inserito il',
					formatter: Formatters.dateFormatter
				},
			],
		},
	};
};