import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo } from "react";
import { SchemaTypes, traverseSchema } from "@ai4/form-viewer";
import {
  gql,
  useAuthRequest,
  useDataRequest,
  useFormViewer,
} from "@ai4/data-request";
import { textFilter } from "@ai4/react-bootstrap-table2-filter";
import $schema from "./demo.schema.json";
import { isMobile } from "react-device-detect";
import { Formatters } from "@ai4/records-management";
let schema = $schema as SchemaTypes.Schema;

const QUERY = gql`
  query getTipologieMedici {
		anagraficaQuery {
      elenchiAnagrafica {
        tipologiaMedico {
          list {
            uniqueId
            codice
            descrizione
          }
        }
      }
    }
	}
`;

const SAVE = gql`
  mutation addBilanci($data: BilanciInput) {
    bilanciMutation {
      bilanci {
        save(bilancio: $data) {
          uniqueId
        }
      }
    }
  }
`;

const DELETE = gql`
  mutation deleteBilanci($uniqueId: String) {
    bilanciMutation {
      bilanci {
        delete(uniqueId: $uniqueId)
      }
    }
  }
`;

interface Props {}

export const useModule = () => {
	const list = 'anagraficaQuery.medici';
	const mutation = "tenant";
  
  const { useQuery, useMutation } = useDataRequest();
  const { data, refetch } = useQuery(QUERY);
  const [execSave, resSave] = useMutation(SAVE, {
    refetchQueries: [{ query: QUERY }],
  });
  const [execDelete, resDelete] = useMutation(DELETE, {
    refetchQueries: [{ query: QUERY }],
  });

  const { transformData } = useFormViewer({});

  const save = useCallback(async (values) => {
    const { uniqueId } = values;

    await execSave({
      variables: {
        data: {
          ...transformData(values, {
            uniqueId: true,
            categoriaUniqueId: true,
            sottoCategoriaUniqueId: true,
            enteDiPubblicazioneUniqueId: true,
            titolo: true,
            descrizione: true,
            data: "date",
          }),
          documentoUniqueId: _.get(values, "documento.uniqueId"),
        },
      },
    });
    return values;
  }, []);

  const remove = useCallback(async (values) => {
    const { uniqueId, name } = values;
    const ret = await execDelete({
      variables: {
        uniqueId: uniqueId,
      },
    });
  }, []);

  /*
  const rows = useMemo(() => {
    return _.get(data, "bilanciQuery.bilanci.list", []).map((row: any) => {
      return {
        ...row,
        categoriaUniqueId: _.get(row, "categoria.uniqueId"),
      };
    });
  }, [data]);
  */

  schema = useMemo(() => {
    if (!data) return schema;
    return traverseSchema(schema, (n: SchemaTypes.SchemaNode) => {
      if (n.id && n.id === "categoriaUniqueId")
        return {
          ...n,
          props: {
            items: _.get(data, "anagraficaQuery.elenchiAnagrafica.tipologiaMedico.list", []).map(
              (cat: any) => ({ text: cat.descrizione, value: cat.uniqueId })
            ),
          },
        };
      return n;
    });
  }, [schema, data]);

  return {
    graphql: {
			list,
			mutation,
		},
    // data: rows,
    schema,
    actions: {
      save,
      remove,
    },
    results: {
      save: resSave,
      remove: resDelete,
    },
    table: {
      columns: [
        {
					dataField: "uniqueId",
					text: "ID",
					hidden: true,
				},
				{
					dataField: "datiAnagrafici.nome",
					text: "Nome",
				},
				{
					dataField: "datiAnagrafici.cognome",
					text: "Cognome",
				},
				{
					dataField: "datiAnagrafici.codiceFiscale",
					text: "Codice fiscale",
					hidden: isMobile,
				},
				{
					dataField: "datiAnagrafici.sesso",
					text: "Sesso",
					hidden: isMobile,
				},
				{
					dataField: 'datiAnagrafici.statoCivile.descrizione',
					text: 'Stato Civile',
					sort: true,
					hidden: true,
					dataNode: `datiAnagrafici {
						statoCivile { descrizione }
					}`,
				},
				{
					dataField: "datiNascita.data",
					text: "Data di nascita",
					formatter: Formatters.dateFormatter,
					sort: true,
					hidden: isMobile,
				},
				{
					dataField: 'datiNascita.comune.descrizione',
					text: 'Comune di nascita',
					sort: true,
					hidden: true,
					noData: true,
					dataNode: `datiNascita {
						comune { descrizione }
					}`,
				},
				{
					dataField: 'datiResidenza.luogo.comune.descrizione',
					text: 'Comune di residenza',
					sort: true,
					hidden: true,
					dataNode: `datiResidenza {
						luogo { comune { descrizione } }
					}`,
				},
      ],
      metaDataPrefix: 'metadata',
    },
  };
};
