const events = [
    getEvent("Rossi Giuseppe\nFisiotearpia", 18, 9),
    getEvent("Rossi Giuseppe\nFisiotearpia", 17, 9),
    getEvent("Rossi Giuseppe\nFisiotearpia", 16, 9),
    getEvent("Rossi Giuseppe\nFisiotearpia", 15, 9),
    getEvent("Rossi Giuseppe\nFisiotearpia", 14, 9),

    getEvent("Angela Azzurri\nFisiotearpia", 18, 15),
    getEvent("Angela Azzurri\nFisiotearpia", 16, 15),
    getEvent("Angela Azzurri\nFisiotearpia", 14, 15),

    getEvent("Michele Neri\nFisiotearpia", 17, 11),
    getEvent("Michele Neri\nFisiotearpia", 15, 11),
    getEvent("Michele Neri\nFisiotearpia", 13, 11),
  ];
  
  function getEvent(name, day, hour) {
    return {title: name, start: new Date(2022, 2, day, hour), end: new Date(2022, 2, day, hour+1)};
  }
  
  export default events;
  