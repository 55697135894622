import { gql } from "@ai4/data-request";
import { ANAGRAFICA_FIELDS, LUOGO_FRAGMENT } from "./anagrafica";

export const GET_MEDICO = gql`
    query getMedico($id: Guid!) {
        anagraficaQuery {
            medici {
                list (where: { uniqueId: { _eq: $id } }) {
                    ${ANAGRAFICA_FIELDS}
                    codice
                    tipologiaMedico {
                        uniqueId
                        descrizione
                    }
                    sedi {
                        nazione {
                            uniqueId
                            descrizione
                        }
                        provincia {
                            uniqueId
                            descrizione
                        }
                        comune {
                            uniqueId
                            descrizione
                        }
                        tipologiaSede {
                            uniqueId
                            descrizione
                        }
                        comuneEstero
                        localita
                        cap
                        note
                    }
                }
            }
        }
    }

    ${LUOGO_FRAGMENT}
`;