import _get from 'lodash/get';
import React, { useCallback, useContext, useMemo, useState } from "react";
import { DataContext, gql, useDataRequest, useFormViewer } from "@ai4/data-request";
import { useHideNodesSchema, useSelectItemsPopulate } from 'src/app/helpers/data';
import { Formatters } from '@ai4/records-management';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { _assoc } from '../../anagrafiche/common';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import { GET_ACCESSO_DIRETTO } from '../gql/accessoDiretto';
import { useAccessoAnnullamento } from '../../accessi/common';
import ViewAccessoDiretto from '../view/accessoDiretto';
import getSchema from '../schema/edit/accessoDiretto';
import { useCanEditAccesso } from '../../accessi/Calendario';

const RELATED = gql`
	query getRelatedAccessoDiretto($cartellaUniqueId: String) {
        struttureServiziQuery {
            prestazioni {
                list {
                    uniqueId
                    nome
                }
            }
            operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
				}
			}

        }
		cartellaSocioSanitariaQuery {
            progettiPai {
                list(
                    cartellaUniqueId: $cartellaUniqueId
                ) {
                    codice
                    interventi {
                        affidamenti {
                            uniqueId
                        }
                    }
                }
            }
			elenchiCartellaSocioSanitaria {
                tipologiaAccessoDiretto {
					list {
						uniqueId
						descrizione
					}
				}
				motivoNonEsecuzione {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

export const useModule = (options: any) => {
    const canEdit = useCanEditAccesso();
    const [inAnnullamento, setInAnnullamento] = useState<any>();
    const { parent, parent_type } = options || {};
    const list = 'cartellaSocioSanitariaQuery.accessiDiretti';
    const mutation = "cartelle.accessiDiretti";
	const { domain, getClient } = useContext(DataContext);
    const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(RELATED, { variables: { cartellaUniqueId: parent_type === 'affidamento' ? undefined : parent.uniqueId }});
    const query = useLazyQuery(GET_ACCESSO_DIRETTO);
	const [execDetails, { data: dataDetails, loading }] = query;
    const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const { annullamentoModale } = useAccessoAnnullamento({
        record: inAnnullamento,
        onSuccess: async () => {
            if (getClient) {
                await getClient(domain).refetchQueries({
                    include: [pathToSlug(list)],
                });
            }
        },
        onCancel: () => setInAnnullamento(undefined),
    });

	const schema = useHideNodesSchema(useSelectItemsPopulate(
		getSchema() as Schema,
        related, {
            affidamentoUniqueId: _get(related.data, 'cartellaSocioSanitariaQuery.progettiPai.list', []).map((pai: any) => { return { text: _get(pai, 'codice'), value: _get(pai, 'interventi[0].affidamenti[0].uniqueId') }}),
            operatoreErogazioneUniqueId: _get(related.data, 'struttureServiziQuery.operatori.list', []).map((cat: any) => ({ text: `${_get(cat, 'datiAnagrafici.nome')} ${_get(cat, 'datiAnagrafici.cognome')}`, value: cat.uniqueId })),
            tipologiaAccessoDirettoUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.tipologiaAccessoDiretto.list', []).map(_assoc),
                { listManagement: { name: 'TipologiaAccessoDiretto', query_name: 'getRelatedAccessoDiretto' } },
            ],
            prestazioniErogate: _get(related.data, 'struttureServiziQuery.prestazioni.list', []).map((cat: any) => ({ text: cat.nome, value: cat.uniqueId })),
        }
	), options.parent_type === 'cartella' ? [] : ['affidamentoUniqueId']);
    
	const save = useCallback(async (values) => {
		const { uniqueId, affidamentoUniqueId = _get(options.parent, 'uniqueId') } = values;
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi/diretto`;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: values,
                affidamentoUniqueId,
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, []);

	const remove = useCallback(async (values) => {
		const { uniqueId, affidamentoUniqueId } = values;
        const BASE = `api/{ver}/cartellasociosanitaria/affidamenti/${affidamentoUniqueId}/accessi/diretto`;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, []);

    return {
        graphql: {
            list,
            mutation,
            loading,
			details: execDetails,
            listFilters: () => {
                const listArgs = () => {
                    if (options.parent_type === 'affidamento') {
                        return `affidamentoUniqueId: "${options.parent.uniqueId}",`;
                    }
                    if (options.parent_type === 'cartella') {
                        return `cartellaUniqueId: "${options.parent.uniqueId}",`;
                    }
                    return ``;
                }
                return `pagedList (
                    ${listArgs()}
                    stati: ["Eseguito"]
                    order: {
                        dataOreInizioErogazione: DESC
                    }
                    pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                )`;
            },
        },
        actions: {
            save,
			remove,
			details: {
				onLoadDetails: (item) => item ? ({
					...item,
					prestazioniErogate: _get(item, 'prestazioniErogate', []).map(i => _get(i, 'prestazione.uniqueId')),
				}) : undefined,
			},
        },
		features: {
			creation: canEdit,
		},
        results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		dataDetails,
        table: {
            columns: [
                {
                    dataField: "uniqueId",
                    text: "ID",
                    hidden: true,
                    dataNode: `
                        uniqueId
                        affidamento {
                            uniqueId
                        }
                    `
                },
                {
                    dataField: "operatoreErogazione",
                    text: "Operatore",
                    formatter: (cell, row) => `${_get(cell, 'datiAnagrafici.nome')} ${_get(cell, 'datiAnagrafici.cognome')}`,
                    dataNode: `
                        operatoreErogazione {
                            datiAnagrafici {
                                nome
                                cognome
                            }
                        }
                    `
                },
                {
                    dataField: "tipologiaAccessoDiretto.descrizione",
                    text: "Tipologia",
                },
                {
                    dataField: "prestazioniErogate",
                    text: "Prestazioni",
                    formatter: (cell, row) => `${_get(row, 'prestazioniErogate', []).map(p => _get(p, 'prestazione.nome')).join(', ')}`,
                    dataNode: `
                        prestazioniErogate {
                            prestazione {
                                uniqueId
                                nome
                            }
                        }
                    `
                },
                {
                    dataField: "dataOreInizioErogazione",
                    text: "Data inizio",
                    formatter: Formatters.dateFormatter
                },
                {
                    dataField: "dataOreFineErogazione",
                    text: "Data fine",
                    formatter: Formatters.dateFormatter
                },
            ],
            dateNascoste: true,
        },
        extra: {
			slots: {
				form: canEdit ? undefined : ViewAccessoDiretto,
			},
            options: {
                onCreateInitialValues: { [`${parent_type}UniqueId`]: parent.uniqueId },
                onEditValues: { [`${parent_type}UniqueId`]: parent.uniqueId },
				closeWhenSaved: true,
            },
            checkFeature: (feature, row) => {
                return canEdit || feature === 'edit';
            },
        },
        additionalComponent: inAnnullamento ? annullamentoModale : undefined,
    };
};
