import React from "react";
import PermissionsList from './list';

export function PermissionsGroups(props) {

  const rows = props.functionGroups;
  const filterByRoleId = props.filterByRoleId;
  const onRemovePermission = props.onRemovePermission;
  const onAddPermission = props.onAddPermission;

  const showAddButton = props.showAddButton;
  const showRemoveButton = props.showRemoveButton;

  const getFunctionsFilteredByGroupId = (functions, roleId) => {
    let filteredFunctions = [];
    for (let i = 0; i < functions.length; i++) {
      for (let r = 0; r < functions[i].roles.length; r++) {
        if (roleId === functions[i].roles[r].id) {
          filteredFunctions.push(functions[i]);
        }
      }
    }
    return filteredFunctions;
  }

  return (
    <div>

      {rows.map(group => {

        let functions = filterByRoleId ? getFunctionsFilteredByGroupId(group.functions, filterByRoleId) : [...group.functions];

        return (
          <div key={group.id}>
            <h4>{group.name}</h4>
            <div>
              {functions.length > 0 ? <PermissionsList
                functionsList={functions}
                onRemovePermission={onRemovePermission}
                onAddPermission={onAddPermission}
                showAddButton={showAddButton}
                showRemoveButton={showRemoveButton}>
              </PermissionsList> : <div style={{ marginBottom: '30px' }}>{"nessun permesso"}</div>}
            </div>
          </div>);
      })}

    </div>
  );


}

export default PermissionsGroups;

