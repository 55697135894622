
import productsDb from './products'
import anagraficheDb from './anagrafiche'

const accountsDb = {

    accessLog: [{
        id: 'ad345c44-5109-11ec-bf63-0242ac134442',
        username: 'giovanni.zuffolini',
        accessDate: '01/12/2021'
    }, {
        id: 'ad345c44-5109-11ec-bf63-0242ac134443',
        username: 'giovanni.zuffolini',
        accessDate: '15/11/2021'
    }, {
        id: 'ad345c44-5109-11ec-bf63-0242ac134444',
        username: 'giovanni.zuffolini',
        accessDate: '14/11/2021'
    }, {
        id: 'ad345c44-5109-11ec-bf63-0242ac134445',
        username: 'giovanni.zuffolini',
        accessDate: '10/11/2021'
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134442',
        username: 'mario.rossi',
        lastAccess: '01/12/2021'
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134442',
        username: 'mario.rossi',
        lastAccess: '28/11/2021'
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134442',
        username: 'mario.rossi',
        lastAccess: '25/11/2021'
    }]
}

accountsDb.accounts = [{
    id: 'ad345c44-5109-11ec-bf63-0242ac134442',
    username: 'giovanni.zuffolini',
    email: 'giovanni.zuffolini@pikkart.com',
    lastAccess: '01/01/2021',
    active: true,
    activeFrom: '01/01/2020',
    activeTo: '01/01/2022',
    licensedProducts: [{
        product: productsDb.products[0],
        profile: productsDb.products[0].permissionSchema.userProfiles[0],
    }]
}, {
    id: 'ad345c44-5109-11ec-bf63-0242ac134444',
    username: 'tizio.caio',
    email: 'tizio.caio@mailinator.com',
    lastAccess: '15/01/2021',
    active: true,
    activeFrom: '12/03/2020',
    activeTo: '12/03/2022',
    licensedProducts: [{
        product: productsDb.products[0],
        profile: productsDb.products[0].permissionSchema.userProfiles[0],
    }]
},
{
    id: 'ad345c44-5109-11ec-bf63-0242ac134443',
    username: 'mario.rossi',
    email: 'mario.rossi@mailinator.com',
    lastAccess: '01/01/2021',
    active: false,
    activeFrom: '01/01/2020',
    activeTo: '01/01/2022',
    licensedProducts: [{
        product: productsDb.products[0],
        profile: productsDb.products[0].permissionSchema.userProfiles[1],
        anagrafica: anagraficheDb.anagrafiche[1],
    }, {
        product: productsDb.products[1],
        profile: productsDb.products[1].permissionSchema.userProfiles[2],
        anagrafica: anagraficheDb.anagrafiche[1],
    }]
}];

export default accountsDb;