import _get from 'lodash/get';
import { useCallback, useContext } from 'react';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { SchemaTypes } from '@ai4/form-viewer';
import { Formatters } from '@ai4/records-management';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';
import { useSelectItemsPopulate } from "../../helpers/data";
import { GET_AFFIDAMENTO } from './gql/affidamento';
import Edit from './edit/affidamento';
import $filters from './schema/filters/affidamenti.json';
import { _assoc, _revid } from '../anagrafiche/common';
import { GET_CARTELLA_PROGETTI } from './gql/progettoPai';
const filterSchema = $filters as SchemaTypes.Schema;

export const AFFIDAMENTI_RELATED = gql`
	query getRelatedAffidamento {
		cartellaSocioSanitariaQuery {
			elenchiCartellaSocioSanitaria {
				motivoChiusuraAffidamento {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
		struttureServiziQuery {
			elenchiStruttureServizi {
				qualificaOperatore {
					list {
						uniqueId
						descrizione
					}
				}
			}
		}
	}
`;

export const useModule = (options: any) => {
	const list = `cartellaSocioSanitariaQuery.affidamenti`;
	const mutation = "cartelle.affidamenti";
	const { domain, getClient } = useContext(DataContext);
	const { useQuery, useLazyQuery, useRestRequest } = useDataRequest();
	const related = useQuery(AFFIDAMENTI_RELATED);
	const progetti = useQuery(GET_CARTELLA_PROGETTI, { variables: { cartella: options.parent.uniqueId }})
	const query = useLazyQuery(GET_AFFIDAMENTO);
	const [execDetails, { data: dataDetails, loading }] = query;
	const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

	// var schema = useFormBuilderSchemaLoader('presets.anagrafiche-allegato');
	var schema = require('./schema/edit/affidamento.json');
	schema = useSelectItemsPopulate(
		schema as Schema,
        related, {
            motivoChiusuraUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.motivoChiusuraAffidamento.list', []).map(_assoc),
            ],
        }
	);

	const newFilterSchema = useSelectItemsPopulate(
		filterSchema,
        related, {
            paiUniqueId: [
                ..._get(progetti.data, 'cartellaSocioSanitariaQuery.progetti.list', []).map((cat: any) => ({ text: cat.codice, value: cat.uniqueId })),
            ],
        }
	);

	const BASE = `api/{ver}/cartellasociosanitaria/affidamenti`;
	const save = useCallback(async (values) => {
		const { uniqueId } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: {
					...values,
				},
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, [BASE]);

	return {
		graphql: {
			list,
            mutation,
            loading,
			details: execDetails,
			listFilters: () => {
                if (options.parent_type === 'cartella') {
                    return `pagedList (
                        ${_revid('cartella')}: "${options.parent.uniqueId}"
						pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                    )`;
                }
                return 'pagedList(pagination: { pageNumber: $pageNumber, pageSize: $pageSize })';
            },
        },
		actions: {
			save,
			remove,
		},
		features: {
			creation: options.canCreate,
		},
		results: {
            save: resSave,
            remove: resDelete,
        },
		schema,
		extra: {
			slots: {
				edit: Edit,
			},
			options: {
				parent: options.parent,
				onCreateInitialValues: {
					interventoUniqueId: options.parent.uniqueId,
				},
			},
		},
		dataDetails,
		filters: !options.noSearch ? {
			schema: newFilterSchema,
		} : undefined,
		table: {
			columns: [
				{
					dataField: 'uniqueId',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: "codice",
					text: "Codice titolo",
				},
				{
					dataField: "dataChiusura",
					text: "Data chiusura",
					formatter: Formatters.dateFormatter,
				},
				{
					dataField: "motivoChiusura.descrizione",
					text: "Motivo chiusura",
				},
			],
		},
	};
}
