import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { FormViewer } from '@ai4/form-viewer';
import { getEditFormViewer } from 'src/@bootstrap/components/Crud/data';
import { Button, Stack } from 'react-bootstrap';

export function ViewAccessoDiretto(props: any) {
    const {
        record,
        handleClose,
    } = props;
    
    const formArgs = useMemo(() => {
        const args = getEditFormViewer(props);
        return {
            ...args,
        }
    }, [props]);

    return (
        <FormViewer
            {...formArgs}
            slots={{
                ButtonBar: (props: any) => {
                    const { form } = props;
                    return (
                        <Stack direction='horizontal' gap={3}>
                            <Button variant='secondary' type='button' onClick={() => handleClose()}>
                                Annulla
                            </Button>
                        </Stack>
                    );
                },
            }}
        />
    );
}

export default ViewAccessoDiretto;
