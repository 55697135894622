import _get from 'lodash/get';
import React, { useCallback, useContext, useMemo } from "react";
import { isMobile } from 'react-device-detect';
import { DataContext, useDataRequest } from "@ai4/data-request";
import Edit from '../edit/valutazione';
import { GET_VALUTAZIONE } from '../gql/valutazione';
import { Formatters } from '@ai4/records-management';
import { splitCamelCase } from 'src/app/helpers/helpers';
import { pathToSlug } from 'src/@bootstrap/components/Crud/helpers';

export const useModule = (options: any) => {
    const { parent, parent_type, cartellaUniqueId } = options || {};
    const list = 'cartellaSocioSanitariaQuery.valutazioni';
    const mutation = "cartelle.valutazioni";
	const { domain, getClient } = useContext(DataContext);
    const { useLazyQuery, useRestRequest } = useDataRequest();
    const query = useLazyQuery(GET_VALUTAZIONE);
	const [execDetails, { data: dataDetails, loading }] = query;
    const [execSave, resSave] = useRestRequest({
        path: '@later',
		jwt: true,
	});
    const [execRemove, resDelete] = useRestRequest({
        path: '@later',
		jwt: true,
	});

    const BASE = `api/{ver}/cartellasociosanitaria/valutazioni`;
	const save = useCallback(async (values) => {
		const { uniqueId, cartellaUniqueId, progettoUniqueId, data, operatoreUniqueId, tipologia, risultato, formVersion, ...rest } = values;
		const insertedId = await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
			data: {
				dati: {
                    data, operatoreUniqueId, tipologia,
                    risultato,
                    formData: JSON.stringify(rest),
                    formVersion,
                },
                cartellaUniqueId,
                progettoUniqueId,
			},
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
		return {
			uniqueId: uniqueId || insertedId,
			...values,
		}
	}, [BASE]);

	const remove = useCallback(async (values) => {
		const { uniqueId } = values;
		await execRemove({
			path: `${BASE}/${uniqueId}`,
			method: 'DELETE',
		});
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [pathToSlug(list)],
            });
        }
	}, [BASE]);

    return {
        graphql: {
            list,
            mutation,
            loading,
			details: execDetails,
            listFilters: () => {
                const listArgs = () => {
                    if (options.parent_type === 'progetto') {
                        return `progettoUniqueId: "${options.parent.uniqueId}"`;
                    }
                    if (options.parent_type === 'cartella') {
                        return `cartellaUniqueId: "${options.parent.uniqueId}"`;
                    }
                    return ``;
                }
                return `pagedList (
                    ${listArgs()}
                    order: {
                        data: DESC
                    }
					pagination: { pageNumber: $pageNumber, pageSize: $pageSize }
                )`;
            },
        },
        actions: {
            save,
			remove,
			details: {
				onLoadDetails: (item) => item ? ({
					...item,
					cartellaUniqueId,
				}) : undefined,
			},
        },
        results: {
            save: resSave,
            remove: resDelete,
        },
		schema: {},
		dataDetails,
        extra: {
			slots: {
				edit: Edit,
			},
			options: {
				...options,
                onCreateInitialValues: { cartellaUniqueId, [`${parent_type}UniqueId`]: parent.uniqueId },
                onEditValues: { cartellaUniqueId, [`${parent_type}UniqueId`]: parent.uniqueId },
			},
		},
        table: {
            columns: [
                {
                    dataField: "progetto.codice",
                    text: "Progetto",
                    hidden: parent_type !== 'cartella',
                },
                {
                    dataField: "tipologia",
                    text: "Tipologia",
                    formatter: (cell) => splitCamelCase(cell)
                },
                {
                    dataField: "risultato.punteggio",
                    text: "Punteggio",
                },
                {
                    dataField: "risultato.esito",
                    text: "Esito",
                    hidden: true,
                },
                {
                    dataField: "data",
                    text: "Data valutazione",
                    formatter: Formatters.dateFormatter,
                    hidden: isMobile,
                },
                {
                    dataField: "dataChiusura",
                    text: "Data chiusura",
                    formatter: Formatters.dateFormatter,
                    hidden: true,
                },
                {
                    dataField: "operatore",
                    text: "Operatore",
                    formatter: (cell) => {
                        return `${_get(cell, 'datiAnagrafici.nome')} ${_get(cell, 'datiAnagrafici.cognome')}`;
                    },
                    dataNode: `
                    operatore {
                        datiAnagrafici {
                            nome
                            cognome
                        }
                    }
                    `,
                    hidden: isMobile,
                },
            ],
        },
    };
};
