import { gql } from "@ai4/data-request";

export const GET_INTERVENTO = gql`
    query getIntervento($id: Guid!) {
        cartellaSocioSanitariaQuery {
            interventi {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    codice
                    tipologiaIntervento {
                        uniqueId
                    }
                    pianificazioneErogazione {
                        numeroAccessi
                        numeroTotaleAccessi
                    }
                    prestazioniPianificate {
                        uniqueId
                        quantitaPrevista
                        prestazione {
                            uniqueId
                            codice
                            descrizione
                            nome
                        }
                    }
                    progetto {
                        uniqueId
                    }
                    affidamenti {
                        uniqueId
                        codice
                        dataChiusura
                        motivoChiusura {
                            uniqueId
                        }
                        motivoChiusuraAltro
                    }
                }
            }
        }
    }
`;