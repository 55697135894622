import { gql } from "@ai4/data-request";

const GET_CALENDARIO_FRAGMENT = gql`
  fragment AccessoCalendarioFragment on AccessoDiretto {
    uniqueId
    stato
    dataOreInizioErogazione
    dataOreFineErogazione
    assistito {
      datiAnagrafici {
        nome
        cognome
      }
      datiNascita {
        data
      }
    }
    operatoreErogazione {
      uniqueId
      qualificaOperatore {
        colore
      }
    }
    dataOreInizioPianificata
    dataOreFinePianificata
    operatorePianificato {
      uniqueId
      qualificaOperatore {
        uniqueId
        descrizione
        colore
      }
    }
  }
`;

export const GET_CALENDARIO_PIANIFICATO = gql`
  ${GET_CALENDARIO_FRAGMENT}
  query getCalendarioPianificato($operatoreUniqueIds: [String], $assistitoUniqueIds: [String], $from: DateTime!, $to: DateTime!) {
      cartellaSocioSanitariaQuery {
          accessiDiretti {
            list(
              operatoreUniqueIds: $operatoreUniqueIds,
              assistitoUniqueIds: $assistitoUniqueIds,
              where: {
                dataOreInizioPianificata: {
                  _between: {
                    _from: $from,
                    _to: $to
                  }
                }
              }
            ) {
              ...AccessoCalendarioFragment
            }
          }
      }
  }
`;

export const GET_CALENDARIO_ESEGUITO = gql`
  ${GET_CALENDARIO_FRAGMENT}
  query getCalendarioEseguito($operatoreUniqueIds: [String], $assistitoUniqueIds: [String], $from: DateTime!, $to: DateTime!) {
      cartellaSocioSanitariaQuery {
          accessiDiretti {
            list(
              operatoreUniqueIds: $operatoreUniqueIds,
              assistitoUniqueIds: $assistitoUniqueIds,
              stati: ["eseguito"],
              where: {
                dataOreInizioErogazione: {
                  _between: {
                    _from: $from,
                    _to: $to
                  }
                }
              }
            ) {
              ...AccessoCalendarioFragment
            }
          }
      }
  }
`;