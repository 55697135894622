import { authRoles } from "src/app/roles";
import Crud from "src/@bootstrap/components/Crud/Crud";
import NuovaCartella from "./NuovaCartella";
import CartellaDettaglio from "./CartellaDettaglio";
import SGDT from "./sgdt/SGDT";

const cartelleRoutes = [
    {
        path: "cartelle/nuovo/:anagrafica",
        element: <NuovaCartella />,
        // auth: authRoles.admin
    },
    {
        path: "cartelle/ricerca",
        element: <Cartelle />,
        auth: authRoles.admin
    },
    {
        path: "cartelle/dettaglio/:id",
        element: <CartellaDettaglio />,
        // auth: authRoles.admin
    },
    {
        path: "cartelle/sgdt",
        element: <SGDT />,
    },
];

function Cartelle() {
    return <Crud module='cartelle' entity='cartelle' />;
};

export default cartelleRoutes;
