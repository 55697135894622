import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Tab, Tabs, Alert, Col, Nav, Spinner } from 'react-bootstrap';
import { useDataRequest } from '@ai4/data-request';
import { GET_INTERVENTO } from '../gql/intervento';
import { GET_PROGETTO_PAI } from '../gql/progettoPai';
import { Dt } from '../NuovaCartella';
import Crud from 'src/@bootstrap/components/Crud/Crud';
import Title from 'src/app/template/SharedComponents/Title';
import { formatDate } from 'src/app/helpers/helpers';
import { getEditFormViewer } from '../../anagrafiche/common';
import { FormViewer } from '@ai4/form-viewer';

export function EditAffidamento(props: any) {
    const { record, handleClose } = props;
    const defaultSection = 'scheda';
    const [section, setSection] = useState<string>(defaultSection);
	const { useQuery, } = useDataRequest();
    // intervento
    const interventoUniqueId = record.interventoUniqueId;
    const query1 = useQuery(GET_INTERVENTO, {
        skip: !interventoUniqueId,
        variables: {
            id: interventoUniqueId,
        }
    });
    const { data: data1 } = query1;
    const intervento = _get(data1, 'cartellaSocioSanitariaQuery.interventi.list[0]');
    const prestazioniIds = _get(intervento, 'prestazioniPianificate', []).map(p => _get(p, 'prestazione.uniqueId'));
    // progetto
    const progettoUniqueId = _get(intervento, 'progetto.uniqueId');
    const query2 = useQuery(GET_PROGETTO_PAI, {
        skip: !progettoUniqueId,
        variables: {
            id: progettoUniqueId,
        }
    });
    const { data: data2 } = query2;
    const progetto = _get(data2, 'cartellaSocioSanitariaQuery.progettiPai.list[0]');
    const dataInizio = _get(progetto, 'dataInizio');

    const formArgs = useMemo(() => {
        const { initialValues, ...args } = getEditFormViewer(props);
        return {
            ...args,
            initialValues: {
                ...record,
            }
        }
    }, [props]);

    const subProps = {
        parent: record,
        parent_type: 'affidamento',
    }
    if (query1.loading || query2.loading) return <Spinner />;
    return <>
        <Title title={record.uniqueId ? `Dettaglio titolo ${record.codice || 'nd'}` : 'Nuovo titolo'} onBack={handleClose} />

        <Alert variant={'primary'}>
            <Dt label='Servizio' value={_get(progetto, 'modalitaCura.servizio.descrizione')} />
            <Dt label='Percorso' value={_get(progetto, 'modalitaCura.percorso.descrizione')} />
            <Dt label='Data inizio' value={dataInizio ? formatDate(dataInizio) : '-'} />
            {/*
            <div className="row">
                <label className="col-sm-4 col-form-label"><strong>Figure professionali</strong></label>
                <div className="col-sm-8">
                </div>
            </div>
            */}
        </Alert>

        <div>
            <Tabs
                defaultActiveKey={defaultSection}
                activeKey={section}
                className="mb-3"
                onSelect={(k) => setSection(k || defaultSection)}
            >
                <Tab eventKey="scheda" title="Scheda titolo">
                    {section === 'scheda' && <>
                        <FormViewer
                            {...formArgs}
                        />
                    </>}
                </Tab>
                <Tab eventKey="accessiDiretti" title="Accessi">
                    {section === 'accessiDiretti' && <>
                        {record.uniqueId && (<>
                        <Accessi {...subProps} />
                        </>)}
                        {!record.uniqueId && (<>
                        <div className='empty'>
                            Puoi aggiungere accessi solo dopo aver creato l'elemento.
                        </div>
                        </>)}
                    </>}
                </Tab>
                <Tab eventKey="sospensioni" title="Sospensioni">
                    {section === 'sospensioni' && <>
                        {record.uniqueId && (<>
                        <Sospensioni {...subProps} />
                        </>)}
                        {!record.uniqueId && (<>
                        <div className='empty'>
                            Puoi aggiungere sospensioni solo dopo aver creato l'elemento.
                        </div>
                        </>)}
                    </>}
                </Tab>
            </Tabs>
        </div>
    </>
}

interface SubProps {
	parent: any;
	parent_type: string;
}

const Accessi = (props: SubProps) => {
	return <Crud
        title='Accessi'
        module='cartelle'
        entity='sub/accessiDiretti'
		options={{
			...props,
            graphql: {
                details: 'getIntervento',
            },
		}}
	/>;
}

const Sospensioni = (props: SubProps) => {
	return <Crud
        title='Sospensioni'
        module='cartelle'
        entity='sub/sospensioni'
		options={{
			...props,
            graphql: {
                details: 'getIntervento',
            },
		}}
	/>;
}

export default EditAffidamento;
