import { gql } from "@ai4/data-request";

export const GET_CARTELLA = gql`
    query getCartella($id: Guid!) {
        cartellaSocioSanitariaQuery {
            cartelle {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    progetti {
                        uniqueId
                    }
                    schede {
                        uniqueId
                        tipologia
                    }
                    codice
                    assistito {
                        datiAnagrafici {
                            nome
                            cognome
                        }
                    }
                    strutturaSanitaria {
                        nome
                    }
                }
            }
        }
    }
`;