const getSchema = () => (

/*** JSON */

{
  "defaultActiveTabId": "Titolo",
  "id": "root",
  "type": "tabs",
  "tabs": [
    {
      "id": "Titolo",
      "title": "Titolo",
      "rows": [
        {
          "id": "columns.cvwwi2mbd",
          "type": "columns",
          "defaultSizes": [
            6,
            6
          ],
          "columns": [
            {
              "id": "column.9lhphgahf",
              "name": "affidamentoUniqueId",
              "type": "field",
              "fieldType": "select",
              "label": "Progetto PAI",
              "props": {},
              "validation": [
                {
                  "rule": "required"
                }
              ]
            },
            {
              "id": "column.xjcx7wxbe"
            }
          ]
        },
        {
          "id": "columns.6x6ukv78y",
          "type": "columns",
          "defaultSizes": [
            6,
            6,
            3
          ],
          "columns": [
            {
              "id": "column.kni12y2dc",
              "name": "dataOreInizioErogazione",
              "type": "field",
              "fieldType": "datetime",
              "label": "Inizio",
              "props": {}
            },
            {
              "id": "column.snmmfwqdj",
              "name": "dataOreFineErogazione",
              "type": "field",
              "fieldType": "datetime",
              "label": "Fine",
              "props": {}
            }
          ]
        },
        {
          "id": "columns.1mny5ipma",
          "type": "columns",
          "defaultSizes": [
            6,
            6
          ],
          "columns": [
            {
              "id": "column.fx2scldkz",
              "name": "operatoreErogazioneUniqueId",
              "type": "field",
              "fieldType": "select",
              "label": "Operatore",
              "props": {}
            },
            {
              "id": "column.m9lcw24c9",
              "name": "tipologiaAccessoDirettoUniqueId",
              "type": "field",
              "fieldType": "select",
              "label": "Tipologia",
              "props": {}
            }
          ]
        },
        {
          "id": "columns.zv7b1fos2",
          "type": "columns",
          "defaultSizes": [
            12,
            6
          ],
          "columns": [
            {
              "id": "column.u1rwqu4ud",
              "name": "prestazioniErogate",
              "type": "field",
              "fieldType": "select",
              "label": "Prestazioni",
              "props": {
                "multiple": "on"
              }
            }
          ]
        }
      ]
    }
  ]
}

/*** JSON */

)

export default getSchema;