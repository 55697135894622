import _get from 'lodash/get';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Alert, Modal, Button, Table, Spinner } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import $schema from './schema/edit/dimissioni.json';
import { GET_SOSPENSIONE } from './gql/sospensione';
import { _assoc } from '../anagrafiche/common';

const RELATED = gql`
	query getRelated {
		cartellaSocioSanitariaQuery {
            elenchiCartellaSocioSanitaria {
                dimissioneMotivo {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                dimissioneEsito {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                dimissioneStatoObiettivi {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
	}
`;

export function EditCartellaDettaglioSospensione(props: any) {
    const { id } = props;
    
    const { domain, getClient } = useContext(DataContext);
    const { useQuery, useRestRequest } = useDataRequest();
    const [execSave, res] = useRestRequest({
        path: `api/{ver}/cartellasociosanitaria/cartelle/${id}/dimissione`,
        jwt: true,
    });

    const related = useQuery(RELATED);
    const query = useQuery(GET_SOSPENSIONE, {
        variables: {
            id,
        }
    });
    const data = _get(query.data, 'cartellaSocioSanitariaQuery.schedaDimissione.list', []);
    const record = useMemo(() => {
        return data.length > 0 ? data[0] : {};
    }, [data]);

    const schema = useSelectItemsPopulate(
        $schema as unknown as any,
        related,
        {
            motivoUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.dimissioneMotivo.list', []).map(_assoc),
            ],
            esitoUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.dimissioneEsito.list', []).map(_assoc),
            ],
            raggiungimentoObiettiviUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.dimissioneStatoObiettivi.list', []).map(_assoc),
            ],
        }
    );

    const submit = useCallback(async ({ values }) => {
        await execSave({
            method: record && record.uniqueId ? 'PUT' : 'POST',
            data: {
                dati: {
                    ...values,
                    motivo: _get(values, 'motivoUniqueId'),
                    esito: _get(values, 'esitoUniqueId'),
                    stato: _get(values, 'raggiungimentoObiettiviUniqueId'),
                },
            },
        });
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: [GET_SOSPENSIONE],
            });
        }
    }, [record]);

    if (query.loading || related.loading) return <Spinner />
    return <div>
        <h2>Dimissioni cartella {record.codice}</h2>
        <FormViewer
            initialValues={record.dimissione}
            schema={schema as Schema}
            onSubmit={submit}
            slots={{
                ButtonBar: (args) => <>
                    <div className='d-flex justify-content-end flex-row bd-highlight gap-3'>
                        <Button variant="primary" type='submit'>
                            Salva
                        </Button>
                    </div>
                </>
            }}
        />
    </div>;
}

export default EditCartellaDettaglioSospensione;
