import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { SchemaTypes, traverseSchema } from '@ai4/form-viewer';
import { gql, useAuthRequest, useDataRequest, useFormViewer } from '@ai4/data-request';
import { textFilter } from '@ai4/react-bootstrap-table2-filter';
import $schema from './tenant.schema.json';
import { Formatters } from '@ai4/records-management';
let schema = $schema as SchemaTypes.Schema;

export const useModule = () => {
	const list = 'tenants.list';
	const mutation = "tenant";

    const { useRestRequest } = useDataRequest();
	const [reqList, resList] = useRestRequest({
        path: 'api/{ver}/tenants',
        method: 'GET',
        jwt: true,
    });
	const [execSave, resSave] = useRestRequest({
        path: 'api/{ver}/tenants',
        method: 'POST',
        jwt: true,
    });
	const [reqTipi, resTipi] = useRestRequest({
        path: 'api/{ver}/tenants/tipologie',
        method: 'GET',
        jwt: true,
    });

    useEffect(() => {
        reqList();
		reqTipi();
    }, []);
	const { transformData } = useFormViewer({});

	const save = useCallback(async (values) => {
		const { id, name, connectionString, adminEmail, issuer } = values;

		await execSave({
			data: {
				...transformData(values, {
					id,
					name,
					connectionString,
					adminEmail,
					issuer,
				}),
			},
		});
		reqList();
		return values;
	}, []);

	const rows = resList.data || [];
	const tipi = resTipi.data || [];

	const newSchema = useMemo(() => {
		if (!tipi) return null;
		return traverseSchema(schema, (n: SchemaTypes.SchemaNode) => {
		  if (n.id && n.id === "tipologia")
			return {
			  ...n,
			  props: {
				items: tipi.map((tipo: any) => ({ text: tipo && tipo.length > 0 ? tipo : 'Default', value: tipo })),
			  },
			};
		  return n;
		});
	  }, [schema, tipi]);
	  console.log(newSchema)

	return {
		noPagination: true,
		graphql: {
			list,
			mutation,
		},
		dataList: rows,
		schema: newSchema,
		actions: {
			save,
		},
		results: {
			save: resSave,
		},
		extra: {
			hideEditColumns: true,
		},
		table: {
			columns: [
				{
					dataField: 'id',
					text: 'ID',
					hidden: true,
				},
				{
					dataField: 'tipologia',
					text: 'Tipologia',
				},
				{
					dataField: 'name',
					text: 'Nome',
				},
				{
					dataField: 'adminEmail',
					text: 'Admin email',
				},
				{
					dataField: 'validUpto',
					text: 'Validità',
					formatter: Formatters.dateFormatter
				},
			]
		}
	};
}
