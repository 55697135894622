import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";

import TitleBar from "src/app/components/TitleBar";
import Edit from "src/app/components/Edit";
import { FormViewer } from '@ai4/form-viewer';
import { getEditFormViewer } from '../../anagrafiche/common';

export function EditPrestazione(props: any) {
    const {
        record,
        handleClose,
    } = props;

    const formArgs = useMemo(() => {
        return getEditFormViewer(props);
    }, [props]);

    return (
        <Edit>
            <TitleBar
                path={[
                    `${record.uniqueId ? record.nome : "Nuova prestazione"}`,
                ]}
                onBack={handleClose}
            />
            <FormViewer
                {...formArgs}
            />
        </Edit>
    );
}

export default EditPrestazione;
