import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import { gql, useDataRequest } from "@ai4/data-request";
import TitleBar from "src/app/components/TitleBar";
import Edit from "src/app/components/Edit";
import { FormViewer } from '@ai4/form-viewer';
import Crud from "src/@bootstrap/components/Crud/Crud";
import { getEditFormViewer } from './common';
import Recapiti from './sub/Recapiti';
import TessereSanitarie from './sub/TessereSanitarie';
import DocumentiIdentita from './sub/DocumentiIdentita';
import Cittadinanza from './sub/Cittadinanza';
import Domicili from './sub/Domicili';

interface SubProps {
	parent: any;
	parent_type: string;
}

const Allegati = (props: SubProps) => {
    // FullCrud
	return <Crud
        title='Allegati'
        module='anagrafiche'
        entity='allegati'
		options={{
			...props,
            update_field: 'assistitoUniqueId',
            graphql: {
                details: 'getAssistito',
            },
            options: {
                modalSize: 'lg',
                closeWhenSaved: true,
            },
		}}
	/>;
}

const QUERY = gql`
    query getSubAnagrafica {
        anagraficaQuery {
            elenchiAnagrafica {
                statoCivile {
                    list {
                        uniqueId
                        codice
                        descrizione
                    }
                }
                tipologiaRecapito {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaTesseraSanitaria {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaDocumentoIdentita {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaEnteEmittente {
                    list {
                        uniqueId
                        descrizione
                    }
                }
                tipologiaCittadinanza {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
        elenchiQuery {
            elenchiGeneraliQuery {
                provincie {
                    list {
                        uniqueId
                        siglaProvincia
                        descrizione
                    }
                }
                nazioni {
                    list {
                        uniqueId
                        denominazioneIT
                    }
                }
                cittadinanze {
                    list {
                        uniqueId
                        descrizione
                    }
                }
            }
        }
    }
`;

export function EditAssistito(props: any) {
    const {
        record,
        onAddExtraData,
        handleClose,
    } = props;
    const subProps = {
        parent: record,
        parent_type: 'assistiti',
    }

    const [extraData, setExtraData] = useState({
        recapiti: _get(record, 'datiRecapito.recapiti', []),
        tessereSanitarie: _get(record, 'datiSanitari.tessereSanitarie', []),
        documentiIdentita: _get(record, 'documentiIdentita', []),
        datiCittadinanza: _get(record, 'datiCittadinanza', []),
        datiDomicilio: _get(record, 'datiDomicilio', []) || [],
    });
    const { useQuery } = useDataRequest();
    const query = useQuery(QUERY);

    useEffect(() => {
        onAddExtraData({
            ...extraData,
        });
    }, [extraData]);

    const formArgs = useMemo(() => {
        const { initialValues, ...args } = getEditFormViewer(props);
        return {
            ...args,
            initialValues: {
                ...initialValues,
                isStraniero: !!(initialValues || {}).datiStraniero,
                isMinore: !!(initialValues || {}).datiMinore,
            }
        }
    }, [props]);

    return (
        <Edit>
            <TitleBar
                path={[
                    `${record.uniqueId ? `${_get(record, 'datiAnagrafici.nome')} ${_get(record, 'datiAnagrafici.cognome')}` : "Nuovo assitito"}`,
                ]}
                onBack={handleClose}
            />
            <FormViewer
                {...formArgs}
                components={{
					recapiti: () => <Recapiti items={extraData.recapiti} related={query} onChange={(recapiti) => setExtraData({...extraData, recapiti})} />,
                    tessereSanitarie: () => <TessereSanitarie items={extraData.tessereSanitarie} related={query} onChange={(tessereSanitarie) => setExtraData({...extraData, tessereSanitarie})} />,
					documentiIdentita: () => <DocumentiIdentita items={extraData.documentiIdentita} related={query} onChange={(documentiIdentita) => setExtraData({...extraData, documentiIdentita})} />,
                    datiCittadinanza: () => <Cittadinanza items={extraData.datiCittadinanza} related={query} onChange={(datiCittadinanza) => setExtraData({...extraData, datiCittadinanza})} />,
                    datiDomicilio: () => <Domicili single={true} items={extraData.datiDomicilio} related={query} onChange={(datiDomicilio) => setExtraData({...extraData, datiDomicilio})} />,
                    allegati: () => <>
                        {record.uniqueId && (<>
                        <Allegati {...subProps} />
                        </>)}
                        {!record.uniqueId && (<>
                        <div className='empty'>
                            Puoi aggiungere allegati solo dopo aver creato l'elemento.
                        </div>
                        </>)}
                    </>
				}}
            />
        </Edit>
    );
}

export default EditAssistito;
