import _get from 'lodash/get';
import React, { useContext, useState } from 'react';
import { Tab, Row, Button, Alert, Col, Nav } from 'react-bootstrap';
import { ConfigContext } from 'src/@bootstrap/Config';
import Crud from 'src/@bootstrap/components/Crud/Crud';
import Title from 'src/app/template/SharedComponents/Title';
import PianoAssistenziale from '../sub/PianoAssistenziale';
import Affidamento from '../sub/Affidamento';

export function EditProgetto(props: any) {
    const { record, options, handleClose } = props;
    const [tab, setTab] = useState('dettaglio');
    const { getEnv, hasFeature } = useContext(ConfigContext);

    const subAffidamentoProps = {
        options: {
            parent: _get(record, 'interventi[0].affidamenti[0]'),
            parent_type: 'affidamento',
        }
    }
    const canAffidamenti = _get(record, 'interventi', []).length > 0;
    return <>
        <Title title={record.uniqueId ? `Dettaglio ${getEnv('PAI_LABEL_SINGLE')} ${record.codice}` : `Nuovo ${getEnv('PAI_LABEL_SINGLE')}`} onBack={handleClose} />
        <Tab.Container
            defaultActiveKey="dettaglio"
            activeKey={tab}
            onSelect={(k) => setTab(k || '')}
        >
            <Row>
                <Col sm={2}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="dettaglio">Dettaglio {getEnv('PAI_LABEL_SINGLE')}</Nav.Link>
                        </Nav.Item>
                        {record && record.uniqueId && (<>
                            {/*<Nav.Item>
                                <Nav.Link eventKey="piano">Piano assistenziale</Nav.Link>
                            </Nav.Item>*/}
                            {canAffidamenti && (<>
                            {/*<Nav.Item>
                                <Nav.Link eventKey="affidamenti">Titoli</Nav.Link>
                            </Nav.Item>*/}
                            <Nav.Item>
                                <Nav.Link eventKey="accessiDiretti">Accessi</Nav.Link>
                            </Nav.Item>
                            {hasFeature('cartelle_progetti_sospensioni') && <Nav.Item>
                                <Nav.Link eventKey="sospensioni">Sospensioni</Nav.Link>
                            </Nav.Item>}
                            </>)}
                            <Nav.Item>
                                <Nav.Link eventKey="valutazioni">Valutazioni</Nav.Link>
                            </Nav.Item>
                        </>)}
                    </Nav>
                </Col>
                <Col sm={10}>
                    <Tab.Content>
                        <Tab.Pane eventKey="dettaglio">
                            {tab === 'dettaglio' && <>
                                {props.children}
                            </>}
                        </Tab.Pane>
                        {record && record.uniqueId && (<>
                            {canAffidamenti && (<>
                            <Tab.Pane eventKey="accessiDiretti">
                                {tab === 'accessiDiretti' && <Crud module='cartelle' entity='accessiDiretti' path='cartelle/sub/accessiDiretti' {...subAffidamentoProps} />}
                            </Tab.Pane>
                            <Tab.Pane eventKey="sospensioni">
                                {tab === 'sospensioni' && <Crud module='cartelle' entity='sospensioni' path='cartelle/sub/sospensioni' {...subAffidamentoProps} />}
                            </Tab.Pane>
                            {/* 
                            <Tab.Pane eventKey="piano">
                                {tab === 'piano' && <>
                                <PianoAssistenziale record={record} parent={options.parent} />
                                </>}
                            </Tab.Pane>
                            <Tab.Pane eventKey="affidamenti">
                                {tab === 'affidamenti' && <>
                                // single
                                <Affidamento
                                    record={record}
                                    parent={options.parent}
                                    onBack={() => setTab('dettaglio')}
                                />
                                
                                // multi
                                <Crud module='cartelle' entity='affidamenti' options={{
                                    ...options,
                                    parent: _get(record, 'interventi', [])[0],
                                    canCreate: true,
                                    noSearch: true,
                                }} />
                                </>}
                            </Tab.Pane>
                            */}
                            </>)}
                            <Tab.Pane eventKey="valutazioni">
                                {tab === 'valutazioni' && <>
                                    <Crud module='cartelle' entity='sub/valutazioni' options={{
                                        ...options,
                                        parent: record,
                                        parent_type: 'progetto',
                                        cartellaUniqueId: _get(record, 'cartella.uniqueId'),
                                        canCreate: true,
                                        noSearch: true,
                                    }} />
                                </>}
                            </Tab.Pane>
                        </>)}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    </>
}

export default EditProgetto;
