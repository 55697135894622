

import permissionSchemaDb from './permissionSchema'

const productsDb = {
  products: [{
    id: 'ad339f64-5109-11ec-bf63-0242ac130002',
    name: "SISS",
    appUrl: "https://modello3agid.progettidiimpresa.it",
    modules: [{
      name: "Anagrafiche",
      code: "ANAGRAFICHE",
      functionGroups: [{
        id: "ad339f64-5109-11ec-bf63-0242ac130006",
        name: "Attività",
        functions: [{
          id: 'ad339f64-5109-11ec-bf63-0242ac130002',
          name: "Accesso ai dati",
          description: "Visualizzazione della scheda anagrafica di un assistito",
          code: "VIEW",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130003',
          name: "Esecuzione della riconciliazione anagrafica",
          code: "RICO",
          roles: [permissionSchemaDb.roles[1], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130004',
          name: "Esecuzione della fusione",
          code: "FUSION",
          roles: [permissionSchemaDb.roles[1], permissionSchemaDb.roles[5]]
        }]
      }, {
        id: "ad339f64-5109-11ec-bf63-0242ac130005",
        name: "Limiti alla visualizzazione dei dati",
        functions: [{
          id: 'ad339f64-5109-11ec-bf63-0242ac130005',
          name: "Limitata ai propri pazienti (per medici)",
          description: "Limitare l'accesso ai soli assistiti che hanno configurato l'utente nel campo 'Medico'",
          code: "DOCTOR",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130006',
          name: "Limitata ai propri pazieti (per pediatri)",
          description: "Limita l'accesso ai soli assistiti che hanno configurato l'utente nel campo 'Pediatra'",
          code: "PED",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130007',
          name: "Limitata ai maggiorenni",
          code: "MAG",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130008',
          name: "Limitata ai residenti nel proprio comune",
          description: "Limita l'accesso ai soli assistiti che risiedono nello stesso comune dell'utente",
          code: "COMUNE",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130009',
          name: "Limitata ai residenti nella propria provincia",
          description: "Limita l'accesso ai soli assistiti che risiedono nella stessa provincia dell'utente",
          code: "PROV",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130010',
          description: "Limita l'accesso ai soli assistiti che risiedono nella stessa regione dell'utente",
          code: "REG",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }]
      }]
    },
    {
      name: "Cartella",
      code: "CARTELLA",
      functionGroups: [{
        id: "ad339f64-5109-11ec-bf63-0242ac130005",
        name: "Principale",
        functions: [{
          id: 'ad339f64-5109-11ec-bf63-0242ac130002',
          name: "Accesso ai dati",
          description: "Visualizzazione della cartella di un assistito",
          code: "VIEW",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130003',
          name: "Accesso ai PAI",
          description: "Visualizzazione dei PAI di un assistito",
          code: "VIEW_PAI",
          roles: [permissionSchemaDb.roles[3], permissionSchemaDb.roles[4], permissionSchemaDb.roles[5]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130004',
          name: "Accesso alla pianificazione",
          description: "Visualizzazione della pianificazione degli operatori",
          code: "PLANNING",
          roles: [permissionSchemaDb.roles[1], permissionSchemaDb.roles[5]]
        }]
      }]
    },
    {
      name: "Configurazione",
      code: "CONFIG",
      functionGroups: [{
        id: "ad339f64-5109-11ec-bf63-0242ac130005",
        name: "Permessi",
        functions: [{
          id: 'ad339f64-5109-11ec-bf63-0242ac130002',
          name: "Accounts",
          description: "Gestione degli utenti che possono accedere al sistema e associazione ai ruoli",
          code: "ACCOUNTS",
          roles: [permissionSchemaDb.roles[1], permissionSchemaDb.roles[2]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130003',
          name: "Ruoli",
          description: "Configurazione dei ruoli previsti dal sistema",
          code: "ROLES",
          roles: [permissionSchemaDb.roles[1]]
        }, {
          id: 'ad339f64-5109-11ec-bf63-0242ac130004',
          name: "Permessi",
          description: "Configurazione dei ruoli su cui sono attivati determinati permessi",
          code: "PERMISSIONS",
          roles: [permissionSchemaDb.roles[1]]
        }]
      }]
    }],
    permissionSchema: permissionSchemaDb
  },
  {
    id: 'ad339f64-5109-11ec-bf63-0242ac130004',
    name: "App Accessi",
    appUrl: "https://modello3agid.progettidiimpresa.it",
    permissionSchema: permissionSchemaDb
  },
  {
    id: 'ad339f64-5109-11ec-bf63-0242ac130003',
    name: "WP PA Plugin",
    appUrl: "https://wordpress.org/plugins/",
    permissionSchema: permissionSchemaDb
  }]
};



export default productsDb;