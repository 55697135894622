import React, { useCallback, useEffect, useState } from "react";
import { useDataRequest } from "@ai4/data-request";
import { Breadcrumb } from "src/@bootstrap/template/@gull";
import NuoveCartelle from "./components/NuoveCartelle";
import AccessiPianificati from "./components/AccessiPianificati";
import ProgettiInScadenza from "./components/ProgettiInScadenza";
import BudgetChart from "./components/BudgetChart";
import ProjectsChart from "./components/ProjectsChart";

function DashboardSupervisor() {
  const [data, setData] = useState({});
  const { useRestRequest } = useDataRequest();
  const [req, ] = useRestRequest({
      path: 'api/{ver}/cartellasociosanitaria/dashboard',
      method: 'GET',
      jwt: true,
  });

  useEffect(() => {
    load();
  }, []);

  const load = useCallback(async () => {
    const res = await req({
      queryString: {
        enableCartelle: true,
        enableAffidamenti: true,
        enableAccessi: true,
        enableChart: true,
        limiteAccessi: 30,
      }
    });
    setData(res);
  }, []);

  const state = {
    cardList1: [
      {
        icon: "i-Address-Book",
        title: data.anagrafiche,
        subtitle: "anagrafiche",
      },
      {
        icon: "i-Folders",
        title: data.cartelleAttive,
        subtitle: "cartelle attive",
      },
      {
        icon: "i-Over-Time-2",
        title: data.titoliInScadenza,
        subtitle: "progetti in scadenza",
      },
      {
        icon: "i-Calendar-4",
        title: data.accessiPianificati,
        subtitle: "accessi pianificati",
      },
    ],
  };

  const getUserStatusClass = (status) => {
    switch (status) {
      case "active":
        return "badge-success";
      case "inactive":
        return "badge-warning";
      case "pending":
        return "badge-primary";
      default:
        break;
    }
  };

  let { cardList1 = [] } = state;

  return (
    <div>
      <Breadcrumb
        routeSegments={[
          { name: "Dashboard", path: "dashboard/users/DashboardUsers" },
          { name: "Amministratore" },
        ]}
      ></Breadcrumb>

      <div className="row">
        {cardList1.map((card, index) => (
          <div key={index} className="col-lg-3 col-md-6 col-sm-6">
            <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
              <div className="card-body text-center">
                <i className={card.icon}></i>
                <div
                  className="content"
                  style={{ maxWidth: "none", display: "flow-root" }}
                >
                  <p className="text-muted mt-2 mb-0 text-capitalize">
                    {card.subtitle}
                  </p>
                  <p className="lead text-primary text-24 mb-2 text-capitalize">
                    {card.title}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="row">
          <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
              <NuoveCartelle data={data.nuoveCartelle}></NuoveCartelle>
          </div>
          <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
              <ProgettiInScadenza data={data.affidamentiInScadenza}></ProgettiInScadenza>
          </div>
      </div>
      <div className="row">
          {/*
          <div className="col-lg-3 col-md-3" style={{ paddingBottom: "15px" }}>
              <BudgetChart height={400}></BudgetChart>
          </div>
          */}
          <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
              <ProjectsChart data={data.ultimiAccessi} height={400}></ProjectsChart>
          </div>
          <div className="col-lg-6 col-md-6" style={{ paddingBottom: "15px" }}>
              <AccessiPianificati data={data.prossimiAccessiPianificati}></AccessiPianificati>
          </div>
      </div>
    </div>
  );
}

export default DashboardSupervisor;
