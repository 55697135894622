import React, { useCallback, useEffect, useState } from 'react';

export function Edit(props: any) {
	const { children } = props;
    
    return <div className='Edit'>
        {children}
    </div>;
}

export default Edit;
