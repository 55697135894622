import _get from 'lodash/get';
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Spinner, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Select from "react-select";
import { List, selectLists } from 'src/app/features/custom';
import { splitPascalCase } from 'src/app/helpers/helpers';
import GestioneListe from '../../commons/GestioneListe';

function ListsManagement() {
    const lists = useSelector(selectLists);
	const [record, setRecord] = useState<List>();

    if (!lists) return <Spinner />;
    return <div>
        <div className="ListManagement">
            <header>
                <h1>Gestione elenchi</h1>
                <div>
                    <Select
                        options={lists.filter(l => l.isModificabile)}
                        getOptionValue={(option) => option.nome}
                        getOptionLabel={(option) => splitPascalCase(option.nome)}
                        // value={node.role}
                        onChange={(option) => {
                            if (option) setRecord(lists.find(l => l.nome === option.nome));
                        }}
                    />
                </div>
            </header>
            <div className="list">
                {record && <>
                    <GestioneListe name={record.nome} />
                </>}
            </div>
        </div>

    </div>;
};

export default ListsManagement;