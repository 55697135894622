import { gql } from "@ai4/data-request";

export const GET_SOSPENSIONE = gql`
    query getSospensione($id: Guid!) {
        cartellaSocioSanitariaQuery {
            sospensioni {
                list (where: { uniqueId: { _eq: $id } }) {
                    uniqueId
                    metadata {
                        insertDate
                        updateDate
                    }
                    affidamento {
                        uniqueId
                    }
                    dataInizio
                    dataFine
                    motivoSospensione {
                      uniqueId
                      descrizione
                    }
                    note
                }
            }
        }
    }
`;