import _get from 'lodash/get';
import _set from 'lodash/set';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Form, Button, Table, Spinner, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { gql, useDataRequest } from '@ai4/data-request';
import { FormViewer, SchemaTypes } from '@ai4/form-viewer';
import Schema from '@ai4/form-viewer/dist/types/schema';
import Title from 'src/app/template/SharedComponents/Title';
import { GET_ASSISTITO } from '../../anagrafiche/gql/assistiti';
import { GET_ASSISTITO_BY_CARTELLA } from '../gql/progettoPai';
import { getEditFormViewer } from '../../anagrafiche/common';
import { hasPermission, selectCurrentUser, selectPermissions } from 'src/@bootstrap/features/auth';
import { useFormBuilderSchemaLastVer, useFormBuilderSchemaMerger } from 'src/@bootstrap/components/Crud/data';
import { useHideNodesSchema, useSelectItemsPopulate } from 'src/app/helpers/data';

const PROGETTI_IN_CARTELLA = gql`
    query getProgettiInCartella($cartellaUniqueId: String) {
        cartellaSocioSanitariaQuery {
            progettiPai {
                list(
                    cartellaUniqueId: $cartellaUniqueId
                ) {
                    uniqueId
                    codice
                }
            }
        }
    }
`;

const RELATED = gql`
	query getRelatedValutazione {
		struttureServiziQuery {
			operatori {
				list {
					uniqueId
					datiAnagrafici {
						nome
						cognome
					}
                    utenteCollegato {
                        email
                        userName
                    }
				}
			}
		}
	}
`;

function Dt({ label, value }) {
    return <div className="row">
        <label className="col-sm-4 col-form-label"><strong>{label}</strong></label>
        <div className="col-sm-8">
            <input type="text" readOnly className="form-control-plaintext" value={value} />
        </div>
    </div>;
}

interface TipologiaValutazione {
    key: string;
    label: string;
    titles?: Record<string, string>;
    schema?: string;
    calc?: boolean;
    parser?: (risultato: any) => any;
}

const TIPOLOGIE: TipologiaValutazione[] = [
    { key: 'valutazioneSociale', schema: 'Valutazione-Sociale', label: 'Valutazione Sociale', titles: { edit: 'Modifica Valutazione Sociale', create: 'Nuova Valutazione Sociale' }},
    { key: 'lesioniDaDecubito', label: 'Lesioni da decubito', },
    { key: 'lesioniVascolari', label: 'Lesioni vascolari', },
    { key: 'rilevazioneDolore', label: 'Rilevazione dolore', },
    { key: 'scalaBarthel', label: 'Scala Barthel', calc: true, parser: (values) => { const punteggio = _get(values, 'risultato.punteggio'); return _set(values, 'risultato.esito', punteggio < 20 ? `livello di dipendenza totale` : (punteggio >= 20 && punteggio <= 35 ? `livello di dipendenza grave` : (punteggio >= 41 && punteggio <= 55 ? `livello di dipendenza moderato` : (punteggio >= 60 && punteggio <= 99 ? `livello di dipendenza lieve` : (punteggio === 100 ? `indipendente` : undefined))))); } },
    { key: 'scalaBIM', label: 'Scala BIM', calc: true, parser: (values) => { const punteggio = _get(values, 'risultato.punteggio'); return _set(values, 'risultato.esito', punteggio <= 20 ? `totalmente dipendente` : (punteggio >= 21 && punteggio <= 60 ? `dipendenza severa` : (punteggio >= 61 && punteggio <= 90 ? `dipendenza moderata` : (punteggio >= 91 && punteggio <= 99 ? `dipendenza minima` : (punteggio === 100 ? `autosufficienza` : undefined))))); } },
    { key: 'scalaBraden', label: 'Scala Braden', calc: true, parser: (values) => { const punteggio = _get(values, 'risultato.punteggio'); return _set(values, 'risultato.esito', punteggio < 13 ? `alto` : (punteggio >= 13 && punteggio <= 16 ? `medio` : (punteggio >= 17 && punteggio <= 23 ? `nullo - lieve` : undefined))); } },
    { key: 'scalaBradenPediatrica', label: 'Scala Braden (pediatrica)', calc: true, },
    { key: 'scalaCBI', label: 'Scala CBI', calc: true, },
    { key: 'scalaCDRFTD', label: 'Scala CDRFTD', calc: true, parser: (values) => {
        const { uniqueId, formVersion, progettoUniqueId, risultato, tipologia, Memoria, ...rest } = values;
        const punteggio = _get(values, 'risultato.punteggio');
        if (!Number.isInteger(parseInt(Memoria))) {
            return values;
        }
        const cat1 = parseInt(Memoria);
        const cat2 = Object.keys(rest).map((k) => {
            if (Number.isInteger(parseInt(rest[k]))) {
                return parseInt(rest[k]);
            }
            return undefined;
        }).filter(c => c !== undefined) as number[];
        var CDR: number | undefined = undefined;
        if (cat2.filter(c => c === cat1).length >= 3) {
            CDR = cat1;
        }
        if (cat2.filter(c => c > cat1 || c < cat1).length >= 3) {
            const map = cat2.reduce<Record<number, number>>((sum, num) => {
                if (!sum[num]) sum[num] = 0;
                sum[num]++;
                return sum;
            }, {});
            CDR = Object.keys(map).reduce((max, k) => {
                if (map[k] > max) return map[k];
                return max;
            }, 0);
        }
        if ((cat2.filter(c => c > cat1).length === 2) && cat2.filter(c => c < cat1).length === 2) {
            CDR = cat1;
        }
        return {
            ...values,
            risultato: {
                punteggio: CDR,
                esito: `Somma totale: ${punteggio}`
            }
        }
    } },
    { key: 'scalaDoloreEspressioniFacciali', label: 'Scala dolore espressioni facciali', calc: true, },
    { key: 'scalaDoloreNPRS', label: 'Scala dolore NPRS', calc: true, },
    { key: 'scalaDolorePainad', label: 'Scala dolore Painad', calc: true, },
    { key: 'scalaNorton', label: 'Scala Norton', calc: true, parser: (values) => { const punteggio = _get(values, 'risultato.punteggio'); return _set(values, 'risultato.esito', punteggio < 12 ? `rischio elevato` : (punteggio >= 12 && punteggio <= 14 ? `rischio lieve` : undefined)); } },
    { key: 'scalaTinettiGait', label: 'Scala Tinetti Gait', calc: true, },
    { key: 'scalaTinettiBalance', label: 'Scala Tinetti Balance', calc: true, },
    { key: 'scalaConley', label: 'Scala Conley', calc: true, },
];

interface Props {
    record: any;
    parent: any;
    tipologia: TipologiaValutazione;
    formSchema: SchemaTypes.Schema;
    lastFormVersion?: number;
    handleClose: () => void;
}

export function EditCartellaDettaglioValutazione(props: Omit<Props, 'tipologia' | 'formSchema' | 'lastFormVersion'>) {
    const { record = {}, handleClose } = props;
    const [progettoUniqueId, setProgettoUniqueId] = useState(record.progettoUniqueId);
    const [tipologia, setTipologia] = useState<TipologiaValutazione | undefined>();
    const [errore, setErrore] = useState(false);
    const { useQuery } = useDataRequest();
    const related = useQuery(PROGETTI_IN_CARTELLA, { variables: { cartellaUniqueId: record.cartellaUniqueId }});
    const isNew = !record.uniqueId;
    
    useEffect(() => {
        if (record.tipologia) {
            const tipo = TIPOLOGIE.find(t => t.key === record.tipologia);
            if (tipo) {
                setTipologia(tipo);
            } else {
                setErrore(true)
            }
        }
    }, [record])

    if (errore) {
        return <div className='empty'>
            <p>Non è stato possibile trovare la tipologia di questa valutazione.</p>
            <Button onClick={handleClose}>Torna indietro</Button>
        </div>
    }

    if (isNew) {
        if (!progettoUniqueId) {
            if (related.loading) return <Spinner />;
            const progetti = _get(related.data, 'cartellaSocioSanitariaQuery.progettiPai.list', []);
            return <>
                <Title title={'Scegli progetto'} onBack={handleClose} />
                <ListGroup>
                    <ListGroup.Item action onClick={() => setProgettoUniqueId('@none')}>
                        <div className="fw-bold">Nessun progetto</div>
                    </ListGroup.Item>
                    {progetti.map((progetto, k) => {
                        return <ListGroup.Item action onClick={() => setProgettoUniqueId(progetto.uniqueId)}>
                            {progetto.codice}
                        </ListGroup.Item>
                    })}
                </ListGroup>
            </>
        }
    }

    if (!tipologia) {
        return <>
            <Title title={'Tipologia valutazione'} onBack={handleClose} />
            <ListGroup>
                {TIPOLOGIE.map((t, k) => {
                    return <ListGroup.Item action onClick={() => setTipologia(t)}>
                        {t.label}
                    </ListGroup.Item>
                })}
            </ListGroup>
        </>
    }

    return <SchemaLoader {...props} record={{ ...record, progettoUniqueId }} tipologia={tipologia} />
}

const useOperatoriDisponibili = (related) => {
    const user = useSelector(selectCurrentUser);
    const permissions = useSelector(selectPermissions);
    const $operatori = _get(related.data, 'struttureServiziQuery.operatori.list', []);
    if (hasPermission('GestioneValutazioneGlobale', permissions)) return $operatori;
    if (hasPermission('GestioneValutazioneBase', permissions)) return $operatori.filter(o => _get(o, 'utenteCollegato.email') === user.email);
    return [];
}

function SchemaLoader(props: Omit<Props, 'formSchema' | 'lastFormVersion'>)  {
    const { record, tipologia } = props;
    const { useQuery } = useDataRequest();
	const related = useQuery(RELATED);
    const operatori = useOperatoriDisponibili(related);
    const preset = `presets.${tipologia.schema || `Valutazione-${tipologia.key}`}`;
    var schema = useFormBuilderSchemaMerger([
        'presets.Valutazione',
        preset,
    ], record && record.formVersion ? {
        [preset]: record.formVersion
    } : undefined);
	schema = useHideNodesSchema(useSelectItemsPopulate(
		schema as Schema,
        related, {
            operatoreUniqueId: [
                ...operatori.map((cat: any) => ({ text: `${cat.datiAnagrafici.nome} ${cat.datiAnagrafici.cognome}`, value: cat.uniqueId })),
            ],
        }
    ), []);
    const lastFormVersion = useFormBuilderSchemaLastVer(preset);

    if (schema === null) return <div className='empty'>
        Impossibile trovare il modulo di inserimento per questa valutazione.
    </div>
    if (schema === undefined) return <Spinner />;

    return <Edit {...props} formSchema={schema} lastFormVersion={lastFormVersion} />
}

function Edit(props: Props) {
    const { record, tipologia, lastFormVersion, handleClose } = props;
    const { cartellaUniqueId } = record;
    const [risultato, setRisultato] = useState<any>();
	const { useQuery } = useDataRequest();
    // get assistito id
    const query1 = useQuery(GET_ASSISTITO_BY_CARTELLA, {
        variables: {
            id: cartellaUniqueId,
        }
    });
    const { data: data1 } = query1;
    // get full data
    const assistitoUniqueId = _get(data1, 'cartellaSocioSanitariaQuery.cartelle.list[0].assistito.uniqueId');
    const query2 = useQuery(GET_ASSISTITO, {
        skip: !assistitoUniqueId,
        variables: {
            id: assistitoUniqueId,
        }
    });
    const { data: data2 } = query2;
    const assistito = _get(data2, 'anagraficaQuery.assistiti.list[0]');

    const formArgs = useMemo(() => {
        const { initialValues, ...args } = getEditFormViewer(props);
        const { __typename, uniqueId, progettoUniqueId, metadata, operatore, progetto, tipologia: _tipologia, formData, formVersion, ...rest } = record;
        const parsedFormData = record && record.formData ? JSON.parse(record.formData) : undefined;
        return {
            ...args,
            initialValues: {
                ...rest,
                ...parsedFormData,
                progettoUniqueId: progettoUniqueId !== '@none' ? progettoUniqueId : undefined,
                tipologia: tipologia.key,
                formVersion: record.formVersion || lastFormVersion, 
            }
        }
    }, [props, tipologia]);

    const onChange = useCallback((args: any) => {
        if (!tipologia.calc) return;

		const {
			values,
            setValues,
		} = args;

        const { __typename, uniqueId, metadata, operatoreUniqueId, progettoUniqueId, cartellaUniqueId, data, tipologia: _tipologia, risultato: _risultato, formData, formVersion, ...rest } = values;
        const punteggio = Object.keys(rest).reduce((acc, k) => {
            return acc + parseFloat(rest[k]);
        }, 0);
		
        const parser = tipologia.parser ? tipologia.parser : (values: any) => (values);
        const newValues = parser({
            ...values,
            risultato: {
                punteggio,
            },
        });
        setRisultato(_get(newValues, 'risultato'));
        setValues(newValues);
	}, [tipologia]);

    if (query1.loading || query2.loading) return <Spinner />
    const title = tipologia.titles ? (record.uniqueId ? tipologia.titles.edit : tipologia.titles.create) : (record.uniqueId ? `Modifica valutazione ${tipologia.label}` : `Nuova valutazione ${tipologia.label}`);
    return <div>
        <Title title={title} onBack={handleClose} />
        <Alert variant={'primary'}>
            <h4>Assistito</h4>
            <div className="row g-3">
                <div className="col-sm-6">
                    <Dt label='Nome' value={_get(assistito, 'datiAnagrafici.nome')} />
                    <Dt label='Cognome' value={_get(assistito, 'datiAnagrafici.cognome')} />
                    <Dt label='Cod. Fiscale' value={_get(assistito, 'datiAnagrafici.codiceFiscale')} />
                    <Button href={`/anagrafiche/assistiti#${_get(assistito, 'uniqueId')}`} target='_blank'>Link a scheda anagrafica</Button>
                </div>
                <div className="col-sm-6 text-center">
                    {tipologia.calc && risultato && <>
                        <h3>Punteggio</h3>
                        <h2>{risultato.punteggio || '-'}</h2>
                        {risultato.esito && <h4>{risultato.esito}</h4>}
                    </>}
                </div>
            </div>
        </Alert>

        <FormViewer
            {...formArgs}
            onInit={onChange}
            onChange={onChange}
        />
    </div>;
}

export default EditCartellaDettaglioValutazione;
