import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import BootstrapTable from "@ai4/react-bootstrap-table-next";
import filterFactory from "@ai4/react-bootstrap-table2-filter";
import ToolkitProvider from "@ai4/react-bootstrap-table2-toolkit";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import paginationFactory from "@ai4/react-bootstrap-table2-paginator";
import { Confirm } from "@ai4/form-viewer";
//import '@ai4/react-bootstrap-table2-paginator/dist/@ai4/react-bootstrap-table2-paginator.min.css';
//import '@ai4/react-bootstrap-table-next/dist/@ai4/react-bootstrap-table2.min.css';

export function FilterTable(props) {
  const {
    columns,
    rows,
    onSelectedRow,
    onDeleteRow,
    hidePagination,
    hideToggle,
    hideEditColumns,
  } = props;
  const [confirm, setConfirm] = useState();

  const selectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
  };

  const CustomToggleList = ({ toggleColumns, onColumnToggle, toggles }) => (
    <div>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-custom-components">
          Colonne...
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ padding: "10px" }}>
          {columns
            .map((column) => ({
              ...column,
              toggle: toggles[column.dataField],
            }))
            .map((column, index) => (
              <Form.Check
                type="checkbox"
                key={column.dataField}
                label={column.text}
                id={column.dataField}
                checked={column.toggle}
                aria-checked={column.toggle ? "true" : "false"}
                onChange={() => onColumnToggle(column.dataField)}
              />
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );

  const customTotal = (from, to, size) => (
    <span className="@ai4/react-bootstrap-table-pagination-total">
      &nbsp;Risultati da {from} a {to} di {size} totali
    </span>
  );

  const pagination = paginationFactory({
    page: 1,
    paginationSize: 2,
    totalSize: rows.length,
    prePageText: "Prec.",
    nextPageText: "Succ.",
    showTotal: true,
    paginationTotalRenderer: customTotal,
  });

  const self = this;
  const selectFormatter = (cell, row, rowIndex, formatExtraData) => {
    const selectMe = (rowIndex, row) => {
      onSelectedRow(row);
    };

    return (
      <Button variant="action">
        <i
          className="bi bi-pencil-square"
          onClick={() => selectMe(rowIndex, row)}
        >
          <span className="visually-hidden">modifica</span>{" "}
        </i>
      </Button>
    );
  };

  const deleteFormatter = (cell, row, rowIndex, formatExtraData) => {
    return (
      <Button variant="action">
        <i className="bi bi-x-lg" onClick={() => setConfirm(row)}>
          <span className="visually-hidden">elimina</span>{" "}
        </i>
      </Button>
    );
  };

  const enhancedColumns = hideEditColumns
    ? columns
    : [
        ...columns,
        {
          dataField: "edit",
          text: "",
          editable: false,
          align: "center",
          headerStyle: { width: 30 },
          formatter: selectFormatter,
        },
        {
          dataField: "delete",
          text: "",
          editable: false,
          align: "center",
          headerStyle: { width: 30 },
          formatter: deleteFormatter,
        },
      ];

  return (
    <div>
      {confirm && (
        <Confirm
          text="Sei sicuro di voler eliminare questo elemento?"
          onSuccess={() => {
            onDeleteRow(confirm);
            setConfirm(undefined);
          }}
          onCancel={() => setConfirm(undefined)}
        />
      )}
      <ToolkitProvider
        keyField="id"
        data={rows}
        columns={enhancedColumns}
        columnToggle
      >
        {(props) => (
          <div
            className="table-responsive-sm table-responsive-md"
            style={{ paddingBottom: "25px" }}
          >
            {hideToggle === true ? null : (
              <div style={{ float: "right", marginBottom: "15px" }}>
                <CustomToggleList {...props.columnToggleProps} />
              </div>
            )}
            <BootstrapTable
              {...props.baseProps}
              bootstrap5={true}
              pagination={hidePagination ? null : pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
              classes="columns-toggle-table table-hover filterTable"
            />
          </div>
        )}
      </ToolkitProvider>
    </div>
  );
}
export default FilterTable;
