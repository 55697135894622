
const permissionSchemaDb = {

    roles: [{
        id: 'ad345c44-5109-11ec-bf63-0242ac134442',
        name: 'Cittadino',
        description: "Utente fruitore dei servizi del SISS"
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134442',
        name: 'Amministratore',
        description: "Ha i diritti per modificare le configurazioni del software"
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134443',
        name: 'Business manager',
        description: "Ha i diritti per modificare il piano di attivazione e le licenze"
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134444',
        name: 'Assistente sociale',
        description: "Può registrare l'erogazioen delle prestazioni"
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134445',
        name: 'Amministrativo',
        description: "Gestisce la rendicontazioen degli interventi e il budget dell'ente per l'erogazione dei servizi"
    },
    {
        id: 'ad345c55-5109-11ec-bf63-0242ac134446',
        name: 'Operatore',
        description: "Inserisce anagrafiche, descrive i progetti (PAI)"
    }],

    userProfiles: [{
        id: 'ad345c44-5109-11ec-bf63-0242ac134442',
        name: 'Super admin',
        roles: [{
            id: 'ad345c55-5109-11ec-bf63-0242ac134442',
            name: 'Amministratore'
        },
        {
            id: 'ad345c55-5109-11ec-bf63-0242ac134442',
            name: 'Business manager'
        }]
    }, {
        id: 'ad345c44-5109-11ec-bf63-0242ac134442',
        name: 'Team manager',
        roles: [{
            id: 'ad345c55-5109-11ec-bf63-0242ac134442',
            name: 'Assistente sociale'
        },
        {
            id: 'ad345c55-5109-11ec-bf63-0242ac134442',
            name: 'Amministrativo'
        },
        {
            id: 'ad345c55-5109-11ec-bf63-0242ac134442',
            name: 'Operatore'
        }]
    }, {
        id: 'ad345c44-5109-11ec-bf63-0242ac134442',
        name: 'Operatore',
        roles: [
            {
                id: 'ad345c55-5109-11ec-bf63-0242ac134442',
                name: 'Operatore'
            }]
    }],
}
export default permissionSchemaDb;