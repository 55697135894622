import _get from 'lodash/get';
import _set from 'lodash/set';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Tab, Row, Button, Alert, Col, Nav, Spinner } from 'react-bootstrap';
import { DataContext, gql, useDataRequest } from '@ai4/data-request';
import { _assoc } from '../../anagrafiche/common';
import EditAffidamento from '../edit/affidamento';
import { AFFIDAMENTI_RELATED } from '../affidamenti';
import { useSelectItemsPopulate } from 'src/app/helpers/data';
import Schema from '@ai4/form-viewer/dist/types/schema';

interface AffidamentoProps {
    record: any;
    parent: any;
    onBack: () => void;
}

function Affidamento(props: AffidamentoProps) {
    const { record, parent, onBack } = props;

    const { domain, getClient } = useContext(DataContext);
    const { useQuery, useRestRequest } = useDataRequest();
    const related = useQuery(AFFIDAMENTI_RELATED);
    const [execSave, res] = useRestRequest({
        path: '@later',
        jwt: true,
    });

    const affidamento = useMemo(() => {
        const affidamento = _get(record, 'interventi[0].affidamenti[0]');
        return {
            ...(affidamento || {}),
            interventoUniqueId: _get(record, 'interventi[0].uniqueId'),
        };
    }, [record]);

    const BASE = `api/{ver}/cartellasociosanitaria/affidamenti`;
    const save = useCallback(async ({ values }) => {
		const { uniqueId, interventoUniqueId } = values;
        await execSave({
			path: `${BASE}${uniqueId ? `/${uniqueId}` : ''}`,
			method: uniqueId ? 'PUT' : 'POST',
            data: {
                dati: {
                    ...values,
                },
                interventoUniqueId,
            },
        });
        if (getClient) {
            await getClient(domain).refetchQueries({
                include: ['getProgettoPai'],
            });
        }
        onBack();
    }, [record]);

    var schema = require('../schema/edit/affidamento.json');
	schema = useSelectItemsPopulate(
		schema as Schema,
        related, {
            motivoChiusuraUniqueId: [
                ..._get(related.data, 'cartellaSocioSanitariaQuery.elenchiCartellaSocioSanitaria.motivoChiusuraAffidamento.list', []).map(_assoc),
            ],
        }
	);

    if (related.loading || !affidamento) return <Spinner />
    return <div>
        <EditAffidamento
            record={affidamento}
            formSchema={schema}
            onSubmit={(args) => {
                save(args);
            }}
            handleClose={onBack}
        />
    </div>
}

export default Affidamento;