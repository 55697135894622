import _get from 'lodash/get';
import React, { Fragment, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Modal, Stack, Table } from 'react-bootstrap';
import { FormViewer } from '@ai4/form-viewer';
import { useFormBuilderSchemaLoader, useSelectItemsPopulate } from 'src/app/helpers/data';
import { ButtonBar } from './Recapiti';
import { _assoc } from '../common';
import Schema from '@ai4/form-viewer/dist/types/schema';

interface Props {
	items: any[];
	related: any;
    onChange: (values: any) => void;
}

const Cittadinanza = (props: Props) => {
    const { items, related, onChange } = props;
	const [record, setRecord] = useState<any>();

	const handleClose = () => setRecord(undefined);

	const tipologie = _get(related.data, "anagraficaQuery.elenchiAnagrafica.tipologiaCittadinanza.list", []);
	const cittadinanze = _get(related.data, "elenchiQuery.elenchiGeneraliQuery.cittadinanze.list", []);
	var schema = useFormBuilderSchemaLoader('presets.anagrafiche-cittadinanza');
	schema = useSelectItemsPopulate(
		schema as Schema,
		related, {
            'tipologiaCittadinanzaUniqueId': [
                ...tipologie.map(_assoc),
                { listManagement: { name: 'TipologiaCittadinanza', query_name: 'getRelatedAnagrafica' } },
            ],
            'cittadinanzaUniqueId': [
                ...cittadinanze.map(_assoc),
            ],
		}
	);

	const onRemove = useCallback(async (index) => {
        onChange(items.filter((item, i) => i !== index));
	}, [items]);

	if (related.loading) return null;
	return <Fragment>
		<Card className='my-3'>
      		<Card.Body>
				<Stack direction="horizontal" gap={3}>
        			<Card.Title>Dati cittadinanza</Card.Title>
					<Button variant='success' className='ms-auto' onClick={() => setRecord({})}>Aggiungi</Button>
				</Stack>
				<Card.Text>
					{items.length === 0 && <div className='empty'>Nessuna cittadinanza inserita</div>}
					{items.length > 0 && <Table striped bordered hover>
						<thead>
							<tr>
								<th>Tipologia</th>
								<th>Cittadinanza</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
						{items.map((row, i) => {
							return <tr key={i} style={{ cursor: 'pointer' }} onClick={() => setRecord({ index: i, ...row })}>
								<td>{row.tipologiaCittadinanza ? row.tipologiaCittadinanza.descrizione : ''}</td>
								<td>{row.cittadinanza ? row.cittadinanza.descrizione : ''}</td>
								<td>
									<Button onClick={(e) => {
										e.stopPropagation();
										if (window.confirm("Vuoi continuare?")) {
											onRemove(i);
										}
									}}>Elimina</Button>
								</td>
							</tr>;
						})}
						</tbody>
					</Table>}
				</Card.Text>
			</Card.Body>
   		</Card>
		<Modal show={!!record} onHide={handleClose}>
			<Modal.Body>
				<FormViewer
					schema={schema as any}
					initialValues={record || {}}
					onSubmit={(args: any) => {
						const { values } = args;
						const tipologiaCittadinanza = tipologie.find(t => t.uniqueId === values.tipologiaCittadinanzaUniqueId);
						const cittadinanza = cittadinanze.find(t => t.uniqueId === values.cittadinanzaUniqueId);
						const obj = { ...values, tipologiaCittadinanza, cittadinanza };
						const newValues = obj.index === undefined ? [...items, obj] : items.map((item, i) => i === obj.index ? obj : item);
            			onChange(newValues);
						handleClose();
					}}
					slots={{
						ButtonBar: (args) => <ButtonBar args={args} onCancel={handleClose} />
					}}
				/>
			</Modal.Body>
		</Modal>
	</Fragment>
}

export default Cittadinanza;