import { lazy } from "react";
import { authRoles } from "src/app/roles";

const Calendario = lazy(() => import("./Calendario"));

const accessiRoutes = [
  {
    path: "accessi/calendario",
    element: <Calendario />,
    // auth: authRoles.editor
  },
];

export default accessiRoutes;
